const Tag: React.FC<TagPropTypes> = ({text, style, onClick}) => {
  return (
    <div
      style={{
        backgroundColor: '#ECFDF3',
        borderRadius: '16px',
        color: '#027A48',
        fontSize: '12px',
        fontWeight: 500,
        padding: '2px 8px',
        ...style
      }}
      onClick={onClick}
    >
      {text}
    </div>
  )
}

interface TagPropTypes {
  text: string | number
  style?: object
  onClick?: (e?: any) => void
}

export default Tag
