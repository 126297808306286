/* eslint-disable */
import React from 'react'
import landingClasses from "../Landing/Landing.module.scss"
import commonClasses from './TermsAndPrivacyCommonClasses.module.scss'
import {useNavigate} from "react-router-dom"
import logo from './../../img/privacyTermsLogo.png'


const TermsAndPrivacyInner:React.FC<{children: any, title: string, subtitle?: string}> = ({children, title, subtitle }) => {
    const navigate = useNavigate()

    const onVisitLoginPage = () => {
        navigate('/sign-in')
    }

    const onVisitSignInPage = () => {
        navigate('/sign-up')
    }

    return (
        <div>
            <div className={commonClasses.buttons}>
                <button className={landingClasses.loginBtn} onClick={onVisitLoginPage}>Log in</button>
                <button className={landingClasses.earlyAccessBtn} onClick={onVisitSignInPage}>Get early access</button>
            </div>
            <div className={`${commonClasses.container} ${commonClasses.header}`}>
                <img src={logo} alt="logo"/>
                <h1>{title}</h1>
                {
                    subtitle
                    &&
                    <h2>{subtitle}</h2>
                }
            </div>
            <div style={{width: '100%', overflowX: 'hidden'}}>
                {children}
            </div>
        </div>
    )
}

export default TermsAndPrivacyInner