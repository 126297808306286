import { InstanceType } from '../types/instanceType'
import { ReportCDType } from '../types/requestTypes'
import { capitalizeWords } from './funcHelper'

export const checkInstancesForConflicts = (selectedSessionsInstances: InstanceType[]) => {
  const instancesForConflicts = ['id', 'super-ego', 'ego', 'external reality']
  const availableRequiredInstances = selectedSessionsInstances?.filter(i => instancesForConflicts.includes(i.name.toLowerCase()))
  const notAvailableRequiredInstances = instancesForConflicts.filter(instName => !selectedSessionsInstances?.some(i => i.name.toLowerCase() === instName))
  return {
    isReportAvailable: availableRequiredInstances?.length === instancesForConflicts.length,
    availableRequiredInstances,
    notAvailableRequiredInstances: notAvailableRequiredInstances.map(capitalizeWords),
  }
}

export const checkIfDiagnosesAvailable = (availableInstances: InstanceType[]) => {
  return availableInstances?.some(i => i.name.toLowerCase() === 'symptoms')
}


export const getAvailableReportTypes = (availableInstances: InstanceType[]):ReportCDType[] => {
  return [
    'main',
    ...(checkInstancesForConflicts(availableInstances)?.isReportAvailable ? ['conflicts'] as ReportCDType[] : []),
    ...(checkIfDiagnosesAvailable(availableInstances) ? ['diagnoses', 'kernberg'] as ReportCDType[] : []),
    'literature',
    'keywords'
  ]
}

export const getReportTypes = () => {
  return {
    independent: ['main', 'conflicts', 'diagnoses'],
    dependent: ['kernberg', 'literature', 'keywords']
  }
}
