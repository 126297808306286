import { useState } from 'react'
import { Button } from 'antd'
import SelectUserModal from '../../Header/CurrentUserMenu/SelectUserModal/SelectUserModal'
import { useTranslation } from 'react-i18next'

const SuperUserWarningMessage = () => {
  const { t } = useTranslation(['commonComponents'])
  const [isSelectUserModalOpen, setIsSelectUserModalOpen] = useState(false)

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{fontSize: '22px', margin: '30px 0px'}}>
        {t('superUserWarningMessage.text')}
      </div>
      <Button
        type='primary'
        onClick={() => setIsSelectUserModalOpen(true)}
      >
        {t('superUserWarningMessage.button')}
      </Button>
      <SelectUserModal
        isOpen={isSelectUserModalOpen}
        onClose={() => setIsSelectUserModalOpen(false)}
      />
    </div>
  )
}

export default SuperUserWarningMessage
