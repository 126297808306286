import { useState } from 'react'
import { Button, Divider } from 'antd'
import { useAppSelector } from '../../../app/hooks'
import { selectUserBillingSettings } from '../../../store/userReducer'
import classes from './MainBalance.module.css'
import AddMoreModal from '../../common/AddMoreModal/AddMoreModal'
import BillingInfoCard from '../BillingInfoCard/BillingInfoCard'
import { ReactComponent as CreditBalanceIcon } from './../../../img/icons/creditBalance.svg'
import { ReactComponent as PatientBalanceIcon } from './../../../img/icons/patientBalance.svg'
import { useTranslation } from 'react-i18next'
import { selectBillingSettings, selectSuperUserCurrentUser } from '../../../store/currentUserReducer'

const MainBalance: React.FC<{isCurrentUserBalance: boolean}> = ({isCurrentUserBalance}) => {
  const { t } = useTranslation(['pages'])
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)
  const userBillingSettings = useAppSelector(selectUserBillingSettings)
  const currentUserBillingSettings = useAppSelector(selectBillingSettings)
  const billingSettings = (isCurrentUserBalance && superUserCurrentUser?.user_id) || !isCurrentUserBalance
    ? userBillingSettings
    : currentUserBillingSettings

  const [creditsModalOpened, setCreditsModalOpened] = useState(false)
  const [patientsModalOpened, setPatientsModalOpened] = useState(false)
  console.log('currentUserBillingSettings', currentUserBillingSettings?.credits_balance)
  return (
    <div className={classes.mainInfo}>
      <div className={classes.mainInfoBlock}>
        <div className={classes.blockTitle}>
          <div>
            <h2>
              {t('billing.credit.title')}
            </h2>
            <h3>
              {t('billing.credit.description')}
            </h3>
          </div>
          {!isCurrentUserBalance &&
            <Button
              type='primary'
              onClick={() => setCreditsModalOpened(true)}
            >
              {t('billing.credit.addBtn')}
            </Button>
          }
        </div>
        <BillingInfoCard
          title={t('billing.credit.label')}
          cardValue={billingSettings?.credits_balance || 0}
          icon={<CreditBalanceIcon />}
        />
      </div>

      <Divider type='vertical' style={{margin: '0 auto', height: '70px'}}/>

      <div className={classes.mainInfoBlock}>
        <div className={classes.blockTitle}>
          <div>
            <h2>
              {t('billing.patients.title')}
            </h2>
            <h3>
              {t('billing.patients.description')}
            </h3>
          </div>
          {!isCurrentUserBalance &&
            <Button
              type='primary'
              onClick={() => setPatientsModalOpened(true)}
            >
              {t('billing.patients.addBtn')}
            </Button>
          }
        </div>
        <BillingInfoCard
          title={t('billing.patients.label')}
          cardValue={billingSettings?.available_person || 0}
          icon={<PatientBalanceIcon />}
        />
      </div>
      {creditsModalOpened &&
        <AddMoreModal
          closeModal={() => setCreditsModalOpened(false)}
          title={t('billing.credit.addModal.title')}
          label={t('billing.credit.addModal.label')}
        />
      }
      {patientsModalOpened &&
        <AddMoreModal
          closeModal={() => setPatientsModalOpened(false)}
          title={t('billing.patients.addModal.title')}
          label={t('billing.patients.addModal.label')}
        />
      }
    </div>
  )
}

export default MainBalance
