import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { NewPersonType, PersonDetailsActiveTabKeyType, PersonListManualParamsType, PersonListRequestParamsType, PersonType } from '../types/personTypes'
import { AppStatusType } from './appStatusReducer'
import { personAPI } from '../app/api'
import { userDataHelper } from '../helpers/localStorageHelper'
import { SignOutThunk, UpdateUserReportSettingsThunk } from './currentUserReducer'
import { pickBy, sortBy } from 'lodash'
import { ReportSettingsType } from '../types/requestTypes'
import { CreateSessionThunk } from './sessionReducer'

const personListRequestParamsInitialValue = {
  pagination_request: {page: 1, size: 10, sort: [{field: 'name', direction: 'ASC' as 'ASC'}]},
  person_request: {
    active_status : 'ALL' as 'ALL',
    name: '',
    user_id: userDataHelper.getUserData().user_id
  }
}

const personListManualParamsInitialValue: PersonListManualParamsType = {
  page: 1,
  size: 10,
  sort: [{field: 'name', sort: 'asc'}],
  activeStatus: 'ACTIVE',
  filters: {items: []}
}

interface InitialStateType {
  personList: null | PersonType[]
  personListTotalCount: number
  personListRequestParams: PersonListRequestParamsType
  personListManualParams: PersonListManualParamsType

  selectedPersonData: null | PersonType
  personDetailsActiveTabKey: PersonDetailsActiveTabKeyType
  selectedPersonReportSettings: null | ReportSettingsType
}

const initialState: InitialStateType = {
  personList: null,
  personListTotalCount: 0,
  personListRequestParams: personListRequestParamsInitialValue,
  personListManualParams: personListManualParamsInitialValue,
  selectedPersonData: null,
  personDetailsActiveTabKey: 'general',
  selectedPersonReportSettings: null
}

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setPersonList: (state, action: PayloadAction<PersonType[] | null>) => {state.personList = action.payload},
    setPersonListTotalCount: (state, action: PayloadAction<number>) => {state.personListTotalCount = action.payload},
    setPersonListRequestParams: (state, action: PayloadAction<PersonListRequestParamsType>) => {state.personListRequestParams = action.payload},
    setPersonListManualParams: (state, action: PayloadAction<PersonListManualParamsType>) => {state.personListManualParams = action.payload},

    setSelectedPersonData: (state, action: PayloadAction<null | PersonType>) => {state.selectedPersonData = action.payload},
    setPersonDetailsActiveTabKey: (state, action: PayloadAction<PersonDetailsActiveTabKeyType>) => {state.personDetailsActiveTabKey = action.payload},
    setSelectedPersonReportSettings: (state, action: PayloadAction<ReportSettingsType | null>) => {state.selectedPersonReportSettings = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPersonListThunk.fulfilled, (state, action) => {
        state.personList = action.payload.persons
        state.personListTotalCount = action.payload.total_count
      })
      .addCase(GetPersonByIdThunk.fulfilled, (state, action) => {
        state.selectedPersonData = action.payload
      })
      .addCase(DeletePersonThunk.fulfilled, (state, action) => {
        state.personList = state.personList?.filter(person => person.id !== action.payload) || []
        state.personListTotalCount -= 1
      })
      .addCase(CreatePersonThunk.fulfilled, (state, action) => {
        if (state.personListRequestParams.person_request.active_status !== 'INACTIVE') {
          state.personList = [...state.personList || [], action.payload] || []
          state.personListTotalCount += 1
        }
      })
      .addCase(EditPersonThunk.fulfilled, (state, action) => {
        if (state.personListRequestParams.person_request.active_status === 'ALL') {
          state.personList = state.personList?.map(person => person.id === action.payload.id ? action.payload : person) || []
        } else {
          state.personList = state.personList?.filter(person => person.id !== action.payload.id) || []
        }
      })
      .addCase(GetPersonReportSettingsThunk.fulfilled, (state, action) => {
        state.selectedPersonReportSettings = action.payload
      })
      .addCase(UpdateUserReportSettingsThunk.fulfilled, (state, action) => {
        if (action.payload.settingsIdData.to_person_id) {
          state.selectedPersonData = {...state.selectedPersonData!, ...action.payload.settings}
        }
      })
      .addCase(CreateSessionThunk.fulfilled, (state, action) => {
        state.personList = state.personList?.map(p => p.id === action.payload.person.id ? {...p, session_count: p.session_count + 1} : p) || []
      })
      .addCase(EditPersonActiveStatusThunk.fulfilled, (state, action) => {
        if (state.personListRequestParams.person_request.active_status === 'ALL') {
          state.personList = state.personList?.map(person => person.id === action.payload.personId ? {...person, is_active: action.payload.isActive} : person) || []
        } else {
          state.personList = state.personList?.filter(person => person.id !== action.payload.personId) || []
        }
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.personList = null
        state.personListTotalCount = 0
        state.selectedPersonData = null
        state.personListRequestParams = personListRequestParamsInitialValue
      })
  }
})

export const {
  setPersonList,
  setPersonListTotalCount,
  setPersonListRequestParams,
  setPersonListManualParams,
  setSelectedPersonData,
  setPersonDetailsActiveTabKey,
  setSelectedPersonReportSettings
} = personSlice.actions

export const selectPersonList = (state: RootState): PersonType[] | null => state.person.personList
export const selectPersonListTotalCount = (state: RootState): number => state.person.personListTotalCount
export const selectPersonListRequestParams = (state: RootState): PersonListRequestParamsType => state.person.personListRequestParams
export const selectPersonListManualParams = (state: RootState): PersonListManualParamsType => state.person.personListManualParams
export const selectSelectedPersonData = (state: RootState): null | PersonType => state.person.selectedPersonData
export const selectPersonDetailsActiveTabKey = (state: RootState): PersonDetailsActiveTabKeyType => state.person.personDetailsActiveTabKey
export const selectSelectedPersonReportSettings = (state: RootState): ReportSettingsType | null => state.person.selectedPersonReportSettings

export const GetPersonListThunk = createAsyncThunk<{persons: PersonType[], total_count: number}, PersonListRequestParamsType, AsyncThunkConfig>(
  'person/getPersonList',
  async (requestParams, thunkAPI) => {
    try {
      const formData = new FormData()
      // formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
      formData.append('person_request', new Blob([JSON.stringify(requestParams.person_request, null, 2)], {type: 'application/json'}))
      const { status, data } = await personAPI.getPersonList(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetPersonByIdThunk = createAsyncThunk<PersonType, number, AsyncThunkConfig>(
  'person/getPersonById',
  async (id, thunkAPI) => {
    try {
      const { status, data } = await personAPI.getPersonById(id)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreatePersonThunk = createAsyncThunk<PersonType, NewPersonType, AsyncThunkConfig>(
  'person/createPerson',
  async (personData, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('person', new Blob([JSON.stringify(personData, null, 2)], {type: 'application/json'}))
      const { status, data } = await personAPI.createPerson(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Patient has been successfully created'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditPersonThunk = createAsyncThunk<PersonType, {personId: number, person: NewPersonType}, AsyncThunkConfig>(
  'person/editPerson',
  async ({personId, person}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('person', new Blob([JSON.stringify(person, null, 2)], {type: 'application/json'}))
      const { status, data } = await personAPI.editPerson(personId, formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Patient has been successfully updated'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeletePersonThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'person/deletePerson',
  async (personId, thunkAPI) => {
    try {
      const { status, data } = await personAPI.deletePerson(personId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(personId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetPersonReportSettingsThunk = createAsyncThunk<ReportSettingsType, number, AsyncThunkConfig>(
  'person/getPersonReportSettings',
  async (personId, thunkAPI) => {
    try {
      const { status, data } = await personAPI.getPersonReportSettings(personId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditPersonActiveStatusThunk = createAsyncThunk<{personId: number, isActive: boolean}, {personId: number, isActive: boolean}, AsyncThunkConfig>(
  'person/editPersonActiveStatus',
  async ({personId, isActive}, thunkAPI) => {
    try {
      const { status, data } = await personAPI.editPersonActiveStatus(personId, isActive)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({personId, isActive}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const getPersonGeneralData = (personData: PersonType): NewPersonType => {
  return {
    ...pickBy(
      personData, (_, key) => (
        key !== 'user' &&
        key !== 'id' &&
        key !== 'is_active' &&
        key !== 'last_session_time' &&
        key !== 'session_count' &&
        key !== 'picture'
      )
    ),
    instance_list: sortBy(personData?.instance_list, i => i.order_num)?.map(instance => String(instance.id)) || []
  } as NewPersonType
}

export default personSlice.reducer
 