import { Button, Input, Modal, Pagination, Spin } from 'antd'
import { ReactComponent as SwitchIcon } from './../../../../img/icons/switch.svg'
import { ReactComponent as SearchIcon } from './../../../../img/icons/search.svg'
import classes from './SelectUserModal.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetUserListForSuperUserThunk, selectUserListForSuperUser, selectUserListForSuperUserFilterCount, selectUserListForSuperUserRequestParams, selectUserListForSuperUserTotalCount, setUserListForSuperUser, setUserListForSuperUserRequestParams, userListForSuperUserRequestParamsInitialValue } from '../../../../store/userReducer'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { selectSuperUserCurrentUser, setSuperUserCurrentUser } from '../../../../store/currentUserReducer'
import { UserDataForSuperUserType } from '../../../../types/userTypes'
import { setSelectedPersonData } from '../../../../store/personReducer'
import { useLocation, useNavigate } from 'react-router-dom'

interface SelectUserModalPropTypes {
  isOpen: boolean
  onClose: () => void
}

const SelectUserModal: React.FC<SelectUserModalPropTypes> = ({isOpen, onClose}) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const userList = useAppSelector(selectUserListForSuperUser)
  const userListTotalCount = useAppSelector(selectUserListForSuperUserTotalCount)
  const userListFilterCount = useAppSelector(selectUserListForSuperUserFilterCount)
  const userListRequestParams = useAppSelector(selectUserListForSuperUserRequestParams)
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)

  const [isLoading, setIsLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      dispatch(GetUserListForSuperUserThunk(userListRequestParams))
        .then(() => setIsLoading(false))
    }
    // eslint-disable-next-line
  }, [dispatch, userListRequestParams, isOpen])

  useEffect(() => {
    return () => {
      dispatch(setUserListForSuperUserRequestParams(userListForSuperUserRequestParamsInitialValue))
      dispatch(setUserListForSuperUser(null))
    }
  }, [dispatch])

  // eslint-disable-next-line
  const onSearch = useCallback(
    debounce((name: string) => {
      dispatch(setUserListForSuperUserRequestParams({
        select_request: {...userListRequestParams?.select_request || {}, name},
        pagination_request: {...userListRequestParams.pagination_request, page: 1}
      }))
    }, 350),
    []
  )

  const selectUser = (user: UserDataForSuperUserType) => {
    dispatch(setSuperUserCurrentUser(user))
    dispatch(setUserListForSuperUserRequestParams(userListForSuperUserRequestParamsInitialValue))
    dispatch(setSelectedPersonData(null))
    onClose()
    if (pathname.includes('/patients/')) {
      navigate('/patients')
    }
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      destroyOnClose
      closable={false}
      width={400}
      centered
      // bodyStyle={{
      //   height: 'calc(100vh - 55px)',
      //   overflowY: 'auto'
      // }}
    >
      <SwitchIcon className={classes.switchIcon}/>
      <div className={classes.title}>
        Select user
      </div>
      
      <div className={classes.description}>
        Select a user from the list to switch the account
      </div>

      {(!isLoading || searchValue) &&
        <Input
          placeholder='Search'
          prefix={<SearchIcon />}
          style={{width: '100%', marginBottom: '20px'}}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value)
            onSearch(e.target.value)
          }}
        /> 
      }

      {(isLoading) ? (
        <Spin style={{width: '100%'}}/>
      ) : (
        <>
          {!!searchValue?.length && !userListFilterCount &&
            <div style={{textAlign: 'center'}}>No users found</div>
          }
          {userList?.map(user => (
            <div className={classes.userData} key={user.user_id}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {(user?.first_name || user?.last_name) &&
                  <div className={classes.userName}>
                    {user?.first_name} {user?.last_name}
                  </div>
                }
                <div className={classes.userEmail}>
                  {user?.email}
                </div>
              </div>
              <div
                className={`${classes.selectBtn} ${superUserCurrentUser?.user_id === user.user_id ? classes.disabled : ''}`}
                onClick={() => superUserCurrentUser?.user_id === user.user_id ? {} : selectUser(user)}
              >
                {superUserCurrentUser?.user_id === user.user_id ? 'Selected' : 'Select'}
              </div>
            </div>
          ))}
          {!!userList?.length &&
            <Pagination
              pageSize={10}
              current={userListRequestParams.pagination_request.page}
              total={searchValue?.length ? userListFilterCount : userListTotalCount}
              style={{display: 'flex', justifyContent: 'center'}}
              onChange={page => {
                dispatch(
                  setUserListForSuperUserRequestParams({
                    ...userListRequestParams,
                    pagination_request: {...userListRequestParams.pagination_request, page}
                  })
                )
              }}
            />
          }
        </>
      )}

      <Button
        style={{
          marginTop: '32px',
          width: '100%'
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Modal>
  )
}

export default SelectUserModal
