import { Checkbox } from 'antd'
import classes from './OptionCard.module.css'

const OptionCard: React.FC<OptionCardPropTypes> = ({isSelected, onClick, children, disabled}) => {
  return (
    <div
      className={`${classes.option} ${isSelected ? classes.selected : ''} ${disabled ? classes.disabled : ''}`}
      onClick={() => disabled ? {} : onClick()}
    >
      {children}
      <Checkbox
        style={{position: 'absolute', right: '20px'}}
        onClick={e => e.preventDefault()}
        checked={isSelected}
        disabled={disabled}
      />
    </div>
  )
}

interface OptionCardPropTypes {
  isSelected: boolean
  onClick: () => void
  children: any
  disabled?: boolean
}

export default OptionCard
