/* eslint-disable */
import React from 'react';
import classes from "../../Landing.module.scss";
import parametersImgForMdDevices from "../../../../img/landingImg/User role. Parameters. Perference.png";
import InfoBadge from "../InfoBadge/InfoBadge";
import {useInView, motion} from "framer-motion";
import {useAppSelector} from "../../../../app/hooks";
import {selectCurrentAppWidth} from "../../../../store/userReducer";

const TransformPractise = () => {

    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, {once: true});
    const width = useAppSelector(selectCurrentAppWidth)

    const textVariants = {
        hidden: {opacity: 0, y: -100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 0.7
            }
        }
    };

    const imageVariants = {
        hidden: {opacity: 0, y: 100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 1
            }
        }
    }

    const titleVariants = {
        hidden: {opacity: 0, y: 100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 0.1
            }
        }
    }


    return (
        <div className={`${classes.block} ${classes.block_whiteBg} ${classes.transform}`}
             ref={ref}
        >
            <motion.div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
            }}
                        variants={titleVariants}
                        initial="hidden"
                        animate={isInView ? 'visible' : 'hidden'}
            >
                <h2
                    className={`${classes.commonTitle} ${classes.transform__title}`}
                >
                    Transform Your Practice: Unleash the Power of AI in Mental
                    Health <span className={classes.commonTitle_grey}>Case Formulation</span>
                </h2>
                <p className={`${classes.commonText} ${classes.transform__text}`}>Streamline your workflow and boost
                    efficiency in case
                    formulation. Join us to experience the future of mental health care!</p>
            </motion.div>
            <div className={classes.transform__content}>
                <motion.div
                    variants={imageVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                >
                    {
                        width > 1220
                            ?
                            <img src={parametersImgForMdDevices} alt="parameters" className={classes.transform__img}/>
                            :
                            <img src={parametersImgForMdDevices} alt="parameters" className={classes.transform__img}/>
                    }

                </motion.div>
                <motion.div
                    variants={textVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    className={classes.transform__contentBox}
                >
                    <InfoBadge text={'FEATURES'}/>
                    <h2 className={`${classes.commonTitle} ${classes.transform__contentBoxTitle}`}><span className={classes.commonTitle_blue}>Revolutionize</span> your approach to case
                        formulation in the
                        mental health field with our cutting-edge tool.</h2>
                    <p
                        className={classes.commonText}
                        style={{
                            maxWidth: '87%'
                        }}
                    >
                        Say goodbye to lengthy processes and hello to efficiency as our AI-powered platform
                        streamlines your workflow.</p>
                </motion.div>
            </div>
        </div>
    );
};

export default TransformPractise;