import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { requestAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { NewRequestDataType, RequestListRequestParamsType, RequestDataType, ReportCDType, ReportDataType, ReportTypes, WGKKReportDataType } from '../types/requestTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { SaveDiagnosisToRequestThunk } from './diagnosisReducer'

dayjs.extend(utc)

export const requestListRequestParamsInitialValue = {
  pagination_request: {page: 1, size: 10,  sort: [{field: 'request_end', direction: 'DESC' as 'DESC'}]},
  request_data_request: {
    date_from: dayjs.utc().startOf('month'),
    date_till: dayjs.utc().endOf('day'),
  }
}

interface InitialStateType {
  requestData: null | RequestDataType
  requestList: null | RequestDataType[]
  requestListRequestParams: RequestListRequestParamsType
  requestListTotalCount: number
  requestListFilterCount: number
  wgkkReport: null | RequestDataType
}

const initialState: InitialStateType = {
  requestData: null,
  // requestKeywordsData: null,
  requestList: null,
  requestListRequestParams: requestListRequestParamsInitialValue,
  requestListTotalCount: 0,
  requestListFilterCount: 0,
  wgkkReport: null 
}

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setRequestData: (state, action: PayloadAction<RequestDataType | null>) => {state.requestData = action.payload},
    setRequestList: (state, action: PayloadAction<RequestDataType[] | null>) => {state.requestList = action.payload},
    setRequestListRequestParams: (state, action: PayloadAction<RequestListRequestParamsType>) => {state.requestListRequestParams = action.payload},
    clearRequestListRequestParams: (state) => {state.requestListRequestParams = requestListRequestParamsInitialValue},
    setRequestListTotalCount: (state, action: PayloadAction<number>) => {state.requestListTotalCount = action.payload},
    setRequestListFilterCount: (state, action: PayloadAction<number>) => {state.requestListFilterCount = action.payload},
    setWgkkReport: (state, action: PayloadAction<null | RequestDataType>) => {state.wgkkReport = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(MakePromptThunk.fulfilled, (state, action) => {
        state.requestData = action.payload
        console.log(action.payload)
      })
      .addCase(CreateRequestThunk.fulfilled, (state, action) => {
        state.requestData = action.payload
        console.log(action.payload)
      })
      .addCase(SaveDiagnosisToRequestThunk.fulfilled, (state, action) => {
        state.requestData = action.payload
        console.log(action.payload)
      })
      .addCase(GetRequestListByPersonIdThunk.fulfilled, (state, action) => {
        if (action.payload.infiniteScroll) {
          if ((state.requestList?.length && action.payload.requests?.[0]?.person_id !== state.requestList[0]?.person_id)
            || (state.requestList?.some(r => r.request_id === action.payload.requests[0]?.request_id))) {
            state.requestList = action.payload.requests
          } else {
            state.requestList = [...(state.requestList || []), ...action.payload.requests]
          }
        } else {
          state.requestList = action.payload.requests
        }
        state.requestListTotalCount = action.payload.total_count
        state.requestListFilterCount = action.payload.filter_count
      })
      .addCase(DeleteRequestThunk.fulfilled, (state, action) => {
        state.requestList = state.requestList?.filter(r => r.request_id !== action.payload) || []
        state.requestListTotalCount -= 1
        state.requestListFilterCount -= 1
      })
      .addCase(GoToWGKKReportThunk.fulfilled, (state, action) => {
        state.wgkkReport = action.payload
      })
      .addCase(GenerateWGKKReportAgainThunk.fulfilled, (state, action) => {
        state.wgkkReport = action.payload
      })
      .addCase(SaveWGKKReportThunk.fulfilled, (state) => {
        state.wgkkReport = {...state.wgkkReport!, is_confirmed: true}
      })
  }
})

export const {
  setRequestData,
  setRequestList,
  setRequestListRequestParams,
  clearRequestListRequestParams,
  setRequestListTotalCount,
  setRequestListFilterCount,
  setWgkkReport,
} = requestSlice.actions

export const selectRequestData = (state: RootState): RequestDataType | null => state.request.requestData
export const selectRequestList = (state: RootState): RequestDataType[] | null => state.request.requestList
export const selectRequestListRequestParams = (state: RootState): RequestListRequestParamsType => state.request.requestListRequestParams
export const selectRequestListTotalCount = (state: RootState): number => state.request.requestListTotalCount
export const selectRequestListFilterCount = (state: RootState): number => state.request.requestListFilterCount
export const selectWgkkReport = (state: RootState): null | RequestDataType => state.request.wgkkReport

export const CreateRequestThunk = createAsyncThunk<RequestDataType, NewRequestDataType, AsyncThunkConfig>(
  'request/createRequest',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await requestAPI.createRequest(requestData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditRequestThunk = createAsyncThunk<RequestDataType, {id: number, requestData: NewRequestDataType}, AsyncThunkConfig>(
  'request/editRequest',
  async ({id, requestData}, thunkAPI) => {
    try {
      const { status, data } = await requestAPI.updateRequest(id, requestData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const ConfirmRequestThunk = createAsyncThunk<RequestDataType, number, AsyncThunkConfig>(
  'request/confirmRequest',
  async (requestId, thunkAPI) => {
    try {
      const patchData = {
        is_confirmed: true,
      }
      const { status, data } = await requestAPI.confirmRequest(requestId, patchData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const MakePromptThunk = createAsyncThunk<RequestDataType, {requestId: number, reportType: ReportTypes}, AsyncThunkConfig>(
  'request/makePrompt',
  async ({requestId, reportType}, thunkAPI) => {
    try {
      const { status, data } = await requestAPI.makePrompt(requestId, reportType)
      if (status === 200 && data) {
        const requestData = (data.request_reports?.length > 1 ? thunkAPI.getState().request.requestData : data) as RequestDataType
        let updatedData
        if (requestData.request_reports.some(r => r.rep_cd === reportType && !!r.chat_gpt_completion?.length)) {
          updatedData = {
            ...requestData,
            request_end: data.request_end,
            request_reports: requestData.request_reports.map(r => {
              if (
                (reportType ==='main' && (r.rep_cd === 'literature' || r.rep_cd === 'keywords'))
                || (reportType === 'diagnoses' && r.rep_cd === 'kernberg')
              ) {
                return {...r, chat_gpt_completion: null}
              }
              return r.rep_cd === reportType ? {...r, chat_gpt_completion: data.completion || ''} : r
            })
          } as RequestDataType
        } else {
          const newReportData = data.request_reports.find(r => r.rep_cd === reportType)
          updatedData = {
            ...requestData,
            request_end: data.request_end,
            request_reports: [...requestData.request_reports.filter(r => r.rep_cd !== reportType), {...newReportData, chat_gpt_completion: data.completion}]
          } as RequestDataType
        }
        return thunkAPI.fulfillWithValue(updatedData, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetRequestListByPersonIdThunk = createAsyncThunk<{requests: RequestDataType[], total_count: number, filter_count: number, infiniteScroll: boolean}, {requestParams: RequestListRequestParamsType, infiniteScroll: boolean}, AsyncThunkConfig>(
  'request/getRequestListByPersonId',
  async ({requestParams, infiniteScroll}, thunkAPI) => {
    try {
      const formData = new FormData()
      const request_data_request = {
        ...requestParams.request_data_request,
        ...(requestParams.request_data_request?.date_from && requestParams.request_data_request?.date_till
          ? {
            date_from: requestParams.request_data_request?.date_from?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            date_till: requestParams.request_data_request?.date_till?.format('YYYY-MM-DDTHH:mm:ss[Z]')
          }
          : {}
        ),
      }

      if (requestParams.pagination_request?.page) {
        formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
      }
      formData.append('request_data_request', new Blob([JSON.stringify(request_data_request, null, 2)], {type: 'application/json'}))
      const { status, data } = await requestAPI.getRequestListByPersonId(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({
          requests: data.requests,
          total_count: data.total_count,
          filter_count: data.filter_count,
          infiniteScroll: infiniteScroll,
        }, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteRequestThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'request/deleteRequest',
  async (requestId, thunkAPI) => {
    try {
      const { status, data } = await requestAPI.deleteRequest(requestId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(requestId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SaveRequestResultThunk = createAsyncThunk<ReportDataType, {requestId: number, reportData: {rep_cd: ReportCDType, message: string}[]}, AsyncThunkConfig>(
  'request/saveRequestResult',
  async ({requestId, reportData}, thunkAPI) => {
    try {
      const { status, data } = await requestAPI.saveRequestResult(requestId, reportData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SaveWGKKReportThunk = createAsyncThunk<ReportDataType, {requestId: number, reportData: WGKKReportDataType}, AsyncThunkConfig>(
  'request/saveWGKKReport',
  async ({requestId, reportData}, thunkAPI) => {
    try {
      const { status, data } = await requestAPI.saveWGKKRequestResult(requestId, reportData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Data has been successfully saved!'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GoToWGKKReportThunk = createAsyncThunk<RequestDataType, NewRequestDataType, AsyncThunkConfig>(
  'request/goToWGKKReport',
  async (requestData, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('request_data_request', new Blob([JSON.stringify({rep_cd: 'insurance', person_id: requestData.person_id}, null, 2)], {type: 'application/json'}))
      const requestResp = await requestAPI.getRequestListByPersonId(formData)
      if (!!requestResp.data?.requests?.length) {
        return thunkAPI.fulfillWithValue(requestResp.data?.requests?.[0] as RequestDataType, {appStatus: AppStatusType.idle})
      } else {
        const newRequestResponse = await requestAPI.createRequest(requestData)
        const promptResponse = await requestAPI.makePrompt(newRequestResponse?.data?.request_id, 'insurance')
        if (promptResponse?.data) {
          return thunkAPI.fulfillWithValue(promptResponse.data, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.rejectWithValue(promptResponse?.data)
        }
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GenerateWGKKReportAgainThunk = createAsyncThunk<RequestDataType, {requestId: number, requestData: NewRequestDataType}, AsyncThunkConfig>(
  'request/createWGKKReportAgain',
  async ({requestId, requestData}, thunkAPI) => {
    try {
      await requestAPI.updateRequest(requestId, requestData)
      const { status, data } = await requestAPI.updatePrompt(requestId, {rep_cd: 'insurance'})
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default requestSlice.reducer
 