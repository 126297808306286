/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pickBy } from 'lodash'
import { currentUserAPI } from '../app/api'
import { tokenDataHelper, userDataHelper } from '../helpers/localStorageHelper'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from '../types/appTypes'
import { TokenDataType } from '../types/appTypes'
import {CurrentUserDataType, SignInDataType, SignUpDataType, SuperUserTypes} from '../types/currentUserTypes'
import { ReportSettingsType, SettingsIdDataType } from '../types/requestTypes'
import { UserDataForSuperUserType, UserReportSettingType } from '../types/userTypes'
import { UserBillingSettingsType } from '../types/billingTypes'
import { GetUserBillingSettingsThunk } from './userReducer'
import { SendMessageThunk, StartChatThunk } from './dreamInterpreterReducer'

interface InitialStateType {
  isLoggedIn: boolean
  isAdmin: boolean
  isSuperAdmin: boolean
  isUser: boolean
  isSuperUser: SuperUserTypes
  userData: CurrentUserDataType
  tokenData: TokenDataType
  reportSettings: null | ReportSettingsType
  billingSettings: null |  UserBillingSettingsType
  superUserCurrentUser: null | UserDataForSuperUserType
  chatSettings: {width: number, height: number}
  isRequestLoading: boolean
  isSuccessModalOpened: boolean
}

const initialState: InitialStateType = {
  isLoggedIn: !!userDataHelper.getUserData(),
  isAdmin: userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'admin'),
  isSuperAdmin: userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'superadmin'),
  isUser: userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'user'),
  isSuperUser: userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'superuser ro')
    ? 'RO'
    : userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'superuser rw')
      ? 'RW'
      : false,
  userData: userDataHelper.getUserData(),
  tokenData: tokenDataHelper.getTokenData(),
  reportSettings: null,
  billingSettings: null,
  superUserCurrentUser: userDataHelper.getSuperUserCurrentUser(),
  chatSettings: userDataHelper.getChatSettings(),
  isRequestLoading: false,
  isSuccessModalOpened: false
}

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {state.isLoggedIn = action.payload},
    setUserData: (state, action: PayloadAction<CurrentUserDataType>) => {state.userData = action.payload},
    setReportSettings: (state, action: PayloadAction<ReportSettingsType | null>) => {state.reportSettings = action.payload},
    setBillingSettings: (state, action: PayloadAction<UserBillingSettingsType | null>) => {state.billingSettings = action.payload},
    setSuperUserCurrentUser: (state, action: PayloadAction<null | UserDataForSuperUserType>) => {
      state.superUserCurrentUser = action.payload
      userDataHelper.setSuperUserCurrentUser(action.payload)
    },
    setChatSettings: (state, action: PayloadAction<{width: number, height: number}>) => {
      state.chatSettings = action.payload
      userDataHelper.setChatSettings(action.payload)
    },
    setIsRequestLoading: (state, action: PayloadAction<boolean>) => {state.isRequestLoading = action.payload},
    setIsSuccessModalOpened: (state, action: PayloadAction<boolean>) => {state.isSuccessModalOpened = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignInThunk.fulfilled, (state, action) => {
        const userData = pickBy(action.payload, (_, key) => key !== 'token') as CurrentUserDataType
        tokenDataHelper.setTokenData(action.payload.token!)
        state.isLoggedIn = true
        userDataHelper.setUserData(userData)
        state.userData = userData
        state.isAdmin = userData?.roles?.some(r => r?.role_name?.toLowerCase() === 'admin')
        state.isUser = userData?.roles?.some(r => r?.role_name?.toLowerCase() === 'user')
        state.isSuperUser = userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'superuser ro')
          ? 'RO'
          : userDataHelper.getUserData()?.roles?.some(r => r?.role_name?.toLowerCase() === 'superuser rw')
            ? 'RW'
            : false
      })
      .addCase(GetUserReportSettingsThunk.fulfilled, (state, action) => {
        state.reportSettings = action.payload
      })
      .addCase(UpdateUserParametersThunk.fulfilled, (state, action) => {
        state.reportSettings = {
          instance_list: action.payload.userData.instance_list,
          lang_cd: action.payload.userData.lang_cd,
          lang_name: '',//'action.payload.userData.lang_name',
          report_style: action.payload.userData.report_style,
          report_format: action.payload.userData.report_format,
        }
      })
      .addCase(UpdateUserMainDataThunk.fulfilled, (state, action) => {
        const updatedData = {
          ...state.userData!,
          first_name: action.payload.userData.first_name,
          last_name: action.payload.userData.last_name,
          therapy_method_id: action.payload.userData?.therapy_method_id
        }
        state.userData = updatedData
        userDataHelper.setUserData(updatedData)
      })
      .addCase(UpdateUserReportSettingsThunk.fulfilled, (state, action) => {
        if (action.payload.settingsIdData.to_user_id) {
          state.reportSettings = {...state.reportSettings!, ...action.payload.settings}
        }
      })
      .addCase(GetUserBillingSettingsThunk.fulfilled, (state, action) => {
        if (!!action.payload.isCurrentUser) {
          state.billingSettings = action.payload.billing
        }
      })
      .addCase(StartChatThunk.fulfilled, (state, action) => {
        state.billingSettings = action.payload.user.billing_info!
      })
      .addCase(SendMessageThunk.fulfilled, (state, action) => {
        state.billingSettings = action.payload.user.billing_info!
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.isLoggedIn = false
        state.isAdmin = false
        state.isUser = false
        state.isSuperUser = false
        state.tokenData = {} as TokenDataType
        state.userData = {} as CurrentUserDataType
        state.reportSettings = null
        state.superUserCurrentUser = null
      })
  }
})

export const {
  setIsLoggedIn,
  setUserData,
  setReportSettings,
  setBillingSettings,
  setSuperUserCurrentUser,
  setChatSettings,
  setIsRequestLoading,
  setIsSuccessModalOpened
} = currentUserSlice.actions

export const selectIsLoggedIn = (state: RootState): boolean => state.currentUser.isLoggedIn
export const selectIsAdmin = (state: RootState): boolean => state.currentUser.isAdmin
export const selectIsSuperAdmin = (state: RootState): boolean => state.currentUser.isSuperAdmin
export const selectIsUser = (state: RootState): boolean => state.currentUser.isUser
export const selectIsSuperUser = (state: RootState): SuperUserTypes => state.currentUser.isSuperUser
export const selectUserData = (state: RootState): CurrentUserDataType => state.currentUser.userData
export const selectReportSettings = (state: RootState): ReportSettingsType | null => state.currentUser.reportSettings
export const selectBillingSettings = (state: RootState): UserBillingSettingsType | null => state.currentUser.billingSettings
export const selectSuperUserCurrentUser = (state: RootState): null | UserDataForSuperUserType => state.currentUser.superUserCurrentUser
export const selectChatSettings = (state: RootState): {width: number, height: number} => state.currentUser.chatSettings
export const selectIsRequestLoading = (state: RootState): boolean => state.currentUser.isRequestLoading
export const selectIsSuccessModalOpened = (state: RootState): boolean => state.currentUser.isSuccessModalOpened

export const SignInThunk = createAsyncThunk<CurrentUserDataType, SignInDataType, AsyncThunkConfig>(
  'currentUser/singIn',
  async (loginData, thunkAPI) => {
    try {
      const { status, data } = await currentUserAPI.signIn(loginData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SignUpThunk = createAsyncThunk<CurrentUserDataType, SignUpDataType, AsyncThunkConfig>(
    'currentUser/singUp',
    async (signUpData, thunkAPI) => {
      try {
        const { status, data } = await currentUserAPI.signUp(signUpData)
        if (status === 200 && data) {
          thunkAPI.dispatch(setIsSuccessModalOpened(true))
          thunkAPI.dispatch(setIsRequestLoading(false))
          return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.rejectWithValue(data)
        }
      } catch (error: any)  {
        thunkAPI.dispatch(setIsRequestLoading(false))
        return thunkAPI.rejectWithValue(error?.response?.data?.message)
      }
      finally {
        setTimeout(() => {
          thunkAPI.dispatch(setIsSuccessModalOpened(false))
          window.location.replace('/')
        }, 15000)
      }
    }
)

export const GetUserReportSettingsThunk = createAsyncThunk<ReportSettingsType, number | undefined, AsyncThunkConfig>(
  'currentUser/getUserReportSettings',
  async (userId, thunkAPI) => {
    try {
      let resp
      if (!userId) {
        resp = await currentUserAPI.getUserReportSettings()
      } else {
        resp = await currentUserAPI.getUserReportSettingsByUserId(userId)
      }
      if (resp?.status === 200 && resp?.data) {
        return thunkAPI.fulfillWithValue(resp?.data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(resp?.data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const UpdateUserReportSettingsThunk = createAsyncThunk<{settingsIdData: SettingsIdDataType, settings: UserReportSettingType}, {settingsIdData: SettingsIdDataType, settings: UserReportSettingType}, AsyncThunkConfig>(
  'currentUser/updateUserReportSettings',
  async ({settingsIdData, settings}, thunkAPI) => {
    try {
      const { status, data } = await currentUserAPI.updateUserReportSettings(settingsIdData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue({settingsIdData, settings}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const UpdateUserParametersThunk = createAsyncThunk<{userId: number, userData: UserReportSettingType}, {userId: number, userData: UserReportSettingType}, AsyncThunkConfig>(
  'currentUser/updateUserParameters',
  async ({userId, userData}, thunkAPI) => {
    try {
      const updatedData = {
        ...userData,
        instance_list: userData.instance_list.map(i => i.id)
      }
      const { status, data } = await currentUserAPI.updateUserParameters(userId, updatedData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue({userId, userData}, {appStatus: AppStatusType.succeeded, appMessage: 'Preferences has been updated'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const UpdateUserMainDataThunk = createAsyncThunk<{userId: number, userData: {first_name: string, last_name: string, therapy_method_id?: number, password?: string}}, {userId: number, userData: {first_name: string, last_name: string, therapy_method_id?: number, password?: string}}, AsyncThunkConfig>(
  'currentUser/updateUserMainData',
  async ({userId, userData}, thunkAPI) => {
    try {
      const { status, data } = await currentUserAPI.updateUserMainData(userId, userData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue({userId, userData}, {appStatus: AppStatusType.succeeded, appMessage: 'Data has been changed!'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SignOutThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
  'currentUser/signOut', (_, thunkAPI) => {
    userDataHelper.removeUserData()
    userDataHelper.removeSuperUserCurrentUser()
    tokenDataHelper.removeTokenData()
    return thunkAPI.fulfillWithValue(_, {appStatus: AppStatusType.idle})
  }
)

export default currentUserSlice.reducer
 