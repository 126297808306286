import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import classes from './StartScreen.module.css'
import { ReactComponent as WelcomeBg } from './../../../../img/dreamInterpreter/welcomeBg.svg'
import { ReactComponent as WelcomeRobot } from './../../../../img/dreamInterpreter/welcomeRobot.svg'
import { ReactComponent as CloseIcon } from './../../../../img/icons/close.svg'
import { useState } from 'react'

const StartScreen:React.FC<StartScreenPropTypes> = ({onClose, startChat}) => {
  const { t } = useTranslation(['commonComponents'])

  const [isLoading, setIsLoading] = useState(false)

  const onStartChat = () => {
    setIsLoading(true)
    startChat()
      .then(() => setIsLoading(false))
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.title}>
          {t('dreamInterpreter.startScreen.title')}
        </div>
        <div className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <WelcomeBg style={{height: '100%', position: 'absolute', width: '100%'}}/>

      <WelcomeRobot style={{position: 'relative', height: '100%'}}/>
      
      <div style={{margin: '0px 44px'}}>
        <div className={classes.text}>
          {t('dreamInterpreter.startScreen.text')}
        </div>
        <Button
          className={classes.startBtn}
          onClick={onStartChat}
          loading={isLoading}
        >
          {t('dreamInterpreter.startScreen.startBtn')}
        </Button>
      </div>
    </div>
  )
}

interface StartScreenPropTypes {
  onClose: () => void
  startChat: () => Promise<any>
}

export default StartScreen
