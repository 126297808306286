import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { ReactNode } from 'react'

const TooltipHint:React.FC<TooltipHintPropTypes> = ({title, children, hide, overlayInnerStyle, placement}) => {
  if (!!hide) {
    return <>{children}</>
  }

  return (
    <Tooltip
      title={title}
      color='white'
      overlayInnerStyle={{color: '#344054', fontSize: '12px', fontWeight: 600, ...overlayInnerStyle}}
      placement={placement}
      autoAdjustOverflow={false}
    >
      {children}
    </Tooltip>
  )
}

interface TooltipHintPropTypes {
  title: string | ReactNode
  children: any
  hide?: boolean
  overlayInnerStyle?: object
  placement?: TooltipPlacement
}

export default TooltipHint
