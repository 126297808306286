import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { AppStatusType } from './appStatusReducer'
import { FormatReferenceType, LanguageReferenceType, StyleReferenceType, TherapyMethodType } from '../types/referenceTypes'
import { referenceAPI } from '../app/api'

interface InitialStateType {
  styleReferences: null | StyleReferenceType[]
  languageReferences: null | LanguageReferenceType[]
  formatReferences: null | FormatReferenceType[]
  therapyMethods: null | TherapyMethodType[]
}

const initialState: InitialStateType = {
  styleReferences: null,
  languageReferences: null,
  formatReferences: null,
  therapyMethods: null,
}

export const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  reducers: {
    setStyleReferences: (state, action: PayloadAction<StyleReferenceType[] | null>) => {state.styleReferences = action.payload},
    setLanguageReferences: (state, action: PayloadAction<LanguageReferenceType[] | null>) => {state.languageReferences = action.payload},
    setFormatReferences: (state, action: PayloadAction<FormatReferenceType[] | null>) => {state.formatReferences = action.payload},
    setTherapyMethods: (state, action: PayloadAction<TherapyMethodType[] | null>) => {state.therapyMethods = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetStyleReferencesThunk.fulfilled, (state, action) => {
        state.styleReferences = action.payload
      })
      .addCase(GetLanguageReferencesThunk.fulfilled, (state, action) => {
        state.languageReferences = action.payload
      })
      .addCase(GetFormatReferencesThunk.fulfilled, (state, action) => {
        state.formatReferences = action.payload
      })
      .addCase(GetTherapyMethodsThunk.fulfilled, (state, action) => {
        state.therapyMethods = action.payload
      })
  }
})

export const {
  setStyleReferences,
  setLanguageReferences,
  setFormatReferences,
} = referenceSlice.actions

export const selectStyleReferences = (state: RootState): StyleReferenceType[] | null => state.reference.styleReferences
export const selectLanguageReferences = (state: RootState): LanguageReferenceType[] | null => state.reference.languageReferences
export const selectFormatReferences = (state: RootState): FormatReferenceType[] | null => state.reference.formatReferences
export const selectTherapyMethods = (state: RootState): TherapyMethodType[] | null => state.reference.therapyMethods

export const GetStyleReferencesThunk = createAsyncThunk<StyleReferenceType[], void, AsyncThunkConfig>(
  'reference/getStyleReferences',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await referenceAPI.getStyleReferences()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.styles, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetLanguageReferencesThunk = createAsyncThunk<LanguageReferenceType[], void, AsyncThunkConfig>(
  'reference/getLanguageReferences',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await referenceAPI.getLanguageReferences()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.languages, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetFormatReferencesThunk = createAsyncThunk<FormatReferenceType[], void, AsyncThunkConfig>(
  'reference/getFormatReferences',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await referenceAPI.getFormatReferences()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.formats, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetTherapyMethodsThunk = createAsyncThunk<TherapyMethodType[], void, AsyncThunkConfig>(
  'reference/getTherapyMethods',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await referenceAPI.getTherapyMethods()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.therapy_methods, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default referenceSlice.reducer
 