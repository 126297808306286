import classes from './RadioGroup.module.css'

const RadioGroup: React.FC<RadioGroupPropType> = ({onChange, value, options, activeStyles, style, children}) => {
  return (
    <div className={classes.wrapper} style={style}>
      {options.map(opt => (
        <div
          key={opt.value}
          onClick={() => onChange(opt.value)}
          className={`${classes.option} ${value === opt.value ? classes.active : ''}`}
          style={{
            color: opt?.color,
            backgroundColor: opt?.bgColor,
            ...(value === opt.value && activeStyles ? activeStyles : {})
          }}
        >
          {opt.label}
        </div>
      ))}
      {children}
    </div>
  )
}

type ValueType = string | number

interface RadioGroupPropType {
  options: {label: string, value: ValueType, color?: string, bgColor?: string}[]
  onChange: (value: string | number) => void
  value: ValueType
  activeStyles?: object
  style?: object
  withAddInstanceButton?: boolean
  children?: any
}

export default RadioGroup
