import { Input } from 'antd'
import classes from './NotesTab.module.css'
import { SessionFormDataType } from '../../../../types/sessionTypes'

const NotesTab: React.FC<NotesTabPropTypes> = ({setSessionData, sessionData, setHasDataChanged}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        Notes for session
      </div>
      <Input.TextArea
        placeholder='Enter notes for session...'
        onChange={e => {
          setHasDataChanged(true)
          setSessionData({...sessionData, mainData:{...sessionData.mainData, extra_data: e.target.value}})
        }}
        value={sessionData.mainData.extra_data}  
      />
    </div>
  )
}

interface NotesTabPropTypes {
  isEditing: boolean
  setSessionData: (data: SessionFormDataType) => void
  sessionData: SessionFormDataType
  setHasDataChanged: (hasChanged: boolean) => void
}

export default NotesTab
