/* eslint-disable */
import React from 'react'
import classes from "../../Landing.module.scss"
import InfoBadge from "../InfoBadge/InfoBadge"
import patientsImg from "../../../../img/landingImg/List of Patients. Sessions1.png"
import {motion, useInView} from "framer-motion"
import {useNavigate} from "react-router-dom"
import patientsForMdDevicesImg from './../../../../img/landingImg/List of Patients. SessionsFooter (1).png'
import {useAppSelector} from "../../../../app/hooks"
import {selectCurrentAppWidth} from "../../../../store/userReducer"

const LandingHero = () => {
        const ref = React.useRef<HTMLDivElement>(null);
        const isInView = useInView(ref, {once: true});
        const navigate = useNavigate()
        const width = useAppSelector(selectCurrentAppWidth)

        const textVariants = {
            hidden: {opacity: 0, y: -100},
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    mass: 2,
                    duration: 1,
                    delay: 0.3
                }
            }
        };

        const imageVariants = {
            hidden: {opacity: 0, y: 100},
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    mass: 2,
                    duration: 1.3,
                    delay: 0.7
                }
            }
        };


        const buttonVariants = {
            hidden: {opacity: 0},
            visible: {
                opacity: 1,
                transition: {duration: 1, delay: 1}

            }
        }

        const onVisitLoginPage = () => {
            navigate('/sign-in')
        }

        const onVisitSignInPage = () => {
            navigate('/sign-up')
        }

        return (
            <div className={`${classes.block} ${classes.block_greyBg}`} ref={ref}>
                <div className={classes.landingHero}>
                    <motion.div
                        variants={textVariants}
                        initial="hidden"
                        animate={isInView ? 'visible' : 'hidden'}
                        className={classes.landingHero__inner}
                    >
                        <InfoBadge text={'COMING IN BETA'}/>
                        <h1 className={classes.landingHero__title}>Revolutionize Mental Health with AI</h1>
                        <p
                            className={`${classes.commonText} ${classes.landingHero__text}`}
                            style={{
                                maxWidth: '87%'
                            }}
                        >
                            Experience a paradigm shift in mental health care with our
                            revolutionary tool. Harness the latest in AI technology to streamline your workflow,
                            saving
                            time and enhancing accuracy. Join us and revolutionize the way you approach case
                            formulation
                            today!</p>
                        <motion.div
                            className={classes.mainBlock__buttons}
                            variants={buttonVariants}
                            initial="hidden"
                            animate={isInView ? 'visible' : 'hidden'}
                        >
                            <button className={classes.loginBtn} onClick={onVisitLoginPage}>Sign in</button>
                            <button className={classes.earlyAccessBtn} onClick={onVisitSignInPage}>Get early access</button>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        variants={imageVariants}
                        initial="hidden"
                        animate={isInView ? 'visible' : 'hidden'}
                        className={classes.landingHero__imgInner}
                    >
                        {
                            width <= 480
                                ?
                                <img src={patientsForMdDevicesImg} alt="patients" className={classes.landingHero__img}/>
                                :
                                width < 1220
                                    ?
                                    <img src={patientsForMdDevicesImg} alt="patients" className={classes.landingHero__img}/>
                                    :
                                    <img src={patientsImg} alt="patients" className={classes.landingHero__img}/>
                        }

                    </motion.div>
                </div>
            </div>
        );
    }
;

export default LandingHero;