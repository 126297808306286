/* eslint-disable */
import {Button, Divider, Form, Input, InputNumber, Select} from 'antd'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import classes from './GeneralTab.module.css'
import {useAppDispatch, useAppSelector} from '../../../../app/hooks'
import PopoverConfirm from '../../../common/PopoverConfirm/PopoverConfirm'
import {
    CreateUserThunk,
    EditUserThunk,
    GetUserRoleListThunk,
    selectRoleList,
    selectSelectedUserData,
    getUserGeneralData,
    setSelectedUserData,
    selectUserDefaultSettings,
    GetUserDefaultSettingThunk,
    setUserDetailsActiveTabKey, selectPublicKey
} from '../../../../store/userReducer'
import {NewUserType} from '../../../../types/userTypes'
import {
    GetFormatReferencesThunk,
    GetLanguageReferencesThunk,
    GetStyleReferencesThunk,
    GetTherapyMethodsThunk,
    selectFormatReferences,
    selectLanguageReferences,
    selectStyleReferences,
    selectTherapyMethods
} from '../../../../store/referenceReducer'
import {GetInstanceListThunk, selectInstanceList} from '../../../../store/instanceReducer'
import TagMultiSelect from '../../../common/TagMultiSelect/TagMultiSelect'
import TooltipHint from '../../../common/TooltipHint/TooltipHint'
import {ReactComponent as HelpIcon} from './../../../../img/icons/help.svg'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import {sortBy} from 'lodash'
import CryptoJS from "crypto-js"
import dayjs from "dayjs"
import {selectIsAdmin, selectIsSuperAdmin} from "../../../../store/currentUserReducer"

const GeneralTab: React.FC<GeneralTabPropTypes> = ({
                                                       isEditing,
                                                       setHasDataChanged,
                                                       isBackLinkConfirmModalShown,
                                                       setIsBackLinkConfirmModalShown
                                                   }) => {
    const {t} = useTranslation(['forms', 'pages', 'app'])
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const selectedUserData = useAppSelector(selectSelectedUserData)
    const roleList = useAppSelector(selectRoleList)
    const styleReferences = useAppSelector(selectStyleReferences)
    const languageReferences = useAppSelector(selectLanguageReferences)
    const formatReferences = useAppSelector(selectFormatReferences)
    const therapyMethods = useAppSelector(selectTherapyMethods)
    const instanceList = useAppSelector(selectInstanceList)
    const userDefaultSettings = useAppSelector(selectUserDefaultSettings)
    const CRYPTOR_KEY = useAppSelector(selectPublicKey)
    const [isSaving, setIsSaving] = useState(false)
    const isAdmin = useAppSelector(selectIsAdmin)
    const isSuperAdmin = useAppSelector(selectIsSuperAdmin)

    useEffect(() => {
        Promise.all([
            ...(isEditing ? [] : [dispatch(GetUserDefaultSettingThunk())]),
            ...(instanceList === null ? [dispatch(GetInstanceListThunk())] : []),
            ...(roleList === null ? [dispatch(GetUserRoleListThunk())] : []),
        ])
    }, [dispatch, instanceList, roleList, isEditing])

    useEffect(() => {
        if (isEditing && selectedUserData?.user_id) {
            form.setFieldsValue({
                ...getUserGeneralData(selectedUserData),
                roles: selectedUserData.roles.map(r => String(r.role_id))
            } as NewUserType)
        } else if (!isEditing) {
            form.setFieldsValue({
                instance_list: userDefaultSettings?.instances?.map(i => String(i.id)),
                roles: userDefaultSettings?.roles?.map(r => String(r.role_id)),
                lang_cd: userDefaultSettings?.lang,
                report_style: userDefaultSettings?.style,
                report_format: userDefaultSettings?.format,
            })
        }
    }, [isEditing, selectedUserData, form, userDefaultSettings])

    useEffect(() => {
        Promise.all([
            ...(styleReferences === null ? [dispatch(GetStyleReferencesThunk())] : []),
            ...(languageReferences === null ? [dispatch(GetLanguageReferencesThunk())] : []),
            ...(formatReferences === null ? [dispatch(GetFormatReferencesThunk())] : []),
            ...(therapyMethods === null ? [dispatch(GetTherapyMethodsThunk())] : []),
        ])
    }, [dispatch, styleReferences, languageReferences, formatReferences, therapyMethods])

    const saveData = async (userData: NewUserType) => {
        const encryptedPassword = encrypt(userData.password, CRYPTOR_KEY)
        const data = isAdmin || isSuperAdmin ? {
                ...userData,
                roles: roleList?.filter(role => (userData.roles as string[]).includes(String(role.role_id)))!,
                instance_list: userData.instance_list.map(instance => +instance),
                password: encryptedPassword,
                is_confirmed: true,
                confirmation_date: dayjs(),
                ...(isEditing ? {is_active: selectedUserData?.is_active!} : {})
            } as NewUserType
            :
            {
                ...userData,
                roles: roleList?.filter(role => (userData.roles as string[]).includes(String(role.role_id)))!,
                instance_list: userData.instance_list.map(instance => +instance),
                password: encryptedPassword,
                ...(isEditing ? {is_active: selectedUserData?.is_active!} : {})
            } as NewUserType
        return isEditing
            ? dispatch(EditUserThunk({
                userId: +pathname.split('/users/edit/')[1],
                userData: data
            }))
            : dispatch(CreateUserThunk(data))
    }

    const handleSave = (userData: NewUserType) => {
        return form.validateFields({validateOnly: true}).then(() => {
            setIsSaving(true)
            return saveData(userData)
                .then((r) => {
                    setIsBackLinkConfirmModalShown(false)
                    setIsSaving(false)
                    dispatch(setSelectedUserData(null))
                    !r.type.includes('rejected') && navigate('/users')
                })
        }).catch(err => {
            form.setFields(err.errorFields)
            dispatch(setUserDetailsActiveTabKey('general'))
            setIsBackLinkConfirmModalShown(false)
            return Promise.resolve()
        })
    }

    // Encrypt function using CryptoJS
    function encrypt(text: string, base64Key: string): string {
        const key = CryptoJS.enc.Base64.parse(base64Key)
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return encrypted.toString()
    }

    return (
        <div className={classes.wrapper}>
            <h2>
                {t('userDetails.general.title', {ns: 'pages'})}
            </h2>
            <h3>
                {isEditing ? t('userDetails.general.descriptionEdit', {ns: 'pages'}) : t('userDetails.general.descriptionAdd', {ns: 'pages'})}
            </h3>
            <Divider style={{margin: '20px 0px'}}/>
            <Form
                name='user'
                onFinish={handleSave}
                autoComplete='off'
                validateTrigger='onBlur'
                form={form}
                disabled={isEditing && !selectedUserData?.is_active}
                onFieldsChange={() => setHasDataChanged(true)}
            >
                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.firstName.label')}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Form.Item
                            name='first_name'
                            rules={[{required: true, message: t('fields.firstName.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <Input placeholder={t('fields.firstName.placeholder')}/>
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.lastName.label')}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Form.Item
                            name='last_name'
                            rules={[{required: true, message: t('fields.lastName.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <Input placeholder={t('fields.lastName.placeholder')}/>
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.email.label')}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Form.Item
                            name='email'
                            rules={[
                                {required: true, message: t('fields.email.validationMessage')},
                                {type: 'email', message: t('fields.email.formatValidationMessage')}
                            ]}
                            style={{width: '100%'}}
                        >
                            <Input placeholder={t('fields.email.placeholder')}/>
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.phone.label')}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Form.Item
                            name='phone_number'
                            // rules={[{required: true, message: t('fields.phone.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <InputNumber
                                placeholder={t('fields.phone.placeholder')}
                                controls={false}
                                min={0}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.roles.label')}
                    </div>
                    <div className={classes.inputWrapper} id='user-roles-select'>
                        <Form.Item
                            name='roles'
                            rules={[{required: true, message: t('fields.roles.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <Select
                                placeholder={t('fields.roles.placeholder')}
                                options={roleList?.map(role => ({label: role.role_name, value: String(role.role_id)}))}
                                getPopupContainer={() => document.getElementById('user-roles-select')!}
                                mode='tags'
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.therapyMethods.label')}
                    </div>
                    <div className={classes.inputWrapper} id='person-output-methods-select'>
                        <Form.Item name='therapy_method_id' style={{width: '100%'}}>
                            <Select
                                placeholder={t('fields.therapyMethods.placeholder')}
                                options={therapyMethods?.map(method => ({label: method.name, value: method.id}))}
                                getPopupContainer={() => document.getElementById('person-output-methods-select')!}
                            />
                        </Form.Item>
                        <TooltipHint
                            overlayInnerStyle={{width: '400px'}}
                            placement='right'
                            title={
                                <>
                                    <ul style={{padding: '10px', margin: '0px'}}>
                                        {t(`fields.therapyMethods.patientHintTitle`)}
                                        {therapyMethods?.map(method => (
                                            <li key={method.id}
                                                style={{fontWeight: 400, paddingLeft: '5px', marginLeft: '15px'}}>
                        <span style={{fontWeight: 600, marginRight: '6px', color: '#344054'}}>
                          {method.name}:
                        </span>
                                                <span style={{color: '#667085'}}>
                          {method.description}
                        </span>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            }
                        >
                            <HelpIcon/>
                        </TooltipHint>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t(`fields.password.label`)}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Form.Item
                            name='password'
                            rules={[
                                ...(isEditing ? [] : [{
                                    required: true,
                                    message: t(`fields.password.validationMessage`)
                                }])
                            ]}
                            style={{width: '100%'}}
                        >
                            <Input placeholder={isEditing ? '∗∗∗∗∗∗∗∗∗' : t(`fields.password.placeholder`)}/>
                        </Form.Item>
                    </div>
                </div>

                <Divider/>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.instances.label')}
                    </div>
                    <div className={classes.inputWrapper} id='user-instances-select'>
                        <Form.Item
                            name='instance_list'
                            rules={[{required: true, message: t('fields.instances.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <TagMultiSelect
                                placeholder={t('fields.instances.placeholder')}
                                options={sortBy(instanceList, i => i.order_num)?.map(instance => ({
                                    label: instance.name,
                                    value: String(instance.id),
                                    color: instance.color
                                })) || []}
                                getPopupContainer={() => document.getElementById('user-instances-select')!}
                                tagColor='#3B414B'
                            />
                        </Form.Item>
                        <TooltipHint title={t('fields.instances.userHint')}>
                            <HelpIcon/>
                        </TooltipHint>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.language.label')}
                    </div>
                    <div className={classes.inputWrapper} id='person-output-language-select'>
                        <Form.Item
                            name='lang_cd'
                            rules={[{required: true, message: t('fields.language.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <Select
                                placeholder={t('fields.language.placeholder')}
                                options={languageReferences?.map(ref => ({label: ref.language, value: ref.lang_cd}))}
                                getPopupContainer={() => document.getElementById('person-output-language-select')!}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.styles.label')}
                    </div>
                    <div className={classes.inputWrapper} id='person-output-style-select'>
                        <Form.Item
                            name='report_style'
                            rules={[{required: true, message: t('fields.styles.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <Select
                                placeholder={t('fields.styles.placeholder')}
                                options={styleReferences?.map(ref => ({label: ref.style, value: ref.style}))}
                                getPopupContainer={() => document.getElementById('person-output-style-select')!}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.format.label')}
                    </div>
                    <div className={classes.inputWrapper} id='person-output-format-select'>
                        <Form.Item
                            name='report_format'
                            rules={[{required: true, message: t('fields.format.validationMessage')}]}
                            style={{width: '100%'}}
                        >
                            <Select
                                placeholder={t('fields.format.placeholder')}
                                options={formatReferences?.map(ref => ({label: ref.format, value: ref.format}))}
                                getPopupContainer={() => document.getElementById('person-output-format-select')!}
                            />
                        </Form.Item>
                    </div>
                </div>

                <Divider/>

                <div className={classes.formBlock}>
                    <div className={classes.label}>
                        {t('fields.comment.label')}
                    </div>
                    <div className={classes.inputWrapper}>
                        <Form.Item
                            name='comment'
                            // rules={[
                            //   ...(isEditing ? [] : [{required: true, message: t('fields.format.validationMessage')}])
                            // ]}
                            style={{width: '100%'}}
                        >
                            <Input.TextArea placeholder={t('fields.comment.placeholder')} rows={3}/>
                        </Form.Item>
                    </div>
                </div>

                {
                    <div className={`${classes.actionButtons} actionButtons`}>
                        <PopoverConfirm
                            title={t('warnings.cancelation', {ns: 'app'})}
                            onConfirm={() => navigate('/users')}
                        >
                            <Button>
                                {t('actions.cancel', {ns: 'app'})}
                            </Button>
                        </PopoverConfirm>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={isSaving}
                            >
                                {t('actions.save', {ns: 'app'})}
                            </Button>
                        </Form.Item>
                    </div>
                }
            </Form>
            <ConfirmationModal
                open={!!isBackLinkConfirmModalShown}
                title={t('warnings.unsavedChanges.title', {ns: 'app'})}
                description={t('warnings.unsavedChanges.description', {ns: 'app'})}
                onClose={() => navigate('/users')}
                onConfirm={() => handleSave(form.getFieldsValue(true))}
                type='warning'
                confirmText={t('actions.save', {ns: 'app'})}
            />
        </div>
    )
}

interface GeneralTabPropTypes {
    isEditing: boolean
    setHasDataChanged: (hasChanged: boolean) => void
    isBackLinkConfirmModalShown: boolean
    setIsBackLinkConfirmModalShown: (isOpen: boolean) => void
}

export default GeneralTab
