import { message } from 'antd'
import { random } from 'lodash'

export const copyToClipboard = (ids: {id: string, title?: string}[], successMessage: string) => {
  const tempContainer = document.createElement('div')
  tempContainer.style.position = 'absolute'
  tempContainer.style.left = '-9999px' // Move off-screen
  document.body.appendChild(tempContainer)

  ids.forEach(({id, title}) => {
    const element = document.getElementById(id)
    if (element) {
      element.style.backgroundColor = 'transparent'
      if (!!title) {
        const titleDiv = document.createElement('div')
        titleDiv.innerHTML = title
        titleDiv.style.fontSize = '22px'
        titleDiv.style.textAlign = 'left'
        tempContainer.appendChild(titleDiv)
        tempContainer.appendChild(document.createElement('br'))
      }
      tempContainer.appendChild(element.cloneNode(true))
      tempContainer.appendChild(document.createElement('br')) // Add a line break between divs
    }
  })

  const range = document.createRange()
  range.selectNodeContents(tempContainer)
  window?.getSelection()?.removeAllRanges()
  window?.getSelection()?.addRange(range)

  try {
    document.execCommand('copy')
    !!successMessage?.length && message.success(successMessage)
  } catch (err) {
    console.error('Unable to copy text to clipboard', err)
  } finally {
    document.body.removeChild(tempContainer) // Remove the temporary container
    window?.getSelection()?.removeAllRanges()
  }
}

export const generateTempId = (items: any[]) => {
  const newId = random(1, 100)
  if (items?.some(i => i.temp_id === newId)) {
    generateTempId(items)
  } else {
    return newId
  }
}

export const capitalizeWords = (str: string) => {
  return str.replace(/\b\w/g, (match) => match.toUpperCase())
}
