import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { AppStatusType } from './appStatusReducer'
import { ChatType } from '../types/dreamInterpreterTypes'
import { dreamInterpreterAPI } from '../app/api'
import { SignOutThunk } from './currentUserReducer'
import dayjs from 'dayjs'
import {userDataHelper} from "../helpers/localStorageHelper"

interface InitialStateType {
  currentChat: undefined | null | ChatType
  currentChatStage: 'Pre chat' | 'Prompt pick' | 'Started'
}

const initialState: InitialStateType = {
  currentChat: undefined,
  currentChatStage: userDataHelper.getChatCurrentStage()
}

export const dreamInterpreterSlice = createSlice({
  name: 'dreamInterpreter',
  initialState,
  reducers: {
    setCurrentChat: (state, action: PayloadAction<ChatType | null | undefined>) => {state.currentChat = action.payload},
    setNewUserMessage: (state, action: PayloadAction<string>) => {
      state.currentChat = {
        ...state.currentChat!,
        conversations: [
          ...state.currentChat?.conversations || [],
          {
            id: 0,
            prompt_text: action.payload,
            completion_text: '',
            free_chat_id: 0,
            messageTime: dayjs().format()
          }
        ]
      }
    },
    setCurrentChatStage: (state, action: PayloadAction<'Pre chat' | 'Prompt pick' | 'Started'>) => {state.currentChatStage = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(StartChatThunk.fulfilled, (state, action) => {
        state.currentChat = action.payload
      })
      .addCase(StopChatThunk.fulfilled, (state, ) => {
        state.currentChat = null
      })
      .addCase(GetLastActiveChatThunk.fulfilled, (state, action) => {
        state.currentChat = action.payload
      })
      .addCase(SendMessageThunk.fulfilled, (state, action) => {
        state.currentChat = action.payload
      })
      .addCase(SendMessageThunk.rejected, (state, action) => {
        if (action.payload === 'canceled') {
          state.currentChat = {
            ...state.currentChat!,
            conversations: state.currentChat!.conversations.map((c, i) => i === state.currentChat!.conversations?.length - 1 ? {...c, completion_text: 'Warning', is_warning: true} : c)
          } 
        }
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.currentChat = undefined
      })
  }
})

export const {
  setCurrentChat,
  setNewUserMessage,
  setCurrentChatStage
} = dreamInterpreterSlice.actions

export const selectCurrentChat = (state: RootState): ChatType | null | undefined => state.dreamInterpreter.currentChat
export const selectCurrentChatStage = (state: RootState): 'Pre chat' | 'Prompt pick' | 'Started' => state.dreamInterpreter.currentChatStage

export const StartChatThunk = createAsyncThunk<ChatType, string, AsyncThunkConfig>(
  'dreamInterpreter/startChat',
  async (perspective, thunkAPI) => {
    try {
      const { status, data } = await dreamInterpreterAPI.startChat(perspective)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const StopChatThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
  'dreamInterpreter/stopChat',
  async (freeChatId, thunkAPI) => {
    try {
      const { status, data } = await dreamInterpreterAPI.stopChat(freeChatId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetLastActiveChatThunk = createAsyncThunk<ChatType | null, void, AsyncThunkConfig>(
  'dreamInterpreter/getLastActiveChat',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await dreamInterpreterAPI.getLastActiveChat()
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data?.id ? data : null, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SendMessageThunk = createAsyncThunk<ChatType, {freeChatId: number, message: string}, AsyncThunkConfig>(
  'dreamInterpreter/sendMessage',
  async ({freeChatId, message}, thunkAPI) => {
    try {
      const { status, data } = await dreamInterpreterAPI.sendMessage(freeChatId, message)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message === 'Not enough credits' ? 'canceled' : error?.response?.data?.message)
    }
  }
)

export default dreamInterpreterSlice.reducer
 