import classes from './Header.module.css'
import { useAppSelector } from '../../app/hooks'
import { Link, useLocation } from 'react-router-dom'
import logo from './../../img/logo.png'
import CurrentUserMenu from './CurrentUserMenu/CurrentUserMenu'
import { getUIPermission } from '../../store/appStatusReducer'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation(['commonComponents'])
  const { pathname } = useLocation()

  const menuItems = [
    ...(useAppSelector(getUIPermission('Tab.Users')) ? [{link: '/users', name: t('header.menu.users')}] : []),
    ...(useAppSelector(getUIPermission('Tab.Patients')) ? [{link: '/patients', name: t('header.menu.patients')}] : []),
    ...(useAppSelector(getUIPermission('Tab.Parameters')) ? [{link: '/parameters', name: t('header.menu.parameters')}] : []),
    ...(useAppSelector(getUIPermission('Tab.Instances')) ? [{link: '/instances', name: t('header.menu.instances')}] : []),
    ...(useAppSelector(getUIPermission('Tab.Faq')) ? [{link: '/faq', name: t('header.menu.faq')}] : []),
  ]

  return (
    <div className={classes.wrapper}>
      <div>
        <img src={logo} alt='logo' className={classes.logo}/>
        {menuItems.map(item => (
          <Link
            to={item.link}
            className={`${classes.menuItem} ${pathname.startsWith(item.link) ? classes.active : ''}`}
            key={item.link}
          >
            {item.name}
          </Link>
        ))}
      </div>

      <CurrentUserMenu /> 
    </div>
  )
}

export default Header
