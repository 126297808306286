import dayjs from 'dayjs'
import { Button, Divider } from 'antd'
import React from 'react'
import utc from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectSessionData, setSessionData } from '../../../store/sessionReducer'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import classes from './SessionDetails.module.css'
import { SessionDataType } from '../../../types/sessionTypes'
import { Link } from 'react-router-dom'
import { selectSelectedPersonData } from '../../../store/personReducer'

const SessionDetails = () => {
  dayjs.extend(utc)
  const dispatch = useAppDispatch()
  const sessionData = useAppSelector(selectSessionData)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)

  const getGroupedInstances = () => {
    return sessionData?.person_data_rec_list.reduce((acc, item) => {
      const key = item.instance.name
      if (acc[key] !== undefined) {
        acc[key] = [...acc[key], item]
      } else {
        acc[key] = [item]
      }
      return acc
    }, {} as {[key: string]: SessionDataType[]})
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sessionTitle}>
        <div>
          <GoBackLink
            onClick={() => dispatch(setSessionData(null))}
            text='To list of sessions'
            style={{marginBottom: '10px'}}
          />
          Session {dayjs.utc(sessionData?.rec_date).format('DD.MM.YYYY HH:mm')}
        </div>
        <Link to={`/patients/${selectedPersonData?.id}/sessions/edit/${sessionData?.id}`}>
          <Button>
            Edit
          </Button>
        </Link>
      </div>
      <Divider />
      <div className={classes.notesLabel}>
        Note for session
      </div>
      {!!sessionData?.extra_data?.length
        ? (
          <div className={classes.sessionNote}>
            {sessionData?.extra_data || ''}
          </div>
        ) : (
          <div style={{fontSize: '18px'}}>
            No notes for session
          </div>
        )
      }
      {Object.entries(getGroupedInstances() || {})?.map(([instanceName, instanceItems]) => {
        return (
          <div key={instanceName}>
            <div className={classes.instanceName}>
              {instanceName}
            </div>
            {instanceItems.map(item => (
              <div key={item.id} className={classes.instanceItem}>
                <div className={classes.topicName}>
                  {item?.topic?.name}
                </div>
                {item?.subtopics?.map(subtopic => (
                  <React.Fragment key={subtopic.id}>
                    <div className={classes.subtopic} key={subtopic.id}>
                      <div
                        className={classes.subtopicName}
                        style={{
                          backgroundColor: item?.instance?.color,
                        }}
                      >
                        {subtopic.name}
                      </div>
                      <div className={classes.subtopicInfo}>
                        {subtopic.description}
                      </div>
                    </div>
                    {!!subtopic?.note?.length &&
                      <div className={classes.comments}>
                        Comments: <span>{subtopic?.note}</span>
                      </div>
                    }
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default SessionDetails
