import { TokenDataHelperType, UserDataHelperType } from '../types/localStorageDataTypes'

export const tokenDataHelper: TokenDataHelperType = {
  setTokenData(data) {
    localStorage.setItem('tokenData', JSON.stringify(data))
  },
  getTokenData() {
    const tokenData = localStorage.getItem('tokenData')
    return !!tokenData && JSON.parse(tokenData)
  },
  removeTokenData() {
    return localStorage.removeItem('tokenData')
  },
}

export const userDataHelper: UserDataHelperType = {
  setUserData(data) {
    localStorage.setItem('userData', JSON.stringify(data))
  },
  setUserAvatar(avatarUrl) {
    const userData = localStorage.getItem('userData')
    if (!!userData && !!avatarUrl) {
      localStorage.setItem('userData', JSON.stringify({photo_url: avatarUrl, ...JSON.parse(userData)}))
    }
  },
  setSuperUserCurrentUser(superUserCurrentUser) {
    localStorage.setItem('superUserCurrentUser', JSON.stringify(superUserCurrentUser))
  },
  setChatSettings(data) {
    localStorage.setItem('chatSettings', JSON.stringify(data))
  },
  getUserData() {
    const userData = localStorage.getItem('userData')
    return !!userData && JSON.parse(userData)
  },
  getSuperUserCurrentUser() {
    const superUserCurrentUser = localStorage.getItem('superUserCurrentUser')
    return !!superUserCurrentUser && JSON.parse(superUserCurrentUser)
  },
  getChatSettings() {
    const chatSettings = localStorage.getItem('chatSettings')
    const defaultChatSettings = {width: 400, height: 600}
    return !!chatSettings ? JSON.parse(chatSettings) : defaultChatSettings
  },
  removeUserData() {
    return localStorage.removeItem('userData')
  },
  removeSuperUserCurrentUser() {
    return localStorage.removeItem('superUserCurrentUser')
  },
  removeChatSettings() {
    return localStorage.removeItem('chatSettings')
  },
  getChatCurrentStage(){
    const chatCurrentStage = localStorage.getItem('chatCurrentStage')
    const defaultCurrentStage = 'Pre chat'
    return !!chatCurrentStage ? JSON.parse(chatCurrentStage) : defaultCurrentStage
  },
  setChatCurrentStage(chatCurrentStage: 'Pre chat' | 'Prompt pick' | 'Started') {
    localStorage.setItem('chatCurrentStage', JSON.stringify(chatCurrentStage))
  },
  removeChatCurrentStage() {
    return localStorage.removeItem('chatCurrentStage')
  },
  setLastChatId(lastChatId: number) {
    localStorage.setItem('lastChatId', JSON.stringify(lastChatId))
  },
  getLastChatId() {
    const lastChatId = localStorage.getItem('lastChatId')
    return !!lastChatId && JSON.parse(lastChatId)
  },
  removeLastChatId() {
    return localStorage.removeItem('lastChatId')
  },
}
