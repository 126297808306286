/* eslint-disable */
import React from 'react'
import classes from "../../Landing.module.scss"
import InfoBadge from "../InfoBadge/InfoBadge"
import {motion, useInView} from "framer-motion"
import {useAppSelector} from "../../../../app/hooks"
import {selectCurrentAppWidth} from "../../../../store/userReducer"
import reportsMediumDeviceImg from './../../../../img/landingImg/Create a new report. Diagnosis.png'
import {useNavigate} from "react-router-dom"

const UnlockInsights = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, {once: true});
    const width = useAppSelector(selectCurrentAppWidth)
    const navigate = useNavigate()

    const textVariants = {
        hidden: {opacity: 0, y: -100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 0.7
            }
        }
    };

    const imageVariants = {
        hidden: {opacity: 0, y: 100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1,
                delay: 1
            }
        }
    }

    const buttonVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 1, delay: 1 }

        }
    }

    const onVisitSignInPage = () => {
        navigate('/sign-up')
    }
    return (
        <div className={`${classes.block} ${classes.block_greyBg} ${classes.unlock}`}
             ref={ref}
        >
            <div
                 className={classes.unlock__content}>
                <motion.div
                    variants={textVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    className={classes.unlock__content}
                >
                    <InfoBadge text={'FEATURES'}/>
                    <h2 className={`${classes.commonTitle} ${classes.unlock__contentTitle}`}>
                        Unlock Deep Insights with <span className={classes.commonTitle_blue}>CaseInsight.Net</span>
                    </h2>
                    <p
                        className={classes.commonText}
                    >
                        Harnessing the power of artificial intelligence, CaseInsight.Net sifts through vast amounts
                        of data in seconds, offering insightful suggestions and comparisons to aid in your analysis.
                        From identifying possible internal conflicts to drawing attention to possibly used defense
                        mechanisms, and even suggesting diagnoses in popular systems, our system is your
                        indispensable ally in understanding your patients.</p>
                </motion.div>
                <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    className={classes.unlock__earlyAccessButton}
                >
                    <button
                        className={`${classes.earlyAccessBtn} ${classes.unlock__earlyAccess}`}
                        onClick={onVisitSignInPage}
                    >
                        Get early access
                    </button>
                </motion.div>
            </div>
            <motion.div
                        variants={imageVariants}
                        initial="hidden"
                        animate={isInView ? 'visible' : 'hidden'}
                        className={classes.unlock__imgInner}
            >
                {
                    width > 1220
                        ?
                        <img src={reportsMediumDeviceImg} alt="reports" style={{
                            borderRadius: '26px'
                        }} className={classes.unlock__img}/>
                        :
                        <img src={reportsMediumDeviceImg} alt="reports" style={{
                            borderRadius: '26px'
                        }}
                             className={classes.unlock__img}
                        />
                }

            </motion.div>
        </div>
    );
};

export default UnlockInsights;