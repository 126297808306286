/* eslint-disable */
import React from 'react';
import s from "./InfoModal/InfoModal.module.scss";
import {useNavigate} from "react-router-dom";

interface SuccessModalPropsType {
    title: string
    withText: boolean
    height: number
    isResetPassword?: boolean
}

const SuccessModal = ({title, withText, height, isResetPassword}: SuccessModalPropsType) => {
    const navigate = useNavigate()

    return (
        <div className={s.inner}>
            <div className={s.inner__content} style={{
                height: `${height}px`
            }}>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF"/>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" strokeWidth="8"/>
                    <path
                        d="M38 27.08V28C37.9988 30.1564 37.3005 32.2547 36.0093 33.9818C34.7182 35.709 32.9033 36.9725 30.8354 37.5839C28.7674 38.1953 26.5573 38.1219 24.5345 37.3746C22.5117 36.6273 20.7847 35.2461 19.611 33.4371C18.4373 31.628 17.8798 29.4881 18.0217 27.3363C18.1636 25.1846 18.9972 23.1363 20.3983 21.4971C21.7994 19.8578 23.6928 18.7154 25.7962 18.2401C27.8996 17.7649 30.1003 17.9823 32.07 18.86M38 20L28 30.01L25 27.01"
                        stroke="#039855" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className={s.title}>{title}</p>
                {
                    withText
                    &&
                    isResetPassword
                        ?
                        <p className={s.text}>Your request for password reset has been successfully submitted. Our team
                            is currently
                            processing
                            it. You will receive confirmation and further information via email shortly.</p>
                        :
                        <p className={s.text}>Your application has been successfully submitted. Our team is currently
                            processing
                            it. You will receive confirmation and further information via email shortly.</p>
                }
                {
                    withText
                    &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                         onClick={() => navigate('/signIn')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                            <path
                                d="M13 6.75C13.4142 6.75 13.75 6.41421 13.75 6C13.75 5.58579 13.4142 5.25 13 5.25V6.75ZM0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
                                fill="#667085"/>
                        </svg>
                        <p
                            style={{
                                margin: '0 0 0 10px',
                                color: '#667085',
                                fontSize: '16px',
                                cursor: "pointer"
                            }}
                        >Back</p>
                    </div>
                }
            </div>
        </div>
    )
};

export default SuccessModal;