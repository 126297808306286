
import { Link, Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GridRenderCellParams, GridRowOrderChangeParams, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { Button, Input, Spin } from 'antd'
import { sortBy } from 'lodash'
import classes from './InstanceList.module.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import MuiTable from '../common/MuiTable/MuiTable'
import ConfirmationModal from '../common/ConfirmationModal/ConfirmationModal'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import { ReactComponent as EditIcon } from './../../img/icons/edit.svg'
import { ReactComponent as ReorderIcon } from './../../img/icons/reorder.svg'
import { ReactComponent as DeleteIcon } from './../../img/icons/delete.svg'
import { selectIsAdmin, selectIsSuperUser } from '../../store/currentUserReducer'
import TooltipHint from '../common/TooltipHint/TooltipHint'
import { DeleteInstanceThunk, GetInstanceListThunk, ReorderInstancesThunk, selectInstanceList, selectInstanceListManualParams, selectInstanceListRequestParams, selectInstanceListTotalCount, setInstanceListManualParams } from '../../store/instanceReducer'
import Tag from '../common/Tag/Tag'
import { InstanceType } from '../../types/instanceType'
import { getDisplayData } from '../../helpers/filtersHelper'
import { useTranslation } from 'react-i18next'

const InstanceList = () => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const apiRef = useGridApiRef()
  const instanceList = useAppSelector(selectInstanceList)
  const instanceListTotalCount = useAppSelector(selectInstanceListTotalCount)
  const instanceListRequestParams = useAppSelector(selectInstanceListRequestParams)
  const instanceListManualParams = useAppSelector(selectInstanceListManualParams)
  const isAdmin = useAppSelector(selectIsAdmin)
  const isSuperUser = useAppSelector(selectIsSuperUser)

  const [confirmationModalData, setConfirmationModalData] = useState<null | {type: 'delete', instance: InstanceType}>(null)
  const [reorderedList, setReorderedList] = useState<InstanceType[]>([])
  const [isReordering, setIsReordering] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [dataForDisplay, setDataForDisplay] = useState<{data: InstanceType[] | null, totalCount: number}>({data: null, totalCount: 0})
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (!!isAdmin || !!isSuperUser) {
      dispatch(GetInstanceListThunk(instanceListRequestParams))
    }
  }, [dispatch, instanceListRequestParams, isAdmin, isSuperUser])

  useEffect(() => {
    getDataForDisplay()
    // eslint-disable-next-line
  }, [instanceListManualParams, searchValue])

  const handleSortModelChange = (sortModel: GridSortModel) => {
    dispatch(setInstanceListManualParams({
      ...instanceListManualParams,
      page: 1,
      sort: sortModel
    }))
  }

  const handleSearch = () => {
    return instanceList?.filter(i => {
      return i?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    })
  }

  const getDataForDisplay = () => {
    let initialList = searchValue?.length ? handleSearch() : instanceList
    initialList = sortBy(initialList, i => i.order_num)?.map(i => ({
      ...i,
      topicCountString: String(i?.topic_count || 0),
      subtopicCountString: String(i?.subtopic_count || 0)
    }))
    const updatedData = getDisplayData<InstanceType>(initialList || [], instanceListManualParams)
    setDataForDisplay(updatedData)
  }

  const columns = [
    ...(isReordering ? [{
      field: 'order_num',
      headerName: t('instanceList.table.columnTitles.orderNumber'),
      flex: 0.5,
      minWidth: 50,
      filterable: false,
      sortable: false,
    }] : []
    ),
    {
      field: 'name',
      headerName: t('instanceList.table.columnTitles.title'),
      flex: 0.7,
      minWidth: 100,
      filterable: !isReordering,
      sortable: !isReordering,
      renderCell: (params: GridRenderCellParams<InstanceType, Date>) => (
        <div className={classes.instanceNameColumn}>
          {params.row.name}
        </div>
      )
    },
    {
      field: 'description',
      headerName: t('instanceList.table.columnTitles.description'),
      flex: 1,
      minWidth: 120,
      filterable: !isReordering,
      sortable: !isReordering,
      renderCell: (params: GridRenderCellParams<InstanceType, Date>) => (
        <div className={classes.instanceDescriptionColumn}>
          {params.row.description}
        </div>
      )
    },
    {
      field: 'color',
      headerName: t('instanceList.table.columnTitles.color'),
      flex: 0.8,
      minWidth: 100,
      sortable: false,
      filterable: false,
      cellClassName: 'tableSecondaryTextStyle',
      renderCell: (params: GridRenderCellParams<InstanceType, Date>) => (
        <Tag
          text={params.row.name}
          style={{backgroundColor: params.row.color, color: '#3B414B'}}
        />
      )
    },
    {
      field: 'topicCountString',
      headerName: t('instanceList.table.columnTitles.topics'),
      flex: 0.5,
      minWidth: 50,
      filterable: !isReordering,
      sortable: !isReordering,
      renderCell: (params: GridRenderCellParams<InstanceType, Date>) => (
        <Tag text={params.row.topic_count} />
      )
    },
    {
      field: 'subtopicCountString',
      headerName: t('instanceList.table.columnTitles.subtopics'),
      flex: 0.5,
      minWidth: 50,
      filterable: !isReordering,
      sortable: !isReordering,
      renderCell: (params: GridRenderCellParams<InstanceType, Date>) => (
        <Tag text={params.row.subtopic_count} />
      )
    },
    ...(isReordering ? [] : [{
      field: 'Actions',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 0.1,
      minWidth: 80,
      renderCell: (params: GridRenderCellParams<InstanceType, Date>) => (
        <div className={classes.tableActions}>
          {params?.row?.is_btn_delete &&
            <TooltipHint title={t('instanceList.table.actionHints.delete')}>
              <DeleteIcon onClick={() => setConfirmationModalData({type: 'delete', instance: params.row})}/>
            </TooltipHint>
          }
          {params?.row?.is_btn_edit &&
            <TooltipHint title={t('instanceList.table.actionHints.viewInfo')}>
              <Link to={`/instances/edit/${params.row.id}`} style={{marginBottom: '-5px'}}>
                <EditIcon />
              </Link>
            </TooltipHint>
          }
        </div>
      )
    }])
  ]

  const reorderItems = (originalArray: InstanceType[], movedObject: InstanceType, prevIndex: number, newIndex: number) => {
    const updatedInstanceList = originalArray.map((obj) => {
      if (obj.id === movedObject.id) {
        return { ...obj, order_num: newIndex + 1 }
      }
      if (prevIndex < newIndex) {
        if (obj.order_num > prevIndex + 1 && obj.order_num <= newIndex + 1) {
          return { ...obj, order_num: obj.order_num - 1 }
        }
      } else if (prevIndex > newIndex) {
        if (obj.order_num >= newIndex + 1 && obj.order_num < prevIndex + 1) {
          return { ...obj, order_num: obj.order_num + 1 }
        }
      }
      return obj
    })
    return sortBy(updatedInstanceList, i => i?.order_num)
  }

  const handleOrderChange = (data: GridRowOrderChangeParams) => {
    const instanceData = (data.row as InstanceType)
    const updatedInstanceList = reorderItems(reorderedList, instanceData, data.oldIndex, data.targetIndex)
    setReorderedList(updatedInstanceList)
  }

  const reorder = () => {
    if (isReordering) {
      setIsSaving(true)
      dispatch(ReorderInstancesThunk(reorderedList.map(i => ({order_num: i.order_num, instance_id: i.id}))))
        .then(() => setIsSaving(false))
    } else {
      dispatch(GetInstanceListThunk())
        .then(r => setReorderedList((r.payload as {instances: InstanceType[]})?.instances))
    }
    setIsReordering(!isReordering)
  }

  if (!isAdmin && !isSuperUser) {
    return <Navigate to='/'/>
  }

  return (
    <>
      <div className={classes.titleWrapper}>
        <h1 className='pageTitle'>
          {t('instanceList.title')}
        </h1>
        {instanceList?.[0]?.is_allow_edit &&
          <div className={classes.tools}>
            <Button
              onClick={reorder}
              className={classes.reorderBtn}
              loading={isSaving}
            >
              <ReorderIcon style={{marginRight: '8px'}}/> {isReordering ? t('instanceList.toolbar.saveReorderBtn') : t('instanceList.toolbar.reorderBtn')}
            </Button> 
            <Link to='/instances/add'>
              <Button type='primary'>
                + {t('instanceList.toolbar.addInstanceBtn')}
              </Button>
            </Link>
          </div>
        }
      </div>
      <h2 className='pageDescription'>
        {t('instanceList.description')}
      </h2>

      {instanceList === null ? (
        <Spin style={{width: '100%'}}/>
      ) : (
        <>
          <Input
            placeholder={t('actions.search', {ns: 'app'})}
            prefix={<SearchIcon />}
            style={{width: '400px', marginBottom: '24px'}}
            onChange={(e) => setSearchValue(e.target.value)}
          /> 

          <MuiTable
            columns={columns}
            rows={isReordering ? sortBy(reorderedList, i => i?.order_num) : dataForDisplay?.data || []}
            pageSizeOptions={isReordering ? [instanceListTotalCount] : undefined}
            apiRef={apiRef}
            sortingMode='server'
            onRowOrderChange={isReordering ? handleOrderChange : undefined}
            count={isReordering ? undefined : dataForDisplay.totalCount}
            page={isReordering ? undefined : instanceListManualParams.page - 1}
            onPageChange={isReordering
              ? undefined
              : (_, page) => {
                dispatch(setInstanceListManualParams({
                  ...instanceListManualParams,
                  page: page + 1,
                }))
              }
            }
            onSortModelChange={handleSortModelChange}
            sortModel={isReordering ? [] : instanceListManualParams?.sort?.length ? [{
              field: instanceListManualParams?.sort?.[0]?.field,
              sort: instanceListManualParams?.sort?.[0]?.sort?.toLowerCase()
            }] : []}
            filterModel={isReordering ? {items: []} : instanceListManualParams.filters}
            onFilterModelChange={isReordering ? undefined : (model) => {
              dispatch(setInstanceListManualParams({
                ...instanceListManualParams,
                page: 1,
                filters: model
              }))
            }}
          />
        </>
      )}
      <ConfirmationModal
        open={!!confirmationModalData?.instance?.id}
        title={t('instanceList.table.actionConfirmation.delete', {name: confirmationModalData?.instance?.name})}
        description={t('warnings.delete', {ns: 'app', itemName: 'instance'})}
        onClose={() => setConfirmationModalData(null)}
        onConfirm={() => {
          return dispatch(DeleteInstanceThunk(confirmationModalData?.instance?.id!))
            .then(() => setConfirmationModalData(null))
        }}
        type={'delete'}
        confirmText={t('actions.delete', {ns: 'app'})}
      />
    </>
  )
}

export default InstanceList
