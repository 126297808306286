import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { diagnosisAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { SignOutThunk } from './currentUserReducer'
import { RequestDataType } from '../types/requestTypes'

interface InitialStateType {
  currentDiagnosis: null | string
}

const initialState: InitialStateType = {
  currentDiagnosis: null,
}

export const diagnosisSlice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    setCurrentDiagnosis: (state, action: PayloadAction<string | null>) => {state.currentDiagnosis = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDiagnosisThunk.fulfilled, (state, action) => {
        state.currentDiagnosis = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.currentDiagnosis = null
      })
  }
})

export const {
  setCurrentDiagnosis,
} = diagnosisSlice.actions

export const selectCurrentDiagnosis = (state: RootState): string | null => state.diagnosis.currentDiagnosis

export const GetDiagnosisThunk = createAsyncThunk<string, number, AsyncThunkConfig>(
  'diagnosis/getDiagnosis',
  async (requestId, thunkAPI) => {
    try {
      const { status, data } = await diagnosisAPI.getDiagnosis(requestId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.message, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SaveDiagnosisToRequestThunk = createAsyncThunk<RequestDataType, {requestId: number, diagnosisText: string}, AsyncThunkConfig>(
  'diagnosis/saveDiagnosisToRequest',
  async ({requestId, diagnosisText}, thunkAPI) => {
    try {
      const { status, data } = await diagnosisAPI.saveDiagnosisToRequest(requestId, diagnosisText)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default diagnosisSlice.reducer
 