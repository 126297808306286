import { Button } from 'antd'
import s from './InfoModal.module.scss'
import { useTranslation } from 'react-i18next'

interface InfoModalPropsType {
  title: string
  text: string
  closeModal: () => void
}

const InfoModal = ({ title, text, closeModal }: InfoModalPropsType) => {
  const { t } = useTranslation(['app'])
  return (
    <div className={s.inner}>
      <div className={s.inner__content}>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" strokeWidth="8" />
          <path
            d="M27.9998 24V28M27.9998 32H28.0098M26.2898 18.86L17.8198 33C17.6451 33.3024 17.5527 33.6453 17.5518 33.9945C17.5508 34.3437 17.6413 34.6871 17.8142 34.9905C17.9871 35.2939 18.2365 35.5467 18.5375 35.7238C18.8385 35.9009 19.1806 35.9961 19.5298 36H36.4698C36.819 35.9961 37.1611 35.9009 37.4621 35.7238C37.7631 35.5467 38.0124 35.2939 38.1854 34.9905C38.3583 34.6871 38.4488 34.3437 38.4478 33.9945C38.4468 33.6453 38.3544 33.3024 38.1798 33L29.7098 18.86C29.5315 18.5661 29.2805 18.3231 28.981 18.1544C28.6814 17.9858 28.3435 17.8972 27.9998 17.8972C27.656 17.8972 27.3181 17.9858 27.0186 18.1544C26.7191 18.3231 26.468 18.5661 26.2898 18.86Z"
            stroke="#DC6803" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <p className={s.title}>{title}</p>
        <p className={s.text}>{text}</p>
        <Button
          type='primary'
          onClick={closeModal}
          className={s.inner__btn}
        >
          {t('actions.ok')}
        </Button>
      </div>
    </div>
  )
}

export default InfoModal
