import { NewInstanceTopicType, InstanceTopicType } from '../../../../types/instanceType'
import classes from './TopicCard.module.css'
import { ReactComponent as TopicIcon } from './../../../../img/icons/topic.svg'
import { ReactComponent as DeleteIcon } from './../../../../img/icons/delete.svg'
import { ReactComponent as EditIcon } from './../../../../img/icons/edit.svg'
import PopoverConfirm from '../../../common/PopoverConfirm/PopoverConfirm'
import { Divider } from 'antd'
import Tag from '../../../common/Tag/Tag'
import { useTranslation } from 'react-i18next'

const TopicCard: React.FC<TopicCardPropTypes> = ({
  topic,
  isNew,
  onDeleteTopic,
  onClick,
  allowEdit
}) => {
  const { t } = useTranslation(['app', 'pages'])
  return (
    <div className={classes.wrapper} >
      <div
        className={classes.mainInfo}
        onClick={() => onClick(topic)}
      >
        <div className={classes.nameWrapper}>
          <TopicIcon /> 
          <div className={classes.name}>
            {topic.name}
            {!!topic.subtopic_count && topic.subtopic_count > 0 &&
              <Tag
                text={t(topic.subtopic_count === 1 ? 'count.subtopic' : 'count.subtopic_plural', {count: topic.subtopic_count})}
                style={{marginTop: '4px'}}
              />
            }
          </div>
        </div>
        <div className={classes.tools}>
          {allowEdit &&
            <div onClick={e => e.stopPropagation()} className={classes.deletePopover}>
              <PopoverConfirm
                title={t('instanceDetails.topic.deleteConfirm', {ns: 'pages'})}
                onConfirm={() => onDeleteTopic(isNew ? 0 : (topic as InstanceTopicType).id, isNew ? topic?.temp_id! : 0)}
              >
                <DeleteIcon />
              </PopoverConfirm>
            </div>
          }
          <EditIcon />
        </div>
      </div>

      <Divider style={{margin: '16px 0px'}} />

      <div className={classes.description}>
        {topic.description}
      </div>
      
    </div>
  )
}

interface TopicCardPropTypes {
  topic: NewInstanceTopicType
  isNew?: boolean
  onDeleteTopic: (topicId: number, topicTempId: number) => void
  onClick: (topic: InstanceTopicType | NewInstanceTopicType) => void
  allowEdit?: boolean
}

export default TopicCard
