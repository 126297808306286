import dayjs from 'dayjs'
import { Divider } from 'antd'
import classes from './Billing.module.scss'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedUserData, selectUserBillingSettings } from '../../store/userReducer'
import BillingInfoCard from './BillingInfoCard/BillingInfoCard'
import Tag from '../common/Tag/Tag'
import { ReactComponent as NoDataIcon } from './../../img/icons/no_data.svg'
import MainBalance from './MainBalance/MainBalance'
import TransactionHistory from './TransactionHistory/TransactionHistory'
import { useTranslation } from 'react-i18next'
import { selectBillingSettings, selectSuperUserCurrentUser } from '../../store/currentUserReducer'

const Billing:React.FC<{isCurrentUserBalance: boolean, noBalance?: boolean}> = ({isCurrentUserBalance, noBalance}) => {
  const { t } = useTranslation(['pages'])
  const selectedUserData = useAppSelector(selectSelectedUserData)

  if (noBalance) {
    return (
      <div className={classes.noData}>
        <NoDataIcon/>
        <p className={classes.noData__title}>{t('userDetails.billing.noDataMessage.title')}</p>
        <p className={classes.noData__text}>{t('userDetails.billing.noDataMessage.description')}</p>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <Plan isCurrentUserBalance={isCurrentUserBalance}/>

      <MainBalance isCurrentUserBalance={isCurrentUserBalance}/>

      {!isCurrentUserBalance && 
        <>
          <TransactionHistory />
          <h2 style={{marginTop: '25px'}}>
            {t('billing.tokens.title')}
          </h2>
          <h3 style={{fontSize: '14px'}}>
            {t('billing.tokens.description')}
          </h3>
          <Divider/>
          <div className={classes.tokensWrapper}>
            <BillingInfoCard
              title={t('billing.tokens.totalLabel')}
              cardValue={selectedUserData?.token_number?.total_tokens || 0}
            />
            <BillingInfoCard
              title={t('billing.tokens.completionLabel')}
              cardValue={selectedUserData?.token_number?.completion_tokens || 0}
            />
            <BillingInfoCard
              title={t('billing.tokens.promptLabel')}
              cardValue={selectedUserData?.token_number?.prompt_tokens || 0}
            />
          </div>
        </>
      }
    </div>
  )
}

const Plan: React.FC<{isCurrentUserBalance: boolean}> = ({isCurrentUserBalance}) => {
  const { t } = useTranslation(['pages'])
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)
  const userBillingSettings = useAppSelector(selectUserBillingSettings)
  const currentUserBillingSettings = useAppSelector(selectBillingSettings)
  const billingSettings = (isCurrentUserBalance && superUserCurrentUser?.user_id) || !isCurrentUserBalance
    ? userBillingSettings 
    : currentUserBillingSettings

  const getFormattedPlanName = (plan: string) => {
    const words = plan.split('_')
    return words.map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()).join(' ')
  }

  // const getUsageProgress = () => {
  //   return (billingSettings?.tariff_plan_info.current_patients!!  / billingSettings?.tariff_plan_info.max_patients!!) * 100
  // }

  return (
    <div className={classes.plan}>
      <div className={classes.planName}>
        {getFormattedPlanName(billingSettings?.tariff_plan_name || '')}
      </div>
      <Tag
        text={t('billing.plan.activeTill', {date: dayjs(billingSettings?.tariff_plan_to_date).format('DD.MM.YYYY')})}
        style={{width: 'fit-content'}}
      />
    </div>
  )
}

export default Billing
