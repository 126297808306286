import { Radio } from 'antd'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../../app/hooks'
import { selectReportSettings } from '../../../../../../store/currentUserReducer'
import { selectSelectedPersonData } from '../../../../../../store/personReducer'
import { NewRequestDataType, ReportCDType, ReportTemplateType } from '../../../../../../types/requestTypes'
import commonClasses from './../../../GenerateReport.module.css'

const InstanceSettingOptions: React.FC<InstanceSettingOptionsPropTypes> = ({
  requestData,
  setRequestData,
  settingTemplate,
  setSettingTemplate,
  isGenerating,
  activeReportType
}) => {
  const userReportSettings = useAppSelector(selectReportSettings)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)

  const [prevSetting, setPrevSetting] = useState<{template: 'U' | 'P' | 'R', reportType: ReportCDType}>({template: 'P', reportType: 'main'})

  const onTemplateChange = (template: ReportTemplateType) => {
    setSettingTemplate(template)
    const updatedRequestData = {
      ...requestData!,
      report_param_mode: template,
      ...(template === 'U' ? {
        instance_list: userReportSettings?.instance_list.map(instance => instance.id),
        p_lang_cd: userReportSettings?.lang_cd,
        report_style: userReportSettings?.report_style,
        report_format: userReportSettings?.report_format,
      } : {}),
      ...(template === 'P' ? {
        instance_list: selectedPersonData?.instance_list?.map(i => i.id) || [],
        p_lang_cd: selectedPersonData?.lang_cd,
        report_style: selectedPersonData?.report_style,
        report_format: selectedPersonData?.report_format,
      } : {}),
    }
    setRequestData(updatedRequestData)
  }

  useEffect(() => {
    const isMainReportOptions = activeReportType === 'main' || activeReportType === 'literature' || activeReportType === 'keywords'
    const isPrevMainReportOptions = prevSetting.reportType === 'main' || prevSetting.reportType === 'literature' || prevSetting.reportType === 'keywords'
    if (isMainReportOptions) {
      if (isPrevMainReportOptions) {
        setPrevSetting({template: settingTemplate, reportType: activeReportType})
        onTemplateChange(settingTemplate)
      } else {
        onTemplateChange(prevSetting.template)
        setPrevSetting({template: settingTemplate, reportType: activeReportType})
      }
    } else {
      if (settingTemplate !== 'R') {
        setPrevSetting({template: settingTemplate, reportType: activeReportType})
      } else if (isPrevMainReportOptions) {
        setPrevSetting({...prevSetting, reportType: activeReportType})
      }
      onTemplateChange('R')
    }
    // eslint-disable-next-line
  }, [activeReportType, settingTemplate])

  return (
    <Radio.Group
      onChange={(e) => onTemplateChange(e.target.value as 'U' | 'P' | 'R')}
      value={settingTemplate}
      rootClassName={commonClasses.radioGroup}
      style={{display: 'flex', marginBottom: '15px'}}
      disabled={!!isGenerating?.length || activeReportType === 'conflicts' || activeReportType === 'diagnoses' || activeReportType === 'kernberg'}
    >
      <Radio value={'P'} className={commonClasses.radioGroupOption}>
        <div className={commonClasses.name}>Patient Settings</div>
      </Radio>
      {/* <Radio value={'U'} className={commonClasses.radioGroupOption}>
        <div className={commonClasses.name}>Personal settings</div>
      </Radio> */}
      <Radio value={'R'} className={commonClasses.radioGroupOption}>
        <div className={commonClasses.name}>Select other settings</div>
      </Radio>
    </Radio.Group>
  )
}

interface InstanceSettingOptionsPropTypes {
  requestData: NewRequestDataType | null
  setRequestData: (val: NewRequestDataType | null) => void
  settingTemplate: ReportTemplateType
  setSettingTemplate: (template: ReportTemplateType) => void
  isGenerating: ReportCDType[]
  activeReportType: ReportCDType
}

export default InstanceSettingOptions
