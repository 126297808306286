import { Button, Input, Modal, Radio, Spin } from 'antd'
import classes from './SubtopicModal.module.css'
import { InstanceTopicType } from '../../../../../types/instanceType'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { useEffect, useState } from 'react'
import { GetInstanceSubtopicListThunk, GetSubtopicCommentHintsThunk, selectInstanceSubtopicList, selectSubtopicCommentHints, setInstanceSubtopicList } from '../../../../../store/instanceReducer'
import Tag from '../../../../common/Tag/Tag'
import { selectUserData } from '../../../../../store/currentUserReducer'

const SubtopicModal: React.FC<SubtopicModalPropType> = ({isOpen, onClose, topic, addDataToSession, setHasDataChanged}) => {
  const dispatch = useAppDispatch()
  const subtopicList = useAppSelector(selectInstanceSubtopicList)
  const commentHints = useAppSelector(selectSubtopicCommentHints)
  const userData = useAppSelector(selectUserData)

  const [selectedSubtopics, setSelectedSubtopics] = useState<{subtopic_id: number, subtopic_name: string, note: string}[] | null>(null)
  const [selectedSubtopicForHint, setSelectedSubtopicForHint] = useState<null | number>(null)
  const [isLoadingHint, setIsLoadingHint] = useState(false)

  useEffect(() => {
    if (subtopicList === null && topic.id) {
      dispatch(GetInstanceSubtopicListThunk(topic.id))
    }
  }, [dispatch, topic, subtopicList])

  useEffect(() => {
    if (topic?.subtopics && selectedSubtopics === null) {
      setSelectedSubtopics(topic?.subtopics)
    }
  }, [dispatch, topic, selectedSubtopics])

  const selectSubtopic = (subtopic_id: number, subtopic_name: string) => {
    setHasDataChanged(true)
    setSelectedSubtopics(selectedSubtopics?.some(s => s.subtopic_id === subtopic_id)
      ? selectedSubtopics?.filter(s => s.subtopic_id !== subtopic_id)
      : [...selectedSubtopics || [], {subtopic_id, subtopic_name, note: ''}]
    )
    setSelectedSubtopicForHint(null)
  }

  const addSubtopicNote = (id: number, note: string) => {
    setHasDataChanged(true)
    setSelectedSubtopics((selectedSubtopics || []).map(s => s.subtopic_id === id
      ? {...s, note}
      : s
    ))
  }

  const addSubtopicHintToNote = (id: number, hint: string) => {
    const subtopicNote = (selectedSubtopics || []).find(s => s.subtopic_id === id)?.note
    const updatedNote = !!subtopicNote?.length
      ? subtopicNote + (subtopicNote[subtopicNote?.length - 1] === ' ' ? '' : ' ') + hint
      : hint
    addSubtopicNote(id, updatedNote)
  }

  const getCommentHints = (subtopicId: number) => {
    setIsLoadingHint(true)
    setSelectedSubtopicForHint(subtopicId)
    dispatch(GetSubtopicCommentHintsThunk({subtopicId: subtopicId, userId: userData?.user_id!}))
      .then(() => setIsLoadingHint(false))
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      destroyOnClose
      closable={false}
      width={800}
    >
      <div className={classes.topicName}>
        {topic.name}
      </div>
      <div className={classes.topicDescription}>
        {topic.description}
      </div>
      {!!subtopicList?.length ? (
        <>
          {subtopicList?.map((subtopic, index) => (
            <div key={subtopic.id}>
              <div
                className={`${classes.subtopic} ${selectedSubtopics?.some(s => s.subtopic_id === subtopic.id) ? classes.active : ''}`}
                onClick={() => selectSubtopic(subtopic.id, subtopic.name)}
              >
                <div>{index + 1}.</div>
                <div>
                  <div className={classes.subtopicName}>
                    {subtopic.name}
                  </div>
                  <div className={classes.subtopicDescription}>
                    {subtopic.description}
                  </div>
                </div>
                <Radio
                  checked={selectedSubtopics?.some(s => s.subtopic_id === subtopic.id)}
                  className={classes.radio}
                />
              </div>
              {selectedSubtopics?.some(s => s.subtopic_id === subtopic.id) &&
                <div className={classes.comment}>
                  <div className={classes.subtopicName}>
                    Comment
                    {selectedSubtopicForHint !== subtopic.id &&
                      <span onClick={() => getCommentHints(subtopic.id)}>
                        Show hint
                      </span>
                    }
                  </div> 
                  <Input.TextArea
                    placeholder='Enter comment'
                    onChange={e => addSubtopicNote(subtopic.id, e.target.value)}
                    value={selectedSubtopics?.find(s => s.subtopic_id === subtopic.id)?.note}
                    style={{marginBottom: '12px'}}
                  />
                  {selectedSubtopicForHint === subtopic.id &&
                    <div className={classes.commentHints}>
                      {isLoadingHint && <Spin />}
                      {!isLoadingHint && !!commentHints?.length &&
                        <div className={classes.hintList}>
                          {commentHints.map(h => (
                            <Tag
                              key={h.id}
                              text={h?.hint}
                              style={{
                                color: '#1890FF',
                                backgroundColor: '#E6F7FF',
                                width: 'fit-content',
                                cursor: 'pointer',
                                marginRight: '7px'
                              }}
                              onClick={() => {
                                addSubtopicHintToNote(subtopic.id, h?.hint)
                                setSelectedSubtopicForHint(null)
                              }}
                            />
                          ))} 
                        </div>
                      }
                      {!isLoadingHint && !commentHints?.length &&
                        'No hints for this subtopic'
                      }
                    </div>
                  }
                </div>
              }
            </div>
          ))}
          <div className={`${classes.footer} actionButtons`}>
            <Button
              onClick={() => {
                onClose()
                dispatch(setInstanceSubtopicList(null))
              }}
            >
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => {
                addDataToSession(selectedSubtopics || [])
                setSelectedSubtopicForHint(null)
                dispatch(setInstanceSubtopicList(null))
              }}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : (
        subtopicList === null ? (
          <Spin style={{width: '100%'}}/>
        ) : (
          <div className={classes.noSubtopics}>
            No subtopics found
            <Button
              onClick={() => {
                onClose()
                dispatch(setInstanceSubtopicList(null))
              }}
            >
              Cancel
            </Button>
          </div>
        )
      )}
    </Modal>
  )
}

interface TopicItemType extends InstanceTopicType {
  subtopics: {
    subtopic_id: number
    subtopic_name: string
    note: string
  }[]
}
interface SubtopicModalPropType {
  isOpen: boolean
  onClose: () => void
  topic: TopicItemType
  addDataToSession: (subtopics: {subtopic_id: number, subtopic_name: string, note: string}[]) => void
  setHasDataChanged: (hasChanged: boolean) => void
}

export default SubtopicModal
