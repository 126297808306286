import { configureStore } from '@reduxjs/toolkit'
import appStatus from './appStatusReducer'
import currentUser from './currentUserReducer'
import user from './userReducer'
import person from './personReducer'
import instance from './instanceReducer'
import reference from './referenceReducer'
import request from './requestReducer'
import session from './sessionReducer'
import diagnosis from './diagnosisReducer'
import dreamInterpreter from './dreamInterpreterReducer'

export const store = configureStore({
  reducer: {
    appStatus,
    currentUser,
    user,
    person,
    instance,
    reference,
    request,
    session,
    diagnosis,
    dreamInterpreter,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  devTools: true,
})
