import { Spin, Tabs } from 'antd'
import Preferences from './Preferences/Preferences'
import Billing from '../Billing/Billing'
import SuperUserWarningMessage from '../common/SuperUserWarningMessage/SuperUserWarningMessage'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetUserReportSettingsThunk, selectIsSuperUser, selectIsUser, selectSuperUserCurrentUser, selectUserData } from '../../store/currentUserReducer'
import { useEffect, useState } from 'react'
import { GetUserBillingSettingsThunk } from '../../store/userReducer'
import { GetFormatReferencesThunk, GetLanguageReferencesThunk, GetStyleReferencesThunk, GetTherapyMethodsThunk } from '../../store/referenceReducer'
import { GetInstanceListThunk } from '../../store/instanceReducer'
import Profile from './Profile/Profile'

const Parameters = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const isUser = useAppSelector(selectIsUser)
  const isAdmin = useAppSelector(selectIsUser)
  const isSuperUser = useAppSelector(selectIsSuperUser)
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    Promise.all([
      dispatch(GetStyleReferencesThunk()),
      dispatch(GetLanguageReferencesThunk()),
      dispatch(GetFormatReferencesThunk()),
      dispatch(GetTherapyMethodsThunk()),
      dispatch(GetInstanceListThunk()),
      ...(isUser || isAdmin || superUserCurrentUser?.user_id
        ? [dispatch(GetUserReportSettingsThunk(superUserCurrentUser?.user_id || userData.user_id))]
        : []
      ),
      ...(!!superUserCurrentUser?.user_id ?
        [dispatch(GetUserBillingSettingsThunk({
          userId: superUserCurrentUser?.user_id,
          isCurrentUser: false
        }))]
        : []
      ),
    ])
      .then(() => setIsLoading(false))
  }, [dispatch, userData, superUserCurrentUser, isUser, isAdmin])

  const tabs = [
    {
      key: 'profile',
      label: 'Profile',
      children: <Profile />,
    },
    {
      key: 'preferences',
      label: 'Preferences',
      children: <Preferences />,
    },
    {
      key: 'billing',
      label: 'Billing',
      children: <Billing isCurrentUserBalance/>,
    },
  ]

  if (isLoading) {
    return <Spin style={{width: '100%'}} />
  }

  return (
    <>
      <h1 className='pageTitle'>
        Parameters
      </h1>
      {isSuperUser && !isUser && !superUserCurrentUser?.user_id &&
        <SuperUserWarningMessage />
      }
      {(isUser || superUserCurrentUser?.user_id) &&
        <Tabs defaultActiveKey='profile' items={tabs} />
      }
    </>
  )
}

export default Parameters

