import { GridRenderCellParams, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import MuiTable from '../../common/MuiTable/MuiTable'
import classes from './TransactionHistory.module.scss'
import RadioGroup from '../../common/RadioGroup/RadioGroup'
import { TransactionChangeType } from '../../../types/billingTypes'
import { ReactComponent as SearchIcon } from './../../../img/icons/search.svg'
import Tag from '../../common/Tag/Tag'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetUserTransactionsThunk, selectSelectedUserData, selectUserTransactions, selectUserTransactionsFilterCount, selectUserTransactionsRequestParams, setUserTransactionsRequestParams } from '../../../store/userReducer'
import { useTranslation } from 'react-i18next'

const TransactionHistory = () => {
  const { t } = useTranslation(['pages'])
  dayjs.extend(utc)
  const dispatch = useAppDispatch()
  const apiRef = useGridApiRef()
  const requestParams = useAppSelector(selectUserTransactionsRequestParams)
  const selectedUserData = useAppSelector(selectSelectedUserData)
  const transactions = useAppSelector(selectUserTransactions) 
  const transactionsFilterCount = useAppSelector(selectUserTransactionsFilterCount) 

  const [isDataLoading, setIsDataLoading] = useState(true)

  useEffect(() => {
    setIsDataLoading(true)
    dispatch(GetUserTransactionsThunk({
      ...requestParams,
      transaction_request: {
        ...(requestParams.transaction_request || {}),
        user_id: requestParams?.transaction_request?.user_id ? requestParams.transaction_request?.user_id : selectedUserData?.user_id!
      }
    })).then(() => setIsDataLoading(false))
  }, [dispatch, requestParams, selectedUserData])

  const columns = [
    {
      field: 'title',
      headerName: t('billing.transactions.table.columnTitles.title'),
      flex: 1.5,
      minWidth: 120,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div className={classes.transactionTitle}>
          {params.row.title}
          <div className={classes.transactionDescription}>
            {params.row?.doc_type_cd.replaceAll('_', ' ')}
          </div>
        </div>
      )
    },
    {
      field: 'change_sum',
      headerName: t('billing.transactions.table.columnTitles.amount'),
      flex: 1.5,
      minWidth: 120,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <Tag
          text={params?.row?.change_type === 'ChangeSum' ? params.row?.change_sum : params.row?.change_pat}
          style={params.row.transaction_type === 'Expenses'
            ? {color: '#B42318', backgroundColor: '#FEF3F2'}
            : {}
          }
        />
      )
    },    {
      field: 'change_date',
      headerName: t('billing.transactions.table.columnTitles.date'),
      flex: 1.5,
      minWidth: 120,
      filterable: false,
      cellClassName: `tableSecondaryTextStyle`,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div>{dayjs(params.row.change_date).format('DD MMM YYYY HH:mm:ss')}</div>
      )
    },
  ]

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sort = sortModel?.length ? sortModel.map(m => ({field: m.field, direction: m.sort?.toUpperCase() as 'ASC' | 'DESC'})) : []
    dispatch(setUserTransactionsRequestParams({
      ...requestParams,
      pagination_request: {...requestParams?.pagination_request!, page: 1, sort},
    }))
  // eslint-disable-next-line
  }, [requestParams])
  
  return (
    <div className={classes.wrapper}>
      <TableCustomHeader />
      <MuiTable
        columns={columns}
        rows={transactions || []}
        loading={isDataLoading}
        getRowId={r => r.doc_id}
        page={requestParams?.pagination_request?.page! - 1}
        count={transactionsFilterCount}
        onPageChange={(_, page) => {
          dispatch(setUserTransactionsRequestParams({
            ...requestParams,
            pagination_request: {...requestParams.pagination_request, page: page + 1},
          }))
        }}
        apiRef={apiRef}
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
        sortModel={requestParams?.pagination_request?.sort?.length ? [{
          field: requestParams?.pagination_request?.sort?.[0]?.field,
          sort: requestParams?.pagination_request?.sort?.[0]?.direction?.toLowerCase()
        }] : []}
        getRowHeight={() => 70}
      />
    </div>
  )
}

const TableCustomHeader = () => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const requestParams = useAppSelector(selectUserTransactionsRequestParams)

  const [searchValue, setSearchValue] = useState('')

  const transactionTypeOptions: {label: string, value: TransactionChangeType}[] = [
    {label: t('billing.transactions.creditTransaction'), value: 'ChangeSum'},
    {label: t('billing.transactions.patientTransaction'), value: 'ChangePat'},
  ]

  // eslint-disable-next-line
  const onSearch = useCallback(
    debounce((title: string) => {
      dispatch(setUserTransactionsRequestParams({
        transaction_request: {...requestParams?.transaction_request || {}, title},
        pagination_request: {...requestParams.pagination_request, page: 1}
      }))
    }, 350),
    []
  )

  return (
    <div className={classes.customHeader}>
      <h2>
        {t('billing.transactions.title')}
      </h2>
      <h3>
        {t('billing.transactions.description')}
      </h3>
      <div className={classes.customHeader__toolbar}>
        <RadioGroup
          options={transactionTypeOptions}
          onChange={(value) => {
            dispatch(setUserTransactionsRequestParams({
              ...requestParams,
              pagination_request: {...requestParams.pagination_request, page: 1},
              transaction_request: {...requestParams.transaction_request || {}, change_type: value as TransactionChangeType}
            }))
          }}
          value={(requestParams?.transaction_request?.change_type)}
          style={{width: 'fit-content', borderRight: '0px'}}
        />
        <Input
          placeholder={t('actions.search', {ns: 'app'})}
          prefix={<SearchIcon />}
          style={{maxWidth: '400px', marginBottom: '20px'}}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value)
            onSearch(e.target.value)
          }}
        /> 
      </div>
    </div>
  )
}

export default TransactionHistory
