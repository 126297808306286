/* eslint-disable */
import React, {useEffect, useState} from 'react'
import logo from "../../img/logo.png"
import classes from './Landing.module.scss'
import {motion, useInView} from 'framer-motion'
import LandingHero from "./LandingComponents/LandingHero/LandingHero";
import TransformPractise from "./LandingComponents/TransformPractise/TransformPractise";
import BeyondExpectations from "./LandingComponents/BeyondExpectations/BeyondExpectations";
import UnlockInsights from "./LandingComponents/UnlockInsights/UnlockInsights";
import JoinRevolution from "./LandingComponents/JoinRevolution/JoinRevolution";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectCurrentAppWidth, setCurrentAppWidth} from "../../store/userReducer";
import {Helmet} from "react-helmet";

const Landing = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, {once: true});
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const width = useAppSelector(selectCurrentAppWidth)


    useEffect(() => {
        dispatch(setCurrentAppWidth(window.innerWidth))
    }, [])

    useEffect(() => {
        // Function to update the width state
        const handleResize = () => {
            dispatch(setCurrentAppWidth(window.innerWidth))

        };

        // Adding the event listener to the window's resize event
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const fadeInVariants = {
        hidden: {opacity: 0},
        visible: {
            opacity: 1,
            transition: {duration: 1}
        }
    };

    const onVisitLoginPage = () => {
        navigate('/sign-in')
    }

    // State to manage if the menu is open or closed
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle menu open/close
    const toggleMenu = () => setIsOpen(!isOpen);

    const onVisitSignInPage = () => {
        navigate('/sign-up')
    }

    return (
        <div ref={ref}>
            <Helmet>
                <title>Revolutionize Mental Health with AI</title>
                <meta name="description"
                      content="Experience a paradigm shift in mental health care with our revolutionary tool.
                      Harness the latest in AI technology to streamline your workflow, saving time and enhancing accuracy. Join us and revolutionize the way you approach case formulation today!"/>
                <meta name="keywords" content="support system, dream interpretation, mental health, comprehensive care, insights, unconscious, alleviating the hassle of insurance
                    paperwork, interactive, dream interpreter, unconscious realm, valuable insights, new insights, mental health, AI, paradigm shift, healthcare, workflow, save time, enhance accuracy, revolutionize the way you approach case
                            formulation, case formulation, healthcare, practice transformation, boost
                    efficiency in case
                    formulation, case analysis, insights, patient understanding, suggesting diagnoses, insightful suggestions, aid in your analysis"/>
                <meta property="og:title"
                      content="Revolutionize Mental Health with AI"/>
                <meta property="og:description"
                      content="Experience a paradigm shift in mental health care with our revolutionary tool.
                      Harness the latest in AI technology to streamline your workflow, saving time and enhancing accuracy. Join us and revolutionize the way you approach case formulation today!"/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                ref={ref}
            >
                <header className={classes.header}>
                    <img src={logo} alt='logo' className={classes.logo}/>
                    {
                        width > 820
                            ?
                            <div className={classes.header__buttons}>
                                <button className={classes.loginBtn} onClick={onVisitLoginPage}>Sign in</button>
                                <button className={classes.earlyAccessBtn} onClick={onVisitSignInPage}>Get early
                                    access
                                </button>
                            </div>
                            :
                            <div onClick={toggleMenu} style={{
                                marginRight: '15px',
                                cursor: 'pointer'
                            }}>
                                {
                                    isOpen
                                        ?
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        :
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g id="menu">
                                                <path id="Icon" d="M3 12H21M3 6H21M3 18H21" stroke="#344054"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                        </svg>
                                }
                            </div>
                    }
                    {
                        isOpen
                        &&
                        <div className={classes.mobile__menu}>
                            <div className={classes.mobile__buttons}>
                                <button className={`${classes.loginBtn} ${classes.loginBtn_blue}`}
                                        onClick={onVisitLoginPage}>Sign in
                                </button>
                                <button className={classes.earlyAccessBtn} onClick={onVisitSignInPage}>Get early
                                    access
                                </button>
                            </div>
                        </div>
                    }
                </header>
            </motion.div>
            <main>
                <LandingHero/>
                <TransformPractise/>
                <UnlockInsights/>
                <BeyondExpectations/>
                <JoinRevolution/>
            </main>
        </div>
    );
};

export default Landing