import mainFormClasses from './../InstanceForm.module.css'
import GoBackLink from '../../../common/GoBackLink/GoBackLink'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { NewInstanceSubtopicType, NewInstanceTopicType } from '../../../../types/instanceType'
import TitleDescriptionFields from '../TitleDescriptionFields'
import { generateTempId } from '../../../../helpers/funcHelper'
import React from 'react'
import { selectBackLinkModalOpened, setBackLinkModalOpened } from '../../../../store/instanceReducer'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import { sortBy } from 'lodash'


const TopicForm: React.FC<TopicFormPropTypes> = ({
  topic,
  setTopicData,
  goToInstanceForm,
  instanceName,
  allowEdit,
  onSubmit,
  setHasTopicChanged,
  hasTopicChanged,
  validationWarning
}) => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const isBackLinkConfirmModalShown = useAppSelector(selectBackLinkModalOpened)

  const addNewSubtopic = () => {
    setHasTopicChanged(true)
    const newSubtopicData = {
      name: '',
      description: '',
      ...(!!topic?.id ? { topic_id: topic.id } : { temp_topic_id: topic.temp_id }),
      short_description: '',
      order_num: topic?.subtopic_count! + 1,
      temp_id: generateTempId(topic.subtopics),
    }
    setTopicData({
      ...topic,
      subtopics: [...topic.subtopics, newSubtopicData],
      subtopicsAdded: [...topic?.subtopicsAdded || [], newSubtopicData],
      subtopic_count: topic?.subtopic_count! + 1
    })
  }


  const onCancelChangesSave = () => {
    setHasTopicChanged(false)
    dispatch(setBackLinkModalOpened(false))
    goToInstanceForm()

  }

  const onSubtopicChange = (field: 'name' | 'description', val: string, subtopic: NewInstanceSubtopicType) => {
    setHasTopicChanged(true)
    const isSubtopicAdded = topic?.subtopicsAdded?.find(s => s?.temp_id === subtopic?.temp_id)
    setTopicData({
      ...topic,
      subtopics: topic.subtopics.map(s => (s?.id && s.id === subtopic.id) || (s?.temp_id && s?.temp_id === subtopic?.temp_id) ? {
        ...s,
        [field]: val
      } : s),
      ...(subtopic?.temp_id
        ? isSubtopicAdded
          ? {
            subtopicsAdded: topic?.subtopicsAdded?.map(sub => sub.temp_id === subtopic.temp_id ? {
              ...sub,
              [field]: val
            } : sub)
          }
          : { subtopicsAdded: [...topic?.subtopicsAdded || [], subtopic] }
        : {}
      ),
      ...(!subtopic?.temp_id
        ? {
          subtopicsEdited: topic?.subtopicsEdited?.some(s => s.id === subtopic?.id)
            ? topic?.subtopicsEdited.map(sub => sub.temp_id === subtopic.temp_id ? {
              ...sub,
              [field]: val
            } : sub)
            : [...topic?.subtopicsEdited || [], {
              ...topic.subtopics?.find(s => s.id === subtopic?.id)!,
              [field]: val
            }]
        }
        : {}
      )
    })
  }

  const deleteSubtopic = (id: number, tempId: number, subtopic: NewInstanceSubtopicType) => {
    setHasTopicChanged(true)
    setTopicData({
      ...topic,
      subtopics: topic.subtopics.filter(s => s?.id ? s.id !== subtopic.id : s?.temp_id !== subtopic.temp_id),
      subtopic_count: topic?.subtopic_count! - 1,
      ...(id === 0 && !!tempId
        ? { subtopicsDeletedWithTempId: [...topic?.subtopicsDeletedWithTempId || [], tempId] }
        : { subtopicsDeleted: [...topic?.subtopicsDeleted || [], id] }
      )
    })
  }

  return (
    <>
      <GoBackLink
        onClick={goToInstanceForm}
        text={t('instanceDetails.topic.form.backBtn')}
        showConfirmModal={hasTopicChanged ? () => dispatch(setBackLinkModalOpened(true)) : undefined}
      />
      <div className={mainFormClasses.pageTitle}>
        <h1 className='pageTitle'>
          {topic?.isEditing ? `${topic?.name} ${!!instanceName?.length ? '(' + instanceName + ')' : ''}` : t('instanceDetails.topic.form.title', {instanceName: instanceName})}
        </h1>
      </div>

      <div className={mainFormClasses.formWrapper}>
        <h2>
          {t('instanceDetails.topic.subtitle')}
        </h2>
        <h3>
          {topic?.isEditing ? t('instanceDetails.topic.editDescription') : t('instanceDetails.topic.addDescription')}
        </h3>
        <Divider style={{ margin: '20px 0px' }} />

        <TitleDescriptionFields
          title={topic.name}
          onTitleChange={(val: string) => {
            setHasTopicChanged(true)
            setTopicData({ ...topic, name: val })
          }}
          titleLabel={t('topic', {ns: 'app'})}
          description={topic.description}
          onDescriptionChange={(val: string) => {
            setTopicData({ ...topic, description: val })
            setHasTopicChanged(true)
          }}
        />

        <h2>
          {t('instanceDetails.topic.form.subtopicTitle')}
        </h2>
        <h3>
          {topic?.isEditing ? t('instanceDetails.topic.form.subtopicEditDescription') : t('instanceDetails.topic.form.subtopicAddDescription')}
        </h3>
        <Divider style={{ margin: '20px 0px' }} />

        {sortBy(topic.subtopics, (subtopic) => subtopic.order_num)?.map(subtopic => (
          <React.Fragment key={subtopic?.id || subtopic?.temp_id}>
            <TitleDescriptionFields
              title={subtopic.name}
              titleLabel={t('subtopic', {ns: 'app'})}
              onTitleChange={(val: string) => onSubtopicChange('name', val, subtopic)}
              description={subtopic.description}
              onDescriptionChange={(val: string) => onSubtopicChange('description', val, subtopic)}
              onDelete={allowEdit
                ? () => deleteSubtopic(subtopic?.id || 0, subtopic?.temp_id || 0, subtopic)
                : undefined
              }
            />
            <Divider style={{ margin: '0px 0px 25px 0px' }} />
          </React.Fragment>
        ))}

        {allowEdit &&
          <div className={mainFormClasses.addNewBtn} onClick={addNewSubtopic}>
            + {t('instanceDetails.topic.form.addSubtopicBtn')}
          </div>
        }
      </div>
      <ConfirmationModal
        open={!!isBackLinkConfirmModalShown}
        title={t('warnings.unsavedChanges.title', {ns: 'app'})}
        description={t('unsavedChanges.description', {ns: 'app'})}
        onClose={onCancelChangesSave}
        onConfirm={() => onSubmit()}
        type='warning'
        confirmText={t('actions.save', {ns: 'app'})}
        confirmWarning={validationWarning ? validationWarning : undefined}
      />
    </>
  )
}

interface TopicFormPropTypes {
  topic: SelectedTopicType
  setTopicData: (values: SelectedTopicType) => void
  goToInstanceForm: any
  instanceName: string
  allowEdit?: boolean
  onSubmit: () => Promise<any>
  resetTopicData?: () => void
  setHasTopicChanged: (hasChanged: boolean) => void
  hasTopicChanged: boolean
  validationWarning?: string
}

interface SelectedTopicType extends NewInstanceTopicType {
  subtopics: NewInstanceSubtopicType[]
  subtopicsDeleted?: number[],
  subtopicsDeletedWithTempId?: number[],
  subtopicsEdited?: NewInstanceSubtopicType[],
  subtopicsAdded?: NewInstanceSubtopicType[]
  isEditing: boolean
}

export default TopicForm
