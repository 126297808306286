/* eslint-disable */
import { Button, Tabs, TabsProps } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TooltipHint from '../../../common/TooltipHint/TooltipHint'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { ConfirmRequestThunk, SaveRequestResultThunk, selectRequestData } from '../../../../store/requestReducer'
import { setSessionList } from '../../../../store/sessionReducer'
import { setPersonDetailsActiveTabKey } from '../../../../store/personReducer'
import { ReactComponent as CopyIcon } from './../../../../img/icons/copy.svg'
import { ReactComponent as InfoIcon } from './../../../../img/icons/info.svg'
import { copyToClipboard } from '../../../../helpers/funcHelper'
import classes from './Results.module.css'
import { ReportCDType } from '../../../../types/requestTypes'
import ReactMarkdown from "react-markdown"

const Results:React.FC<ResultsPropTypes> = ({getPersonId, setResultsActiveTabKey, resultsActiveTabKey, isGenerating}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const generatedRequestData = useAppSelector(selectRequestData)

  const [isSaving, setIsSaving] = useState(false)

  const tabStyle = {
    backgroundColor: '#F9FAFB',
    padding: '12px',
    marginTop: '20px',
    whiteSpace: 'pre-wrap' as 'pre-wrap',
  }

  const getReportContent = (reportType: ReportCDType) => {
    return generatedRequestData?.request_reports.find(r => r.rep_cd === reportType)?.chat_gpt_completion
  }

  const tabs: TabsProps['items'] = [
    ...(!!getReportContent('main') ? [{
      key: 'main',
      label: 'General',
      children: <div id='main' style={{...tabStyle}}><ReactMarkdown>{getReportContent('main')}</ReactMarkdown></div>,
    }] : []),
    ...(!!getReportContent('conflicts') ? [{
      key: 'conflicts',
      label: 'Conflicts',
      children: <div id='conflicts' style={{...tabStyle}}><ReactMarkdown>{getReportContent('conflicts')}</ReactMarkdown></div>
    }] : []),
    ...(!!getReportContent('diagnoses') ? [{
      key: 'diagnoses',
      label: 'Diagnoses',
      children: <div id='diagnoses' style={{...tabStyle}}><ReactMarkdown>{getReportContent('diagnoses')}</ReactMarkdown></div>,
    }] : []),
    ...(!!getReportContent('kernberg') ? [{
      key: 'kernberg',
      label: 'Diagnoses (Kernberg)',
      children: <div id='kernberg' style={{...tabStyle}}><ReactMarkdown>{getReportContent('kernberg')}</ReactMarkdown></div>
    }] : []),
    ...(!!getReportContent('literature') ? [{
      key: 'literature',
      label: 'Literature',
      children: <div id='literature' style={{...tabStyle}}><ReactMarkdown>{getReportContent('literature')}</ReactMarkdown></div>,
    }] : []),
    ...(!!getReportContent('keywords') ? [{
      key: 'keywords',
      label: 'Keywords',
      children: <div id='keywords' style={{...tabStyle}}><ReactMarkdown>{getReportContent('keywords')}</ReactMarkdown></div>,
    }] : []),
  ]

  const getWrapperIdToCopyData = (copyAll?: boolean) => {
    if (copyAll) {
      return [
        ...(tabs?.some(t => t.key === 'main') ? [{id: 'main', title: 'General'}] : []),
        ...(tabs?.some(t => t.key === 'conflicts') ? [{id: 'conflicts', title: 'Conflicts'}] : []),
        ...(tabs?.some(t => t.key === 'diagnoses') ? [{id: 'diagnoses', title: 'Diagnoses'}] : []),
        ...(tabs?.some(t => t.key === 'kernberg') ? [{id: 'kernberg', title: 'Diagnoses (Kernberg)'}] : []),
        ...(tabs?.some(t => t.key === 'literature') ? [{id: 'literature', title: 'Literature'}] : []),
        ...(tabs?.some(t => t.key === 'keywords') ? [{id: 'keywords', title: 'Keywords'}] : []),
      ]
    }
    switch (resultsActiveTabKey) {
      case 'main':
        return [{id: 'main'}]
      case 'diagnoses':
        return [{id: 'diagnoses'}]
      case 'kernberg':
        return [{id: 'kernberg'}]
      case 'literature':
        return [{id: 'literature'}]
      case 'keywords':
        return [{id: 'keywords'}]
      case 'conflicts':
        return [{id: 'conflicts'}]
      default:
        return [{id: 'main'}]
    }
  }

  const saveReport = () => {
    setIsSaving(true)
    dispatch(SaveRequestResultThunk({requestId: generatedRequestData?.request_id!, reportData: generatedRequestData?.request_reports.map(r => ({message: r.chat_gpt_completion, rep_cd: r.rep_cd as ReportCDType})) || []}))
      .then((resp) => {
        if (!resp?.type?.includes('rejected')) {
          return dispatch(ConfirmRequestThunk(generatedRequestData?.request_id!))
        }
      })
      .then((resp) => {
        setIsSaving(false)
        if (!resp?.type?.includes('rejected')) {
          dispatch(setSessionList(null))
          dispatch(setPersonDetailsActiveTabKey('reports'))
          navigate(`/patients/edit/${getPersonId()}`)
        }
      })
  }

  if (!getReportContent('main')?.length
    && !getReportContent('diagnoses')?.length
    && !getReportContent('conflicts')?.length
  ) {
    return <></>
  }

  return (
    <div className={classes.reportResult}>
      <h2 id='reportResult'>
        Result information 
      </h2>
      <h3>
        Select a suitable result or change the parameters and query again.
      </h3>
        <Tabs
            items={tabs}
            activeKey={resultsActiveTabKey}
            onChange={key => setResultsActiveTabKey(key as ReportCDType)}
        />
      <div className={classes.actionButtonsWrapper}>
        <TooltipHint
          overlayInnerStyle={{width: 330, padding: '12px'}}
          placement='topLeft'
          title={
            <div style={{fontWeight: 400}}>
              <div style={{fontWeight: 600, color: '#344054', marginBottom: '5px'}}>
                Tip: Preserve Formatting When Copying Text
              </div>
              <div className={classes.copyHintContent} style={{marginBottom: '7px'}}>
                {/* eslint-disable-next-line */}
                When copying text from one document to another, maintaining the original formatting can be crucial. Here's a quick guide to ensure you keep the text formatting intact:
              </div>
              <ol style={{paddingLeft: '15px'}}>
                <li className={classes.copyHintContent}>
                  <span>Copy text.</span>
                </li>
                <li className={classes.copyHintContent}>
                  <span>Right-Click</span>: After coping the text, right-click in the text editor.
                </li>
                <li className={classes.copyHintContent}>
                  {/* eslint-disable-next-line */}
                  <span>Paste Options</span>: When you right-click, you'll see a menu. Hover over "Paste Options."
                </li>
                <li className={classes.copyHintContent}>
                  {/* eslint-disable-next-line */}
                  Choose <span>"Keep Text Only"</span> or <span>"Insert Without Formatting"</span>: In the Paste Options menu, select "Keep Text Only"/"Insert Without Formatting". This ensures that only the text is pasted without any formatting from the source document.
                </li>
              </ol>
            </div>
          }
        >
          <div style={{display: 'flex', alignItems: 'center', fontSize: '12px', width: '100%'}}>
            <InfoIcon style={{marginRight: '6px'}}/>Tip: Preserve Formatting When Copying Text
          </div>
        </TooltipHint>
        <div className={`actionButtons`} style={{marginBottom: '20px'}}>
          <Button
            onClick={() => copyToClipboard(getWrapperIdToCopyData(), 'Copied to clipboard')}
            className={classes.copyBtn}
          >
            <CopyIcon style={{marginRight: '8px'}}/> Copy
          </Button>
          {tabs?.length > 1 &&        
            <Button
              onClick={() => copyToClipboard(getWrapperIdToCopyData(true), 'Copied to clipboard')}
              className={classes.copyBtn}
            >
              <CopyIcon style={{marginRight: '8px'}}/> Copy all
            </Button>
          }
          <Button
            loading={isSaving}
            onClick={saveReport}
            type='primary'
            disabled={!!isGenerating.length}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

interface ResultsPropTypes {
  getPersonId: () => number
  setResultsActiveTabKey: (val: ReportCDType) => void
  resultsActiveTabKey: ReportCDType
  isGenerating: ReportCDType[]
}

export default Results
