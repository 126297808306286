import { Button, Dropdown, MenuProps } from 'antd'
import { ReactComponent as ExportIcon } from './../../../img/icons/export.svg'
import { ReactComponent as DownArrowIcon } from './../../../img/icons/down-arrow.svg'
import classes from './ExportSessionsBtn.module.css'
import { useTranslation } from 'react-i18next'

interface ExportSessionsBtnPropTypes {
  onExport: (instances: 'all' | 'sessions') => void
  isLoading: boolean
}

const ExportSessionsBtn:React.FC<ExportSessionsBtnPropTypes> = ({onExport, isLoading}) => {
  const { t } = useTranslation('commonComponents')

  const exportOptions:  MenuProps['items'] = [
    {key: 'sessions', label: <div onClick={() => onExport('sessions')}>{t('exportSessionsBtn.options.sessions')}</div>},
    {key: 'all', label: <div onClick={() => onExport('all')}>{t('exportSessionsBtn.options.all')}</div>},
  ]

  return (
    <Dropdown
      menu={{items: exportOptions}}
      placement='bottom'
      trigger={['click']}
    >
      <Button
        className={classes.exportBtn}
        loading={isLoading}
      >
        <ExportIcon /> {t('exportSessionsBtn.text')} <DownArrowIcon />
      </Button>
    </Dropdown>
  )
}

export default ExportSessionsBtn
