import { Button, Divider, Form, Input, InputNumber, Select, Spin } from 'antd'
import classes from './GeneralTab.module.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { CreatePersonThunk, EditPersonThunk, getPersonGeneralData, selectSelectedPersonData, setPersonDetailsActiveTabKey, setSelectedPersonData } from '../../../../store/personReducer'
import { GetFormatReferencesThunk, GetLanguageReferencesThunk, GetStyleReferencesThunk, GetTherapyMethodsThunk, selectFormatReferences, selectLanguageReferences, selectStyleReferences, selectTherapyMethods } from '../../../../store/referenceReducer'
import PopoverConfirm from '../../../common/PopoverConfirm/PopoverConfirm'
import { useLocation, useNavigate } from 'react-router-dom'
import { NewPersonType, PersonType } from '../../../../types/personTypes'
import { GetUserReportSettingsThunk, selectReportSettings, selectSuperUserCurrentUser, selectUserData } from '../../../../store/currentUserReducer'
import TagMultiSelect from '../../../common/TagMultiSelect/TagMultiSelect'
import { GetInstanceListThunk, selectInstanceList } from '../../../../store/instanceReducer'
import { getUIPermission } from '../../../../store/appStatusReducer'
import TooltipHint from '../../../common/TooltipHint/TooltipHint'
import { ReactComponent as HelpIcon } from './../../../../img/icons/help.svg'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import { setRequestData } from '../../../../store/requestReducer'
import { setSessionData } from '../../../../store/sessionReducer'
import { sortBy } from 'lodash'

const GeneralTab: React.FC<GeneralTabPropTypes> = ({
  isEditing,
  setHasDataChanged,
  isBackLinkConfirmModalShown,
  setIsBackLinkConfirmModalShown
}) => {
  const { t } = useTranslation(['forms', 'pages', 'app'])
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const styleReferences = useAppSelector(selectStyleReferences)
  const languageReferences = useAppSelector(selectLanguageReferences)
  const formatReferences = useAppSelector(selectFormatReferences)
  const therapyMethods = useAppSelector(selectTherapyMethods)
  const userData = useAppSelector(selectUserData)
  const instanceList = useAppSelector(selectInstanceList)
  const userReportSettings = useAppSelector(selectReportSettings)
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)

  const allowAddOwnPatient = useAppSelector(getUIPermission('Button.Patients.Own.Add')) && !superUserCurrentUser?.user_id
  const allowAddPatient = useAppSelector(getUIPermission('Button.Patients.Add')) && superUserCurrentUser?.user_id

  const [isSaving, setIsSaving] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    setIsDataLoading(true)
    Promise.all([
      ...(styleReferences === null ? [dispatch(GetStyleReferencesThunk())] : []),
      ...(languageReferences === null ? [dispatch(GetLanguageReferencesThunk())] : []),
      ...(formatReferences === null ? [dispatch(GetFormatReferencesThunk())] : []),
      ...(therapyMethods === null ? [dispatch(GetTherapyMethodsThunk())] : []),
      ...(instanceList === null ? [dispatch(GetInstanceListThunk())] : []),
      ...[userReportSettings === null ? [dispatch(GetUserReportSettingsThunk(superUserCurrentUser?.user_id || userData?.user_id))] : []]
    ]).then(() => setIsDataLoading(false))
    // eslint-disable-next-line
  }, [dispatch, styleReferences, languageReferences, formatReferences, therapyMethods, instanceList, userReportSettings])

  useEffect(() => {
    if (isEditing && selectedPersonData?.id) {
      form.setFieldsValue(getPersonGeneralData(selectedPersonData))
    } else if (!isEditing) {
      form.setFieldsValue({
        instance_list: sortBy(userReportSettings?.instance_list, i => i.order_num).map(i => String(i.id)),
        lang_cd: userReportSettings?.lang_cd,
        report_style: userReportSettings?.report_style,
        report_format: userReportSettings?.report_format,
        therapy_method_id: userData?.therapy_method_id
      })
    }
  }, [isEditing, selectedPersonData, form, userReportSettings, userData])

  const saveData = async(personData: NewPersonType) => {
    return isEditing
      ? dispatch(EditPersonThunk({
        personId: +pathname.split('/patients/edit/')[1],
        person: {...personData, user_id: userData.user_id, is_active: selectedPersonData?.is_active}
      }))
      : dispatch(CreatePersonThunk({...personData, user_id: userData.user_id}))
  }

  const handleSave = (personData: NewPersonType) => {
    return form.validateFields({ validateOnly: true }).then(() => {
      setIsSaving(true)
      return saveData({
        ...personData,
        instance_list: personData.instance_list?.map(instance => +instance) || []
      })
        .then((r) => {
          setIsBackLinkConfirmModalShown(false)
          setIsSaving(false)
          dispatch(setSelectedPersonData(null))
          !isEditing && !!(r?.payload as PersonType)?.id && navigate(`/patients/edit/${(r?.payload as PersonType)?.id}`)
        })
    }).catch(err => {
      form.setFields(err.errorFields)
      dispatch(setRequestData(null))
      dispatch(setSessionData(null))
      dispatch(setPersonDetailsActiveTabKey('general'))
      setIsBackLinkConfirmModalShown(false)
      setHasDataChanged(false)
      return Promise.resolve()
    })
  }

  if (isDataLoading) {
    return <Spin style={{width: '100%'}} />
  }

  return (
    <div className={classes.wrapper}>
      <h2>
        {t('patientDetails.general.title', {ns: 'pages'})}
      </h2>
      <h3>
        {isEditing ? t('patientDetails.general.descriptionEdit', {ns: 'pages'}) : t('patientDetails.general.descriptionAdd', {ns: 'pages'})}
      </h3>
      <Divider style={{margin: '20px 0px'}}/>
      <Form
        name='person'
        onFinish={handleSave}
        autoComplete='off'
        validateTrigger='onBlur'
        form={form}
        disabled={isEditing && !selectedPersonData?.is_active}
        onFieldsChange={() => setHasDataChanged(true)}
      >
        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.pseudonym.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='name'
              rules={[{required: true, message: t('fields.pseudonym.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input placeholder={t('fields.pseudonym.label')}/>
            </Form.Item>
          </div>
        </div>
        
        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.gender.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='gender'
              rules={[{required: true, message: t('fields.gender.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Select
                options={[{label: t('fields.gender.optionMale'), value: 'male'}, {label: t('fields.gender.optionFemale'), value: 'female'}]}
                placeholder={t('fields.gender.placeholder')}
              />
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.age.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='age'
              rules={[{required: true, message: t('fields.age.validationMessage')}]}
              style={{width: '100%'}}
            >
              <InputNumber
                placeholder={t('fields.age.placeholder')}
                controls={false}
                min={1}
                style={{width: '100%'}}
              />
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.status.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='status'
              rules={[{required: true, message: t('fields.status.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input placeholder={t('fields.status.placeholder')}/>
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.education.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='education'
              rules={[{required: true, message: t('fields.education.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input placeholder={t('fields.education.placeholder')}/>
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.profession.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='profession'
              rules={[{required: true, message: t('fields.profession.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input placeholder={t('fields.profession.placeholder')}/>
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.disposableIncome.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='income'
              rules={[{required: true, message: t('fields.disposableIncome.validationMessage')}]}
              style={{width: '100%'}}
            >
              <InputNumber
                placeholder={t('fields.disposableIncome.placeholder')}
                controls={false}
                min={0}
                style={{width: '100%'}}
              />
            </Form.Item>
          </div>
        </div>

        <Divider />

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.presentingSymptoms.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='presenting_symptoms'
              rules={[{required: true, message: t('fields.presentingSymptoms.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input.TextArea placeholder={t('fields.presentingSymptoms.placeholder')} rows={4}/>
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.descriptiveData.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='description'
              rules={[{required: true, message: t('fields.descriptiveData.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input.TextArea placeholder={t('fields.descriptiveData.placeholder')} rows={3}/>
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.therapyMethods.label')}
          </div>
          <div className={classes.inputWrapper} id='person-output-method-select'>
            <Form.Item name='therapy_method_id' style={{width: '100%'}}>
              <Select
                placeholder={t('fields.therapyMethods.placeholder')}
                options={therapyMethods?.map(ref => ({label: ref.name, value: ref.id}))}
                getPopupContainer={() => document.getElementById('person-output-method-select')!}
                allowClear
              />
            </Form.Item>
            <TooltipHint
              overlayInnerStyle={{width: '400px'}}
              placement='right'
              title={
                <>
                  <ul style={{padding: '10px', margin: '0px'}}>
                    {t(`fields.therapyMethods.patientHintTitle`)}
                    {therapyMethods?.map(method => (
                      <li key={method.id} style={{fontWeight: 400, paddingLeft: '5px', marginLeft: '15px'}}>
                        <span style={{fontWeight: 600, marginRight: '6px', color: '#344054'}}>
                          {method.name}:
                        </span>
                        <span style={{color: '#667085'}}>
                          {method.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            >
              <HelpIcon />
            </TooltipHint>
          </div>
        </div>

        <Divider />

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.instances.label')}
          </div>
          <div className={classes.inputWrapper} id='user-instances-select'>
            <Form.Item
              name='instance_list'
              // rules={[{required: true, message: t('fields.instances.validationMessage')}]}
              style={{width: '100%'}}
            >
              <TagMultiSelect
                placeholder={t('fields.instances.placeholder')}
                options={sortBy(instanceList, i => i.order_num)?.map(instance => ({label: instance.name, value: String(instance.id), color: instance.color})) || []}
                getPopupContainer={() => document.getElementById('user-instances-select')!}   
                tagColor='#3B414B'     
              />
            </Form.Item>
            <TooltipHint
              title={
                <>
                  <ul style={{padding: '10px', margin: '0px'}}>
                    {t(`fields.instances.patientHint.title`)}
                    <li style={{fontWeight: 400, paddingLeft: '5px', marginLeft: '15px'}}>
                      {t('fields.instances.patientHint.conflicts')}
                    </li>
                    <li style={{fontWeight: 400, paddingLeft: '5px', marginLeft: '15px'}}>
                      {t('fields.instances.patientHint.diagnosis')}
                    </li>
                  </ul>
                </>
              }
            >
              <HelpIcon />
            </TooltipHint>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.interviewer.label')}
          </div>
          <div className={classes.inputWrapper}>
            <Form.Item
              name='interviewer'
              rules={[{required: true, message: t('fields.interviewer.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Input placeholder={t('fields.interviewer.placeholder')}/>
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.language.label')}
          </div>
          <div className={classes.inputWrapper} id='person-output-language-select'>
            <Form.Item
              name='lang_cd'
              // rules={[{required: true, message: t('fields.language.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Select
                placeholder={t('fields.language.placeholder')}
                options={languageReferences?.map(ref => ({label: ref.language, value: ref.lang_cd}))}
                getPopupContainer={() => document.getElementById('person-output-language-select')!}
                allowClear
              />
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.styles.label')}
          </div>
          <div className={classes.inputWrapper} id='person-output-style-select'>
            <Form.Item
              name='report_style'
              // rules={[{required: true, message: t('fields.styles.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Select
                placeholder={t('fields.styles.placeholder')}
                options={styleReferences?.map(ref => ({label: ref.style, value: ref.style}))}
                getPopupContainer={() => document.getElementById('person-output-style-select')!}
                allowClear
              />
            </Form.Item>
          </div>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.format.label')}
          </div>
          <div className={classes.inputWrapper} id='person-output-format-select'>
            <Form.Item
              name='report_format'
              // rules={[{required: true, message: t('fields.format.validationMessage')}]}
              style={{width: '100%'}}
            >
              <Select
                placeholder={t('fields.format.placeholder')}
                options={formatReferences?.map(ref => ({label: ref.format, value: ref.format}))}
                getPopupContainer={() => document.getElementById('person-output-format-select')!}
                allowClear
              />
            </Form.Item>
          </div>
        </div>

        <Divider />

        {(selectedPersonData?.is_allow_edit || allowAddOwnPatient || allowAddPatient) &&
          <div className={`${classes.actionButtons} actionButtons`}>  
            {selectedPersonData?.is_active ? (
              <PopoverConfirm
                title={t('warnings.cancelation', {ns: 'app'})}
                onConfirm={() => navigate('/patients')}
              >
                <Button>
                  {t('actions.cancel', {ns: 'app'})}
                </Button>
              </PopoverConfirm>
            ) : (
              <Button onClick={() => navigate('/patients')} disabled={false}>
                {t('actions.cancel', {ns: 'app'})}
              </Button>
            )}
            <Form.Item>   
              <Button
                type='primary'
                htmlType='submit'
                loading={isSaving}
              >
                {t('actions.save', {ns: 'app'})}
              </Button>
            </Form.Item>
          </div>
        }
      </Form>
      <ConfirmationModal
        open={!!isBackLinkConfirmModalShown}
        title={t('warnings.unsavedChanges.title', {ns: 'app'})}
        description={t('warnings.unsavedChanges.description', {ns: 'app'})}
        onClose={() => navigate('/patients')}
        onConfirm={() => handleSave(form.getFieldsValue(true)).then(() => navigate('/patients'))}
        type='warning'
        confirmText={t('actions.save', {ns: 'app'})}
      />
    </div>
  )
}

interface GeneralTabPropTypes {
  isEditing: boolean
  setHasDataChanged: (hasChanged: boolean) => void
  isBackLinkConfirmModalShown: boolean
  setIsBackLinkConfirmModalShown: (isOpen: boolean) => void
}

export default GeneralTab
