/* eslint-disable */
import {motion, useInView} from 'framer-motion';
import React from 'react';
import classes from "../../Landing.module.scss";
import InfoBadge from "../InfoBadge/InfoBadge";
import {ReactComponent as SupportIcon} from './../../../../img/icons/landingSupportSystemIcon.svg'
import {ReactComponent as SmileIcon} from './../../../../img/icons/smile.svg'

const BeyondExpectations = () => {

    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, {once: true});

    const titleVariants = {
        hidden: {opacity: 0, y: -100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 0.5
            }
        }
    };

    const firstBlockVariants = {
        hidden: {opacity: 0, y: 100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 1.2
            }
        }
    }

    const secondBlockVariants = {
        hidden: {opacity: 0, y: 100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 1.5
            }
        }
    }

    return (
        <div
            className={`${classes.block} ${classes.block_whiteBg} ${classes.beyond}`}
            ref={ref}
        >
            <motion.div
                className={classes.beyond__textContent}
                variants={titleVariants}
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
            >
                <h2 className={classes.commonTitle} style={{
                    marginBottom: '10px'
                }}>
                    <span className={classes.commonTitle_blue}>Beyond Expectations:</span> Comprehensive Support and
                    Dream
                    Interpretation
                </h2>
                <p
                    className={classes.commonText}
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    Experience unparalleled support with our integrated system, alleviating the hassle of insurance
                    paperwork. But that's just the beginning - explore the depths of the unconscious with our
                    interactive
                    dream interpreter, empowering you with new insights to elevate your practice.
                </p>
                <InfoBadge text={'INTEGRATIONS'}/>
            </motion.div>
            <div className={classes.beyond__blocks}>
                <motion.div
                    className={classes.beyond__block}
                    variants={firstBlockVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                >
                    <SupportIcon/>
                    <h4>Support system</h4>
                    <p>Seamlessly integrated is a comprehensive support system, easing the burden of insurance form
                        completion, saving you time and frustration.</p>
                </motion.div>
                <motion.div
                    className={classes.beyond__block}
                    variants={secondBlockVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                >
                    <SmileIcon/>
                    <h4>Interactive dream interpreter</h4>
                    <p>Introducing our latest feature: an interactive dream interpreter. Delve deeper into the
                        unconscious realm, gaining valuable insights to enrich your practice.</p>
                </motion.div>
            </div>
        </div>
    );
};

export default BeyondExpectations;