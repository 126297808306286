/* eslint-disable */
import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectIsRequestLoading,
    selectIsSuccessModalOpened,
    setIsSuccessModalOpened
} from "../../store/currentUserReducer";
import classes from "../SignIn/SignIn.module.css";
import logo from "../../img/logo.png";
import {Button, Form, Input} from "antd";
import signInImage from "../../img/landingImg/List of Patients. Sessions1.png";
import SuccessModal from "../common/SuccessModal";
import {ResetUserPassword} from "../../store/userReducer";
import {useNavigate} from "react-router-dom";


const RestorePassword = () => {
    const {t} = useTranslation(['pages', 'forms'])
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(selectIsRequestLoading)
    const isModalOpened = useAppSelector(selectIsSuccessModalOpened)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setIsSuccessModalOpened(false))
    }, [])

    const onFinish = (formData: { email: string }) => {
        dispatch(ResetUserPassword(formData.email))
    }


    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.logo}>
                    <img src={logo} alt='logo' className={classes.logo}/>
                </div>
                <Form
                    name='createPassword'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={classes.form}
                >
                    <h1 className={classes.signUpTitle}>
                        {t('auth.forgotPassword.title')}
                    </h1>
                    <p className={classes.signUpText}> {t('auth.forgotPassword.restoreText')}</p>
                    <div className={classes.label}>
                        {t('fields.email.label', {ns: 'forms'})}
                    </div>
                    <Form.Item
                        name='email'
                        rules={[
                            {required: true, message: t('fields.email.validationMessage', {ns: 'forms'})},
                            {type: 'email', message: t('fields.email.formatValidationMessage', {ns: 'forms'})},
                        ]}
                    >
                        <Input placeholder={t('fields.email.placeholder', {ns: 'forms'})}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
                            {t('auth.forgotPassword.buttonText')}
                        </Button>
                    </Form.Item>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '12px',
                        width: '100%',
                        justifyContent: 'center'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                            <path
                                d="M13 6.75C13.4142 6.75 13.75 6.41421 13.75 6C13.75 5.58579 13.4142 5.25 13 5.25V6.75ZM0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
                                fill="#667085"/>
                        </svg>
                        <p
                            style={{
                                margin: '0 0 0 10px',
                                color: '#667085',
                                fontSize: '16px',
                                cursor: "pointer"
                            }}
                            onClick={() => navigate('/signIn')}
                        >Back</p>
                    </div>
                </Form>
                <div className={classes.signUpImage}>
                    <div>
                        <p>{t('auth.signUp.signUpDescription')}</p>
                    </div>
                    <img src={signInImage} alt=''/>
                </div>
            </div>
            {
                isModalOpened
                &&
                <SuccessModal title={''} withText={true} height={260} isResetPassword={true}/>
            }
        </>
    )
}

export default RestorePassword