/* eslint-disable */
import React from 'react';
import classes from "../SignIn/SignIn.module.css";
import signInImage from "../../img/landingImg/List of Patients. Sessions1.png";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectIsRequestLoading,
    selectIsSuccessModalOpened,
} from "../../store/currentUserReducer";
import {useSearchParams} from "react-router-dom";
import logo from "../../img/logo.png";
import {Button, Form, Input, message} from "antd";
import {selectPublicKey, SetUserPassword} from "../../store/userReducer";
import CryptoJS from "crypto-js";
import SuccessModal from "../common/SuccessModal";

const CreatePassword = () => {
    const {t} = useTranslation(['pages', 'forms'])
    const dispatch = useAppDispatch()
    const CRYPTOR_KEY = useAppSelector(selectPublicKey)
    const isLoading = useAppSelector(selectIsRequestLoading)
    const isModalOpened = useAppSelector(selectIsSuccessModalOpened)
    const [searchParams] = useSearchParams()

    const onFinish = (formData: { password: string, confirm_password: string }) => {
        if (formData.password !== formData.confirm_password) {
            message.error('Two passwords must be equal')
        } else {
            const encryptedPassword = encrypt(formData.password, CRYPTOR_KEY);
            dispatch(SetUserPassword({password: encryptedPassword, token: `${searchParams.get('token')!}`}))
        }
    }

    // Encrypt function using CryptoJS
    function encrypt(text: string, base64Key: string): string {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }


    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.logo}>
                    <img src={logo} alt='logo' className={classes.logo}/>
                </div>
                <Form
                    name='createPassword'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={classes.form}
                >
                    <h1 className={classes.signUpTitle}>
                        {t('auth.createPassword.title')}
                    </h1>
                    <p className={classes.signUpText}> {t('auth.createPassword.subTitle')}</p>
                    <div className={classes.label}>
                        {t('fields.createPassword.label', {ns: 'forms'})}
                    </div>
                    <Form.Item
                        name='password'
                        rules={[
                            {required: true, message: t('fields.createPassword.validationMessage', {ns: 'forms'})}
                        ]}
                    >
                        <Input type={'password'} placeholder={t('fields.createPassword.placeholder', {ns: 'forms'})}/>
                    </Form.Item>

                    <div className={classes.label}>
                        {t('fields.confirmPassword.label', {ns: 'forms'})}
                    </div>
                    <Form.Item
                        name='confirm_password'
                        rules={[
                            {required: true, message: t('fields.confirmPassword.validationMessage', {ns: 'forms'})},
                        ]}
                    >
                        <Input type={'password'} placeholder={t('fields.confirmPassword.placeholder', {ns: 'forms'})}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
                            {t('auth.createPassword.signUpBtn')}
                        </Button>
                    </Form.Item>
                </Form>
                <div className={classes.signUpImage}>
                    <div>
                        <p>{t('auth.signUp.signUpDescription')}</p>
                    </div>
                    <img src={signInImage} alt=''/>
                </div>
            </div>
            {
                isModalOpened
                &&
                <SuccessModal title={'Password successfully created'} withText={false} height={260}/>
            }
        </>

    );
};

export default CreatePassword;