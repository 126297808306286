import { useState } from 'react'
import { Button, Divider, Dropdown } from 'antd'
import classes from './CurrentUserMenu.module.css'
import SelectUserModal from './SelectUserModal/SelectUserModal'
import { ReactComponent as DownArrowIcon } from './../../../img/icons/down-arrow.svg'
import { SignOutThunk, selectIsSuperUser, selectSuperUserCurrentUser, selectUserData, setSuperUserCurrentUser } from '../../../store/currentUserReducer'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Tag from '../../common/Tag/Tag'
import { setUserListForSuperUserRequestParams, userListForSuperUserRequestParamsInitialValue } from '../../../store/userReducer'
import { setSelectedPersonData } from '../../../store/personReducer'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CurrentUserMenu = () => {
  const { t } = useTranslation(['commonComponents'])
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const userData = useAppSelector(selectUserData)
  const isSuperUser = useAppSelector(selectIsSuperUser)
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)

  const [isSelectUserModalOpen, setIsSelectUserModalOpen] = useState(false)
    
  const signOut = () => {
    dispatch(SignOutThunk())
  }

  const selectMyAccount = () => {
    dispatch(setSuperUserCurrentUser(null))
    dispatch(setUserListForSuperUserRequestParams(userListForSuperUserRequestParamsInitialValue))
    dispatch(setSelectedPersonData(null))
    if (pathname.includes('/patients/')) {
      navigate('/patients')
    }
  }

  const userOptions = [
    {
      label: (
        <>
          <div
            className={classes.userData} style={{margin: '8px 0px'}}
            onClick={() => selectMyAccount()}
          >
            {(!!userData?.first_name?.length || !!userData?.last_name?.length) &&
              <div className={classes.userName}>
                {userData?.first_name} {!!userData?.last_name?.length && userData?.last_name}
              </div>
            }
            <div className={classes.userEmail}>
              {userData.email}
            </div>
            <Tag
              text={t('header.userMenu.youTag')}
              style={{
                color: '#007DFA',
                backgroundColor: '#E0EFFE',
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                width: '30px',
                fontSize: '10px',
                display: 'flex',
                justifyContent: 'center',
                lineHeight: '10px',
              }}/>
          </div>
        </>
      ),
      key: '1',
      disabled: !superUserCurrentUser?.user_id,
    },
    {
      key: 'divider',
      label: <Divider style={{margin: '0px 0px 5px 0px'}} />,
      disabled: true
    },
    ...(isSuperUser ? [{
      key: '2',
      label: <div onClick={() => setIsSelectUserModalOpen(true)}>{t('header.userMenu.selectUser')}</div>,
    }] : []
    ),
    {
      key: '3',
      label: <div onClick={() => signOut()}>{t('header.userMenu.logOut')}</div>,
    }
  ]

  return (
    <>
      <Dropdown
        menu={{ items: userOptions }}
        className={classes.userEmail}
        trigger={['click']}
      >
        <Button className={classes.userMenuBtn} style={{display: 'flex', alignItems: 'center'}}>
          {(!superUserCurrentUser?.user_id && !!userData?.first_name?.length) || !!superUserCurrentUser?.first_name?.length ? (
            <div className={classes.userName}>
              {superUserCurrentUser?.first_name || userData?.first_name} {superUserCurrentUser?.last_name || userData?.last_name}
            </div>
          ) : (
            <div className={classes.userEmail}>
              {superUserCurrentUser?.email || userData.email}
            </div> 
          )}
          {isSuperUser &&
            <Tag
              text={superUserCurrentUser?.user_id ? t('header.userMenu.userTag') : t('header.userMenu.youTag')}
              style={{
                color: superUserCurrentUser?.user_id ? '#5A5A5D' : '#007DFA',
                backgroundColor: superUserCurrentUser?.user_id ? '#DDDEDF' : '#E0EFFE',
                width: '30px',
                fontSize: '10px',
                display: 'flex',
                justifyContent: 'center',
                lineHeight: '10px',
                alignSelf: 'baseline',
                marginLeft: '5px',
              }}
            />
          }
          <DownArrowIcon />
        </Button>
      </Dropdown>
    
      <SelectUserModal
        isOpen={isSelectUserModalOpen}
        onClose={() => setIsSelectUserModalOpen(false)}
      />
    </>
  )
}

export default CurrentUserMenu
