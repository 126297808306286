import { Button, Modal } from 'antd'
import { useState } from 'react'
import classes from './ConfirmationModal.module.css'
import { ReactComponent as SuccessIcon } from './../../../img/icons/success.svg'
import { ReactComponent as ErrorIcon } from './../../../img/icons/error.svg'
import { ReactComponent as WarningIcon } from './../../../img/icons/warning.svg'
import { useTranslation } from 'react-i18next'

const ConfirmationModal: React.FC<ConfirmationModalPropTypes> = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  type,
  confirmText,
  isRequestLoading,
  confirmWarning
}) => {
  const { t } = useTranslation('app')
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirmation = () => {
    if (isRequestLoading === undefined) {
      setIsLoading(true)
      onConfirm()
        .then(() => setIsLoading(false))
    } else {
      onConfirm()
    }
  }

  return (
    <Modal
      open={open}
      footer={null}
      destroyOnClose
      closable={false}
      width={600}
    >
      <div className={classes.saveSessionModal}>
        {type === 'success' && <SuccessIcon />}
        {type === 'delete' && <ErrorIcon />}
        {type === 'warning' && <WarningIcon />}
        <div className={classes.modalTitle}>
          {title}
        </div>
        <div style={{textAlign: 'center'}}>
          {description}
        </div>
        <div className={`${classes.actionButtons} actionButtons`}>
          <Button onClick={() => onClose()}>
            {t('actions.cancel')}
          </Button>
          <div>
            <Button
              type='primary'
              onClick={handleConfirmation}
              loading={isRequestLoading || isLoading}
              className={type === 'success' ? '' : 'deleteBtn'}
              disabled={!!confirmWarning}
            >
              {confirmText ? confirmText : type === 'success' ? t('actions.save') : t('actions.delete')}
            </Button>
            {!!confirmWarning?.length &&
              <div className={classes.confirmWarning}>
                {confirmWarning}
              </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}

interface ConfirmationModalPropTypes {
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<any>
  title: string
  description?: string
  type: 'success' | 'delete' | 'warning'
  confirmText?: string
  isRequestLoading?: boolean
  confirmWarning?: string
}

export default ConfirmationModal
