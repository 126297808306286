/* eslint-disable */
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Button, DatePicker, Divider, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { DeleteRequestThunk, GetRequestListByPersonIdThunk, requestListRequestParamsInitialValue, selectRequestData, selectRequestList, selectRequestListFilterCount, selectRequestListRequestParams, selectRequestListTotalCount, setRequestData, setRequestListRequestParams } from '../../../../store/requestReducer'
import classes from './ReportsTab.module.css'
import { ReactComponent as ReportIcon } from './../../../../img/icons/reportWithBg.svg'
import { ReactComponent as RightArrowIcon } from './../../../../img/icons/right-arrow.svg'
import { ReactComponent as DeleteIcon } from './../../../../img/icons/delete.svg'
import PopoverConfirm from '../../../common/PopoverConfirm/PopoverConfirm'
// import InstanceTagRow from '../../../common/InstanceTagRow/InstanceTagRow'
import ReportDetails from '../../../Report/ReportDetails/ReportDetails'
import Tag from '../../../common/Tag/Tag'
import { selectSelectedPersonData } from '../../../../store/personReducer'
import { useTranslation } from 'react-i18next'

const ReportsTab: React.FC<ReportsTabPropTypes> = () => {
  const { t } = useTranslation(['pages', 'app'])
  dayjs.extend(utc)
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const requestData = useAppSelector(selectRequestData)
  const requestList = useAppSelector(selectRequestList)
  const filterCount = useAppSelector(selectRequestListFilterCount)
  const totalCount = useAppSelector(selectRequestListTotalCount)
  const requestListRequestParams = useAppSelector(selectRequestListRequestParams)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)

  const [isRequestsLoading, setIsRequestsLoading] = useState(false)
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(false)

  const showMore = () => {
    setIsInfiniteScroll(true)
    dispatch(setRequestListRequestParams({
      ...requestListRequestParams,
      pagination_request: {
        ...requestListRequestParams.pagination_request!,
        page: requestListRequestParams?.pagination_request?.page! + 1
      }
    }))
  }

  useEffect(() => {
    setIsRequestsLoading(true)
    dispatch(GetRequestListByPersonIdThunk({
      infiniteScroll: isInfiniteScroll,
      requestParams: {
        ...requestListRequestParams,
        request_data_request: {
          ...requestListRequestParams.request_data_request,
          person_id: +pathname.split('/patients/edit/')[1],
        },
      }
    }))
      .then(() => setIsRequestsLoading(false))
  // eslint-disable-next-line
  }, [dispatch, requestListRequestParams, isInfiniteScroll])

  if (requestList === null || (!requestList.length && isRequestsLoading)) {
    return (<Spin style={{width: '100%'}}/>)
  }

  if (!!requestData?.request_id) {
    return (
      <ReportDetails />
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.listTools}>
        <div className={classes.datePickerWrapper}>
          <DatePicker.RangePicker
            value={[
              requestListRequestParams?.request_data_request?.date_from || requestListRequestParamsInitialValue?.request_data_request?.date_from,
              requestListRequestParams?.request_data_request?.date_till || requestListRequestParamsInitialValue?.request_data_request?.date_till,
            ]}
            allowClear={false}
            onChange={(val) => {
              setIsInfiniteScroll(false)
              dispatch(setRequestListRequestParams({
                pagination_request: {page: 1, size: 10, sort: [{field: 'request_end', direction: 'DESC' as 'DESC'}]},
                request_data_request: {
                  ...requestListRequestParams.request_data_request,
                  date_from: val?.[0] as Dayjs,
                  date_till: val?.[1]?.endOf('day') as Dayjs
                }
              }))
            }}
          />
          {filterCount !== 0 &&
            <Tag
              text={t(filterCount === 1 ? 'count.report' : 'count.report_plural', {ns: 'app', count: filterCount})}
              style={{marginLeft: '10px'}}
            />
          }
        </div>
      </div>
      {!!requestList?.length ? (
        <div className={classes.requestsWrapper}>
          {requestList?.filter(r => !!r?.request_end)?.map(request => (
            <div className={classes.request} key={request.request_id}>
              <div
                className={classes.cardHeader}
                onClick={() => dispatch(setRequestData(request))}
              >
                <div className={classes.requestNameWrapper}>
                  <ReportIcon /> 
                  <div className={classes.requestName}>
                    {t('report', {ns: 'app'})}
                    <div className={classes.date}>
                      {dayjs.utc(request?.request_end).format('DD.MM.YYYY HH:mm')}
                    </div>
                  </div>
                </div>
                <div className={classes.tools}>
                  <div onClick={e => e.stopPropagation()} className={classes.deletePopover}>
                    {selectedPersonData?.is_btn_delete &&
                      <PopoverConfirm
                        title={t('patientDetails.reports.confirmDelete')}
                        onConfirm={() => dispatch(DeleteRequestThunk(request.request_id))}
                      >
                        <DeleteIcon />
                      </PopoverConfirm>
                    }
                  </div>
                  <RightArrowIcon />
                </div>
              </div>
              <Divider style={{margin: '16px 0px'}} />
              <div>
                <div className={classes.cardData} id={`report-${request.request_id}`}>
                  <div>
                    {t('patientDetails.reports.reportCard.date', {date: `${dayjs(request.date_from).format('YYYY.MM.DD')}-${dayjs(request.date_till).format('YYYY.MM.DD')}`})}
                  </div>
                  <div>
                    {t('patientDetails.reports.reportCard.language', {language: request?.language_name})}
                  </div>
                  <div>
                    {t('patientDetails.reports.reportCard.styles', {styles: request?.report_style})}
                  </div>
                  <div>
                    {t('patientDetails.reports.reportCard.format', {format: request?.report_format})}
                  </div>
                </div>
                {/* <InstanceTagRow
                  tags={request?.instance_list?.map(instance => ({tag: instance.name, color: instance.color}))}
                  containerWidth={400}
                  wrapperStyle={{padding: '10px 16px 0px 16px'}}
                  containerId={`report-${request.request_id}`}
                /> */}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={classes.emptyList}>
          {totalCount > 0 ? t('patientDetails.reports.noReportsPeriod') : t('patientDetails.reports.noReports')}
        </div>
      )}
      {!!requestList && requestList?.length && requestList?.length < filterCount ?
        <Button
          type='primary'
          onClick={showMore}
          className={classes.showMore}
          loading={isRequestsLoading}
        >
          {t('actions.showMore', {ns: 'app'})}
        </Button>
          :
          null
      }
    </div>
  )
}

interface ReportsTabPropTypes {
  isEditing: boolean
}

export default ReportsTab
