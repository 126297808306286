import { DatePicker, Input, Spin } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useGridApiRef, GridSortModel, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { DeleteSessionThunk, GetSessionsByPersonIdThunk, GetSessionsExcelThunk, selectSessionData, selectSessionList, selectSessionListFilterCount, selectSessionListManualParams, selectSessionListRequestParams, setSessionData, setSessionListManualParams, setSessionListRequestParams } from '../../../../store/sessionReducer'
import classes from './SessionsTab.module.css'
import { ReactComponent as DeleteIcon } from './../../../../img/icons/delete.svg'
import { ReactComponent as SearchIcon } from './../../../../img/icons/search.svg'
import { ReactComponent as EditIcon } from './../../../../img/icons/edit.svg'
import { ReactComponent as ViewIcon } from './../../../../img/icons/view.svg'
import { ReactComponent as HelpIcon } from './../../../../img/icons/help.svg'
import SessionDetails from '../../../Sessions/SessionDetails/SessionDetails'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { sortBy, uniqBy } from 'lodash'
import Tag from '../../../common/Tag/Tag'
import MuiTable from '../../../common/MuiTable/MuiTable'
import { SessionType } from '../../../../types/sessionTypes'
import TooltipHint from '../../../common/TooltipHint/TooltipHint'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import { selectSelectedPersonData } from '../../../../store/personReducer'
import ExportSessionsBtn from '../../../common/ExportSessionsBtn/ExportSessionsBtn'
import { getDisplayData } from '../../../../helpers/filtersHelper'

const SessionsTab: React.FC<SessionsTabPropTypes> = () => {
  const { t } = useTranslation(['pages', 'app'])
  dayjs.extend(utc)
  const apiRef = useGridApiRef()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const sessionList = useAppSelector(selectSessionList)
  const sessionData = useAppSelector(selectSessionData)
  const filterCount = useAppSelector(selectSessionListFilterCount)
  const sessionListRequestParams = useAppSelector(selectSessionListRequestParams)
  const sessionListManualParams = useAppSelector(selectSessionListManualParams)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)

  const [isSessionsLoading, setIsSessionsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [confirmationModalData, setConfirmationModalData] = useState<null | {type: 'delete', session: SessionType}>(null)
  const [isExportingExcel, setIsExportingExcel] = useState(false)
  const [dataForDisplay, setDataForDisplay] = useState<{data: SessionType[] | null, totalCount: number}>({data: null, totalCount: 0})

  const columns = [
    {
      field: 'rec_date',
      headerName: t('patientDetails.sessions.table.columnTitles.date'),
      flex: 0.1,
      minWidth: 120,
      filterable: true,
      renderCell: (params: GridRenderCellParams<SessionType, Date>) => (
        <div style={{fontSize: '14px'}}>
          {dayjs(params.row.rec_date).format('DD.MM.YYYY')}
        </div>
      )
    },
    {
      field: 'extra_data',
      headerName: t('patientDetails.sessions.table.columnTitles.note'),
      flex: 0.8,
      minWidth: 120,
      filterable: true,
      cellClassName: 'tableSecondaryTextStyle',
    },
    {
      field: 'instances',
      headerName: t('patientDetails.sessions.table.columnTitles.instances'),
      flex: 1,
      minWidth: 120,
      filterable: true,
      sortable: false,
      cellClassName: 'tableSecondaryTextStyle',
      renderCell: (params: GridRenderCellParams<SessionType, Date>) => {
        const uniqueInstances = uniqBy(params.row.person_data_rec_list, rec => rec.instance.id)
        return (
          <>
            {sortBy(uniqueInstances, inst => inst.instance?.order_num).map((item, index) => {
              const topicCount = params.row.person_data_rec_list
                .map(rec => rec.topic)
                .filter(t => t.instance_id === item.instance.id)
                .length
              return (
                <Tag
                  text={item.instance.name + '(' + topicCount +  ')'}
                  style={{
                    backgroundColor: item.instance.color,
                    color: '#3B414B',
                    marginLeft: index === 0 ? '0px' : '10px'
                  }}
                  key={item.id}
                />
              )
            })}
          </>
        )
      }
    },
    {
      field: 'Actions',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 0.1,
      minWidth: 110,
      renderCell: (params: GridRenderCellParams<SessionType, Date>) => (
        <div className={classes.tableActions}>      
          {selectedPersonData?.is_allow_edit &&
            <TooltipHint title={t('patientDetails.sessions.table.actionHints.delete')}>
              <DeleteIcon onClick={() => setConfirmationModalData({type: 'delete', session: params.row})}/>
            </TooltipHint>
          }
          {selectedPersonData?.is_allow_edit &&
            <TooltipHint title={t('patientDetails.sessions.table.actionHints.edit')}>
              <Link to={`/patients/${params.row.person.id}/sessions/edit/${params.row.id}`} style={{marginBottom: '-5px'}}>
                <EditIcon />
              </Link>
            </TooltipHint>
          }
          <TooltipHint title={t('patientDetails.sessions.table.actionHints.view')}>
            <ViewIcon onClick={() => dispatch(setSessionData(params.row))}/>
          </TooltipHint>
        </div>
      )
    },
  ]

  useEffect(() => {
    setIsSessionsLoading(true)
    dispatch(GetSessionsByPersonIdThunk({
      infiniteScroll: false,
      requestParams: {
        ...sessionListRequestParams,
        person_data_request: {
          ...sessionListRequestParams.person_data_request,
          person_id: +pathname.split('/patients/edit/')[1],
        },
      }
    }))
      .then(() => setIsSessionsLoading(false))
  // eslint-disable-next-line
  }, [dispatch, sessionListRequestParams])

  useEffect(() => {
    getDataForDisplay()
    // eslint-disable-next-line
  }, [sessionListManualParams, sessionList, searchValue])

  const handleSortModelChange = (sortModel: GridSortModel) => {
    dispatch(setSessionListManualParams({
      ...sessionListManualParams,
      page: 1,
      sort: sortModel
    }))
  }

  const handleSearch = () => {
    return sessionList?.filter(s => {
      const hasInComment = s.extra_data?.toLowerCase().includes(searchValue?.toLowerCase())
      const hasInSubtopicComment = s.person_data_rec_list?.some(rec => rec.subtopics?.some(sub => sub.note?.toLowerCase().includes(searchValue?.toLowerCase())))
      return hasInComment || hasInSubtopicComment
    })
  }

  const getDataForDisplay = () => {
    let initialList = searchValue?.length ? handleSearch() : sessionList
    initialList = initialList?.map(session => {
      const uniqueInstances = uniqBy(session.person_data_rec_list, rec => rec.instance.id)
      return {
        ...session,
        instances: uniqueInstances.map((item) => {
          const topicCount = session.person_data_rec_list
            .map(rec => rec.topic)
            .filter(t => t.instance_id === item.instance.id)
            .length
          return `${item.instance.name} (${topicCount})`
        })}
    })
    const updatedData = getDisplayData<SessionType>(initialList || [], sessionListManualParams)
    setDataForDisplay(updatedData)
  }

  const exportExcel = (instances: 'all' | 'sessions') => {
    setIsExportingExcel(true)
    dispatch(GetSessionsExcelThunk({
      date_from: sessionListRequestParams.person_data_request.date_from?.format('YYYY-MM-DDTHH:mm:ss[Z]')!,
      date_till: sessionListRequestParams.person_data_request.date_to?.format('YYYY-MM-DDTHH:mm:ss[Z]')!,
      patient_id: selectedPersonData?.id!,
      user_id: selectedPersonData?.user?.user_id!,
      with_all_instances: instances === 'all'
    })).then(() => setIsExportingExcel(false))
  }

  if (sessionList === null) {
    return (<Spin style={{width: '100%'}}/>)
  }

  if (sessionData?.id) {
    return (
      <SessionDetails />
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.listTools}>
        <div className={classes.inputsWrapper}>
          <Input
            placeholder={t('actions.search', {ns: 'app'})}
            prefix={<SearchIcon />}
            style={{width: '450px'}}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            suffix={
              <TooltipHint title={t('patientDetails.sessions.searchHint')}>
                <HelpIcon />
              </TooltipHint>
            }
          />
          
          <div className={classes.datePickerWrapper}>
            <DatePicker.RangePicker
              value={[
                sessionListRequestParams?.person_data_request?.date_from || dayjs.utc().startOf('month'),
                sessionListRequestParams?.person_data_request?.date_to || dayjs.utc().endOf('day'),
              ]}
              allowClear={false}
              onChange={(val) => {
                dispatch(setSessionListRequestParams({
                  person_data_request: {
                    ...sessionListRequestParams.person_data_request,
                    date_from: val?.[0] as Dayjs,
                    date_to: val?.[1]?.endOf('day') as Dayjs
                  }
                }))
              }}
            />
            {filterCount !== 0 &&
              <Tag
                text={t(filterCount === 1 ? 'count.session' : 'count.session_plural', {ns: 'app', count: filterCount})}
                style={{marginLeft: '10px'}}
              />
            }
          </div>
        </div>
        <div className={classes.toolButtonsWrapper}>
          <ExportSessionsBtn
            isLoading={isExportingExcel}
            onExport={(instances: 'all' | 'sessions') => exportExcel(instances)}
          />
        </div>
      </div>
      <MuiTable
        columns={columns}
        rows={dataForDisplay.data || []}
        loading={isSessionsLoading}
        apiRef={apiRef}
        sortingMode='server'
        count={dataForDisplay.totalCount}
        page={sessionListManualParams.page - 1}
        onPageChange={(_, page) => {
          dispatch(setSessionListManualParams({
            ...sessionListManualParams,
            page: page + 1,
          }))
        }}
        onSortModelChange={handleSortModelChange}
        sortModel={sessionListManualParams?.sort?.length ? [{
          field: sessionListManualParams?.sort?.[0]?.field,
          sort: sessionListManualParams?.sort?.[0]?.sort?.toLowerCase()
        }] : []}
        filterModel={sessionListManualParams.filters}
        onFilterModelChange={(model) => {
          dispatch(setSessionListManualParams({
            ...sessionListManualParams,
            page: 1,
            filters: model
          }))
        }}
      />
      {/* delete session confirmation */}
      <ConfirmationModal
        open={!!confirmationModalData?.session?.id}
        title={t('patientDetails.sessions.table.deleteConfirmation')}
        description={t('warnings.delete', {ns: 'app', itemName: t('session', {ns: 'app'}).toLowerCase()})}
        onClose={() => setConfirmationModalData(null)}
        onConfirm={() => dispatch(DeleteSessionThunk(confirmationModalData?.session?.id!))
          .then(() => setConfirmationModalData(null))
        }
        type='delete'
        confirmText={t('actions.delete', {ns: 'app'})}
      />
    </div>
  )
}

interface SessionsTabPropTypes {
  isEditing: boolean
}

export default SessionsTab
