/* eslint-disable */
import React from 'react';
import classes from "../../Landing.module.scss";
import signUpImg from "../../../../img/landingImg/Sign up.png";
import {motion, useInView} from "framer-motion";
import {useNavigate} from "react-router-dom";


const JoinRevolution = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, {once: true});
    const navigate = useNavigate()

    const textVariants = {
        hidden: {opacity: 0, y: -100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 1
            }
        }
    };


    const imageVariants = {
        hidden: {opacity: 0, y: 100},
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                mass: 2,
                duration: 1.3,
                delay: 1.3
            }
        }
    };

    const buttonVariants = {
        hidden: {opacity: 0},
        visible: {
            opacity: 1,
            transition: {duration: 1, delay: 1.6}

        }
    }

    const onVisitLoginPage = () => {
        navigate('/sign-in')
    }


    const onVisitSignInPage = () => {
        navigate('/sign-up')
    }

    return (
        <div
            className={`${classes.block} ${classes.block_greyBg} ${classes.joinRevolution}`}
            ref={ref}
        >
            <div className={classes.revolution}>
                <motion.div
                    variants={textVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                >
                    <p className={classes.revolution__title}>Join the revolution with <span
                        className={classes.commonTitle_blue}>CaseInsight.Net</span></p>
                    <p className={classes.commonText}>Experience efficiency. Embrace insight. Welcome to
                        CaseInsight.Net</p>
                </motion.div>
                <motion.div style={{
                    marginTop: '37px',
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '37px'
                }}
                            variants={buttonVariants}
                            initial="hidden"
                            animate={isInView ? 'visible' : 'hidden'}
                            className={classes.revolution__buttons}
                >
                    <button className={classes.loginBtn} onClick={onVisitLoginPage}>Sign in</button>
                    <button className={`${classes.earlyAccessBtn} ${classes.earlyAccessBtn_withArrow}`} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                            onClick={onVisitSignInPage}
                    >
                        Get early access
                        <svg style={{marginLeft: '10px'}} xmlns="http://www.w3.org/2000/svg" width="25" height="24"
                             viewBox="0 0 25 24" fill="none">
                            <path
                                d="M21.6506 12.5306L14.9006 19.2806C14.7599 19.4213 14.569 19.5003 14.37 19.5003C14.171 19.5003 13.9801 19.4213 13.8394 19.2806C13.6986 19.1398 13.6196 18.949 13.6196 18.7499C13.6196 18.5509 13.6986 18.36 13.8394 18.2193L19.3097 12.7499H4.62C4.42108 12.7499 4.23032 12.6709 4.08967 12.5303C3.94901 12.3896 3.87 12.1988 3.87 11.9999C3.87 11.801 3.94901 11.6103 4.08967 11.4696C4.23032 11.3289 4.42108 11.2499 4.62 11.2499H19.3097L13.8394 5.78055C13.6986 5.63982 13.6196 5.44895 13.6196 5.24993C13.6196 5.05091 13.6986 4.86003 13.8394 4.7193C13.9801 4.57857 14.171 4.49951 14.37 4.49951C14.569 4.49951 14.7599 4.57857 14.9006 4.7193L21.6506 11.4693C21.7204 11.539 21.7757 11.6217 21.8134 11.7127C21.8512 11.8038 21.8706 11.9014 21.8706 11.9999C21.8706 12.0985 21.8512 12.1961 21.8134 12.2871C21.7757 12.3782 21.7204 12.4609 21.6506 12.5306Z"
                                fill="white"/>
                        </svg>
                    </button>
                </motion.div>
                <motion.div
                    variants={imageVariants}
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}

                >
                    <img src={signUpImg} alt="patients"
                         style={{
                             borderBottomLeftRadius: '24px',
                             borderBottomRightRadius: '24px',
                             width: '100%'
                         }}
                         className={classes.revolution__img}
                    />
                </motion.div>
            </div>
        </div>
    );
};

export default JoinRevolution;