import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { ConfigProvider } from 'antd'
import { ThemeProvider, createTheme } from '@mui/material'
import './i18n'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const antTheme = {
  token: {fontFamily: "'Inter', sans-serif"},
  components: {
    Button: {
      colorPrimary: '#007DFA',
      controlHeight: 42,
      fontSize: 16,
      borderRadius: 8,
    },
    Input: {
      controlHeight: 44,
      fontSize: 16,
      colorTextPlaceholder: '#667085',
      borderRadius: 8,
    },
    InputNumber: {
      controlHeight: 44,
      fontSize: 16,
      colorTextPlaceholder: '#667085',
      borderRadius: 8,
    },
    Select: {
      controlHeight: 44,
      fontSize: 16,
      colorTextPlaceholder: '#667085',
      borderRadius: 8,
    },
    DatePicker: {
      controlHeight: 44,
      fontSize: 16,
      colorTextPlaceholder: '#667085',
      borderRadius: 8,
    },
  },
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007DFA',
    },
    // secondary: {
    //   main: '#E0C2FF',
    //   light: '#F5EBFF',
    //   // dark: will be calculated from palette.secondary.main,
    //   contrastText: '#47008F',
    // },
  },
  // shape: {
  //   borderRadius: 8
  // },
})

root.render(
  <React.StrictMode>
    <ConfigProvider theme={antTheme}>
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}> 
          <BrowserRouter>
            <App />
          </BrowserRouter>     
        </Provider>
      </ThemeProvider>
    </ConfigProvider>
  </React.StrictMode>
)

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.ready.then(registration => {
//     registration?.unregister()
//     if (caches) {
//       caches.keys().then(async (names) => {
//         await Promise.all(names.map(name => caches.delete(name)));
//       }).then(() => window.location.reload())  
//     }
//   })
// }

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name)
    })
  })
  console.log('Complete Cache Cleared')
}

if ('caches' in window){
  caches.keys().then((names) => {
    names.forEach(name => {
      caches.delete(name)
    })
  })
}

clearCacheData()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
