/* eslint-disable */
import {useTranslation} from 'react-i18next'
import {Button, Divider, Input, message} from 'antd'
import classes from './Chat.module.css'
import {ReactComponent as CloseIcon} from './../../../img/icons/close.svg'
import {ReactComponent as BalanceIcon} from './../../../img/icons/balance.svg'
import {ReactComponent as SendIcon} from './../../../img/icons/send.svg'
import {ReactComponent as ChatRobot} from './../../../img/dreamInterpreter/chatRobot.svg'
import Message from './Message/Message'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {
    SendMessageThunk,
    StopChatThunk,
    selectCurrentChat,
    setNewUserMessage,
    setCurrentChatStage, selectCurrentChatStage, StartChatThunk
} from '../../../store/dreamInterpreterReducer'
import StartScreen from './StartScreen/StartScreen'
import React, {useLayoutEffect, useRef, useState} from 'react'
import dayjs from 'dayjs'
import PopoverConfirm from '../../common/PopoverConfirm/PopoverConfirm'
import {selectBillingSettings} from '../../../store/currentUserReducer'

const Chat: React.FC<ChatPropTypes> = ({onClose}) => {
    const {t} = useTranslation(['commonComponents', 'app'])
    const dispatch = useAppDispatch()
    const currentChat = useAppSelector(selectCurrentChat)
    const billingSettings = useAppSelector(selectBillingSettings)
    const divRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [messageText, setMessageText] = useState('')
    const currentChatStage = useAppSelector(selectCurrentChatStage)
    const [prompts, setPrompts] = useState<string[]>([])

    useLayoutEffect(() => {
        if (divRef?.current) {
            // @ts-ignore
            divRef.current.scrollTop = divRef?.current?.scrollHeight
        }
    }, [currentChat])

    const startChat = async (withPrompts: boolean, newChat: boolean) => {
        setIsLoading(true)
        if (newChat) {
            setIsLoading(true)
            if(currentChat?.id!){
                dispatch(StopChatThunk(currentChat?.id!))
                    .then(() => setIsLoading(false))
                    .then(() => dispatch(setCurrentChatStage('Prompt pick')))
            }
            else{
                setIsLoading(false)
                dispatch(setCurrentChatStage('Prompt pick'))
            }
        }
        else{
            if (withPrompts) {
                if (!prompts.length) {
                    setIsLoading(false)
                    message.warning('Please pick prompts before starting the chat')
                } else {
                    setIsSending(true)
                    dispatch(setCurrentChatStage('Started'))
                    dispatch(StartChatThunk(prompts.map((item: string) => item).join(",")))
                        .then(() => setIsLoading(false))
                        // .then(() => dispatch(setCurrentChatStage('Started')))
                        .then(() => setPrompts([]))
                        .then(() => setIsSending(false))
                }

            } else if (!withPrompts && currentChatStage === 'Pre chat') {
                setIsLoading(false)
                dispatch(setCurrentChatStage('Prompt pick'))
            } else if (!withPrompts && currentChatStage === 'Prompt pick') {
                if (currentChat?.id) {
                    await dispatch(StopChatThunk(currentChat?.id))
                        .then(() => setPrompts([]))
                }
                setIsSending(true)
                dispatch(setCurrentChatStage('Started'))
                return dispatch(StartChatThunk('ai'))
                    // .then(() => dispatch(setCurrentChatStage('Started')))
                    .then(() => setIsLoading(false))
                    .then(() => setPrompts([]))
                    .then(() => setIsSending(false))
            }
        }

    }

    const stopChat = () => {
        setIsLoading(true)
        onClose()
        if(currentChat?.id!){
            dispatch(StopChatThunk(currentChat?.id!))
                .then(() => setIsLoading(false))
                .then(() => dispatch(setCurrentChatStage('Pre chat')))
        }
        else{
            setIsLoading(false)
            dispatch(setCurrentChatStage('Pre chat'))
        }

    }

    const sendMessage = () => {
        if (!messageText?.length) {
            return
        }
        setIsSending(true)
        dispatch(setNewUserMessage(messageText))
        setMessageText('')
        dispatch(SendMessageThunk({freeChatId: currentChat!.id, message: messageText}))
            .then(() => setIsSending(false))
    }

    if (!currentChat?.id && !isLoading && currentChatStage === 'Pre chat') {
        return <StartScreen onClose={onClose} startChat={() => startChat(false, false)}/>
    }
    else if(currentChat?.id){
        dispatch(setCurrentChatStage('Started'))
    }

    const onAddPrompt = (propmpt: string) => {
        if (prompts.includes(propmpt)) {
            setPrompts(prompts.filter((s: string) => s !== propmpt))
        } else {
            setPrompts([...prompts, propmpt])
        }

    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                {
                    currentChatStage === 'Started'
                    &&
                    <div className={classes.balance}>
                        <BalanceIcon/>
                        {t('count.credit_plural', {
                            count: currentChat?.user?.billing_info?.credits_balance ? currentChat?.user?.billing_info?.credits_balance : billingSettings?.credits_balance,
                            ns: 'app'
                        })}
                    </div>
                }
                {
                    currentChatStage === 'Prompt pick'
                    &&
                    <div className={classes.title}>
                        {t('dreamInterpreter.startScreen.title')}
                    </div>
                }
                <PopoverConfirm
                    title={t('warnings.closingDialog', {ns: 'app'})}
                    onConfirm={stopChat}
                >
                    <CloseIcon className={classes.closeIcon}/>
                </PopoverConfirm>
            </div>

            <div className={currentChatStage !== 'Prompt pick' ? classes.chatContent : classes.chatContent_noPadding}
                 ref={divRef}>
                {
                        <>
                            <div>
                                <ChatRobot className={classes.chatRobotImg}/>
                            </div>
                            {
                                currentChatStage === 'Prompt pick'
                                    ?
                                    <div>
                                        <div className={classes.chatTextDescr}>
                                            <p>To explore dreams with researchers, choose one or more to collaborate with. If
                                                you&apos;d rather use a General AI approach for analysis, you can skip this
                                                step.</p>
                                        </div>
                                        <div className={classes.divider}></div>
                                        <div className={classes.promptButtons}>
                                            <div className={classes.promptButtons__inner}>
                                                <div className={classes.promptButtons__prompts}>
                                                    <div
                                                        onClick={() => onAddPrompt('freud')}
                                                        className={`${prompts.some((p: string) => p === 'freud') ? classes.promptButton + ' ' + classes.promptButton__selected : classes.promptButton}`}
                                                    >
                                                        Freud
                                                    </div>
                                                    <div
                                                        onClick={() => onAddPrompt('jung')}
                                                        className={`${prompts.some((p: string) => p === 'jung') ? classes.promptButton + ' ' + classes.promptButton__selected : classes.promptButton}`}
                                                    >
                                                        Jung
                                                    </div>
                                                    <div
                                                        onClick={() => onAddPrompt('adler')}
                                                        className={`${prompts.some((p: string) => p === 'adler') ? classes.promptButton + ' ' + classes.promptButton__selected : classes.promptButton}`}
                                                    >
                                                        Adler
                                                    </div>
                                                </div>
                                                <Button type={'primary'} onClick={() => startChat(true, false)}>
                                                    <SendIcon style={{marginTop: '3px'}}/>
                                                </Button>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => startChat(false, false)}
                                                    className={classes.promptButtons__skip}
                                                >
                                                    Skip
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {isLoading && !currentChat?.conversations?.length &&
                                            <Message
                                                text={''}
                                                time={dayjs().format()}
                                                typing
                                                incoming
                                            />
                                        }

                                        {currentChat?.conversations?.map((con, index) => (
                                            <React.Fragment key={con.id}>
                                                {index !== 0 && !dayjs.utc(currentChat?.conversations[index - 1]?.messageTime).isSame(dayjs.utc(con.messageTime), 'day') &&
                                                    <Divider>
                <span style={{fontSize: '14px', color: '#667085'}}>
                  {dayjs.utc(con.messageTime).isToday()
                      ? t('today', {ns: 'app'})
                      : dayjs.utc(con.messageTime).isYesterday()
                          ? t('Yesterday', {ns: 'app'})
                          : dayjs.utc(con.messageTime).format('DD.MM')
                  }
                </span>
                                                    </Divider>
                                                }
                                                {con.prompt_text &&
                                                    <Message
                                                        text={con.prompt_text}
                                                        time={con.messageTime}
                                                    />
                                                }
                                                {(!!con.completion_text || isSending) &&
                                                    <Message
                                                        incoming
                                                        title={con?.is_warning ? t('dreamInterpreter.chat.noCreditsMessage.title') : ''}
                                                        text={con?.is_warning ? t('dreamInterpreter.chat.noCreditsMessage.description') : con.completion_text || ''}
                                                        time={con.messageTime}
                                                        typing={!con.completion_text && isSending}
                                                        warning={con?.is_warning}
                                                    />
                                                }
                                            </React.Fragment>
                                        ))}
                                        <div className={classes.newMessageWrapper}>
                                            <Input.TextArea
                                                placeholder={t('dreamInterpreter.chat.messagePlaceholder')}
                                                onChange={(e) => setMessageText(e.target.value)}
                                                value={messageText}
                                                onPressEnter={e => {
                                                    e.preventDefault()
                                                    sendMessage()
                                                }}
                                                rows={2}
                                            />
                                            <Button type='primary' disabled={isLoading || isSending} onClick={sendMessage}>
                                                <SendIcon style={{marginTop: '3px'}}/>
                                            </Button>
                                        </div>
                                        <div className={classes.controlBtnWrapper}>
                                            <PopoverConfirm
                                                title={t('warnings.closingDialog', {ns: 'app'})}
                                                onConfirm={stopChat}
                                            >
                                                <Button className={classes.stopChatBtn} disabled={isLoading}>
                                                    {t('dreamInterpreter.chat.stopChatBtn')}
                                                </Button>
                                            </PopoverConfirm>
                                            <PopoverConfirm
                                                title={t('warnings.closingDialog', {ns: 'app'})}
                                                onConfirm={() => startChat(false, true)}
                                            >
                                                <Button className={classes.newChatBtn} disabled={isLoading}>
                                                    {t('dreamInterpreter.chat.newChatBtn')}
                                                </Button>
                                            </PopoverConfirm>
                                        </div>
                                    </>
                            }

                        </>
                }


            </div>


        </div>
    )
}

interface ChatPropTypes {
    onClose: () => void
}

export default Chat
