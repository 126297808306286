import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import classes from './Message.module.css'
import { ReactComponent as RobotAvatar } from './../../../../img/dreamInterpreter/robotAvatar.svg'
import ReactMarkdown from 'react-markdown'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(utc)

const Message:React.FC<MessagePropTypes> = ({incoming, warning, typing, title, text, time}) => {
  const { t } = useTranslation(['commonComponents', 'app'])

  return (
    <div className={`${classes.wrapper} ${incoming ? classes.incoming : ''}`}>
      {incoming && <RobotAvatar style={{width: '30px'}}/>}
      <div className={classes.messageContent}>
        <div className={classes.authorWrapper}>
          <div className={classes.author}>
            {t(incoming ? 'dreamInterpreter.chat.authorChat' : 'dreamInterpreter.chat.authorYou')}
          </div>
          <div className={classes.date}>
            {dayjs.utc(time).isToday() 
              ? t('today', {ns: 'app'}) + ' ' + dayjs.utc(time).format('HH:mm') 
              : dayjs.utc(time).isYesterday()
                ? t('Yesterday', {ns: 'app'}) + ' ' + dayjs.utc(time).format('HH:mm')
                : dayjs.utc(time).format('DD.MM  HH:mm')
            }
          </div>
        </div>
        <div
          className={`
            ${classes.message}
            ${incoming ? classes.incoming : ''}
            ${warning ? classes.warning : ''}
          `}
        >
          {!!title?.length &&
            <div style={{fontWeight: 600, marginBottom: '5px'}}>
              {title}
            </div>
          }
          {typing ? <Typing /> : <ReactMarkdown>{text.replace('<input>', '')}</ReactMarkdown>}
        </div>
      </div>
    </div>
  )
}

const Typing = () => {
  return (
    <div className={classes.typing}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

interface MessagePropTypes {
  incoming?: boolean
  warning?: boolean
  typing?: boolean
  title?: string
  text: string
  time: string
}

export default Message
