import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { selectReportSettings } from '../../../../../store/currentUserReducer'
import { selectSelectedPersonData } from '../../../../../store/personReducer'
import { GetFormatReferencesThunk, GetLanguageReferencesThunk, GetStyleReferencesThunk, selectFormatReferences, selectLanguageReferences, selectStyleReferences } from '../../../../../store/referenceReducer'
import { Checkbox, Select } from 'antd'
import { NewRequestDataType, ReportCDType, ReportTemplateType } from '../../../../../types/requestTypes'
import classes from './AdditionalInformation.module.css'

const AdditionalInformation: React.FC<AdditionalInformationPropTypes> = ({
  requestData,
  settingTemplate,
  setRequestData,
  disabled,
  isGenerating,
  copySettings,
  setCopySettings,
  activeReportType,
}) => {
  const dispatch = useAppDispatch()
  const styleReferences = useAppSelector(selectStyleReferences)
  const languageReferences = useAppSelector(selectLanguageReferences)
  const formatReferences = useAppSelector(selectFormatReferences)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const userReportSettings = useAppSelector(selectReportSettings)

  useEffect(() => {
    if (!styleReferences || !languageReferences || !formatReferences) {
      Promise.all([
        ...(styleReferences === null ? [dispatch(GetStyleReferencesThunk())] : []),
        ...(languageReferences === null ? [dispatch(GetLanguageReferencesThunk())] : []),
        ...(formatReferences === null ? [dispatch(GetFormatReferencesThunk())] : []),
      ])
    }
    // eslint-disable-next-line
  }, [dispatch, styleReferences, languageReferences, formatReferences])

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.label}>Output Language</div>
        <Select
          placeholder='Select language'
          options={languageReferences?.map(ref => ({label: ref.language, value: ref.lang_cd}))}
          value={requestData?.p_lang_cd}
          onChange={(val) => setRequestData({...requestData!, p_lang_cd: val})}
          disabled={
            (settingTemplate === 'P' && !!selectedPersonData?.lang_cd)
            || (settingTemplate === 'U' && !!userReportSettings?.lang_cd)
            || disabled
          }
          style={{maxWidth: '400px'}}
        />
        <div className={classes.label}>Report Styles</div>
        <Select
          placeholder='Select styles'
          options={styleReferences?.map(ref => ({label: ref.style, value: ref.style}))}
          value={requestData?.report_style}
          onChange={(val) => setRequestData({...requestData!, report_style: val})}
          disabled={
            (settingTemplate === 'P' && !!selectedPersonData?.report_style)
            || (settingTemplate === 'U' && !!userReportSettings?.report_style)
            || disabled
          }
          style={{maxWidth: '400px'}}
        />
        <div className={classes.label}>Report Format</div>
        <Select
          placeholder='Select format'
          options={formatReferences?.map(ref => ({label: ref.format, value: ref.format}))}
          value={requestData?.report_format}
          onChange={(val) => setRequestData({...requestData!, report_format: val})}
          disabled={
            (settingTemplate === 'P' && !!selectedPersonData?.report_format)
            || (settingTemplate === 'U' && !!userReportSettings?.report_format)
            || disabled
          }
          style={{maxWidth: '400px'}}
        />
      </div>
      {(settingTemplate === 'R' || settingTemplate === 'U') && activeReportType === 'main' &&
        <div className={classes.saveSettingsTemplate}>
          <Checkbox
            checked={copySettings.person}
            onChange={e => setCopySettings({...copySettings, person: e.target.checked})}
            disabled={!!isGenerating.length}
          />
          Remember these settings for the patient.
        </div>
      }

      {/* {(settingTemplate === 'R' || settingTemplate === 'P') && activeReportType === 'main' &&
        <div className={classes.saveSettingsTemplate} style={{marginTop: '15px'}}>
          <Checkbox
            checked={copySettings.user}
            onChange={e => setCopySettings({...copySettings, user: e.target.checked})}
            disabled={!!isGenerating.length}
          />
          Remember these settings as my personal.
        </div>
      } */}
    </>
  )
}

interface AdditionalInformationPropTypes {
  setRequestData: (requestData: NewRequestDataType | null) => void
  requestData: NewRequestDataType | null
  settingTemplate: ReportTemplateType
  disabled: boolean
  isGenerating: ReportCDType[]
  copySettings: {user: boolean, person: boolean}
  setCopySettings: (val: {user: boolean, person: boolean}) => void
  activeReportType: ReportCDType
}

export default AdditionalInformation
