import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import appEn from './localization/en/app.json'
import commonComponentsEn from './localization/en/commonComponents.json'
import formsEn from './localization/en/forms.json'
import pagesEn from './localization/en/pages.json'

const resources = {
  en: {
    "app": appEn,
    "commonComponents": commonComponentsEn,
    "forms": formsEn,
    "pages": pagesEn
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
