import { ReactNode } from 'react'
import classes from './BillingInfoCard.module.scss'

interface BillingInfoCardPropsType {
  title: string | ReactNode
  cardValue: number
  icon?: ReactNode
}

const BillingInfoCard = ({title, cardValue, icon} : BillingInfoCardPropsType) => {
  return (
    <div className={classes.inner}>
      <p className={classes.title}>
        {icon && icon} {title}
      </p>
      <p className={classes.cardValue}>
        {cardValue}
      </p>
    </div>
  )
}

export default BillingInfoCard
