/* eslint-disable */
import React from 'react'
import TermsAndPrivacyInner from "../TermsAndPrivacyInner/TermsAndPrivacyInner"
import commonClasses from "../TermsAndPrivacyInner/TermsAndPrivacyCommonClasses.module.scss"


const Terms = () => {
    return (
        <TermsAndPrivacyInner
            title={'Terms of Service'}
            subtitle={'Welcome to CaseInsight.Net!'}
        >
            <TermsComponent/>
        </TermsAndPrivacyInner>
    )
}


const TermsComponent = () => {
    return (
        <div className={`${commonClasses.container}`}>
            <div>
                <p className={commonClasses.commonText}>These terms of service ("Terms") outline the rules and
                    regulations
                    for the use of CaseInsight web application, located at CaseInsight.Net.</p>
                <p className={commonClasses.commonText}>By accessing this web application, we assume you accept these
                    Terms
                    in full. Do not continue to use CaseInsight.Net if you do not accept all of the Terms stated on this
                    page.</p>
                <p className={commonClasses.commonText}>We employ the use of cookies. By accessing CaseInsight.Net, you
                    agreed to use cookies in agreement with the CaseInsight.Net Privacy Policy.</p>
                <p className={commonClasses.commonText}>
                    The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                    Notice
                    and all Agreements: "Client", "You" and "Your" refers to you, the person log on this web application
                    and
                    compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
                    refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All
                    terms
                    refer to the offer, acceptance and consideration of payment necessary to undertake the process of
                    our
                    assistance to the Client in the most appropriate manner for the express purpose of meeting the
                    Client's
                    needs in respect of provision of the Company's stated services, in accordance with and subject to,
                    prevailing law of Austria. Any use of the above terminology or other words in the singular, plural,
                    capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to
                    same.
                </p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>Cookies</h3>
                <p className={commonClasses.commonText}>We employ the use of cookies. By accessing CaseInsight.Net, you
                    agreed to use cookies in agreement with the CaseInsight.Net! Privacy Policy.</p>
            </div>
            <div>
                <h3 className={commonClasses.commonTitle}>License</h3>
                <p className={commonClasses.commonText}>Unless otherwise stated, CaseInsight.Net and/or its licensors
                    own the intellectual property rights for all material on CaseInsight.Net. All intellectual property
                    rights are reserved. You may access this from CaseInsight.Net for your own personal use subjected to
                    restrictions set in these terms and conditions.</p>
                <div>
                    <h4>You may:</h4>
                    <p className={commonClasses.commonText}>- Reformulate produced content for your use</p>
                </div>
                <div>
                    <h4>You must not:</h4>
                    <p className={commonClasses.commonText}>- Reproduce, duplicate or copy direct material from
                        CaseInsight.Net</p>
                    <p className={commonClasses.commonText}>- Redistribute direct content from CaseInsight.Net</p>
                </div>
                <div>
                    <h4>User Content</h4>
                    <p className={commonClasses.commonText}>We do not own any data, information or material ("Content")
                        that you submit on the web application in the course of using the Service. You shall have sole
                        responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and
                        intellectual property ownership or right to use of all submitted Content. We may monitor Content
                        on the web application submitted or created using our services by you. Unless specifically
                        permitted by you, your use of the web application does not grant us the license to use,
                        reproduce, adapt, modify, publish or distribute the Content created by you or stored in your
                        user account for commercial, marketing or any similar purpose. But you grant us permission to
                        access, copy, distribute, store, transmit, reformat, display and perform the Content of your
                        user account solely as required for the purpose of providing the services to you. Without
                        limiting any of those representations or warranties, we have the right, though not the
                        obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable
                        opinion, violates any of our policies or is in any way harmful or objectionable.</p>
                </div>
                <div>
                    <h3 className={commonClasses.commonTitle}>Termination</h3>
                    <p className={commonClasses.commonText}>We may terminate or suspend access to our Service
                        immediately, without prior notice or liability, for any reason whatsoever, including without
                        limitation if you breach the Terms.</p>
                    <p className={commonClasses.commonText}>All provisions of the Terms which by their nature should
                        survive termination shall survive termination, including, without limitation, ownership
                        provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                    <div style={{
                        marginBottom: '75px'
                    }}>
                        <h4>Governing Law</h4>
                        <p className={commonClasses.commonText}>These Terms shall be governed and construed in
                            accordance with the laws of Austria, without regard to its conflict of law provisions.</p>
                        <p className={commonClasses.commonText}>Our failure to enforce any right or provision of these
                            Terms will not be considered a waiver of those rights. If any provision of these Terms is
                            held to be invalid or unenforceable by a court, the remaining provisions of these Terms will
                            remain in effect. These Terms constitute the entire agreement between us regarding our
                            Service, and supersede and replace any prior agreements we might have between us regarding
                            the Service.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Terms