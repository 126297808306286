/* eslint-disable */
import React from 'react';
import classes from './InfoBadge.module.scss'

interface InfoBadgeProps {
    text: string
}

const InfoBadge = ({text} : InfoBadgeProps) => {
    return (
        <div className={classes.badge}>
            <div className={classes.badge__dot}></div>
            <span>{text}</span>
        </div>
    );
};

export default InfoBadge;