/* eslint-disable */
import { Divider } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import classes from './ReportDetails.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectRequestData, setRequestData } from '../../../store/requestReducer'
import { ReportCDType } from '../../../types/requestTypes'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from "react-markdown"

const ReportDetails = () => {
  const { t } = useTranslation(['pages'])
  dayjs.extend(utc)
  const dispatch = useAppDispatch()
  const requestData = useAppSelector(selectRequestData)
  return (
    <div style={{marginBottom: '40px'}}>
      <div className={classes.reportTitle}>
        <GoBackLink
          onClick={() => dispatch(setRequestData(null))}
          text={t('patientDetails.reports.details.backBtn')}
          style={{marginBottom: '10px'}}
        />
        {t('patientDetails.reports.details.title', {date: dayjs.utc(requestData?.request_end).format('DD.MM.YYYY HH:mm')})}
      </div>
      <Divider />
      <ReportContent />
    </div>
  )
}

export const ReportContent = () => {
  const { t } = useTranslation(['app'])
  const requestData = useAppSelector(selectRequestData)

  const getReportContent = (reportType: ReportCDType) => {
    return requestData?.request_reports.find(r => r.rep_cd === reportType)?.chat_gpt_completion
  }

  return (
    <div className={classes.reportContent}>
      {!!getReportContent('main') &&
        <div>
          <h3>{t('reports.title.general')}</h3>
          <ReactMarkdown>{getReportContent('main')}</ReactMarkdown>
        </div>
      }
      {!!getReportContent('conflicts') &&
        <div>
          <h3>{t('reports.title.conflicts')}</h3>
          <ReactMarkdown>{getReportContent('conflicts')}</ReactMarkdown>
        </div>
      }
      {!!getReportContent('diagnoses') &&
        <div>
          <h3>{t('reports.title.diagnoses')}</h3>
          <ReactMarkdown>{getReportContent('diagnoses')}</ReactMarkdown>
        </div>
      }
      {!!getReportContent('kernberg') &&
        <div>
          <h3>{t('reports.title.diagnosesKernberg')}</h3>
        <ReactMarkdown>{getReportContent('kernberg')}</ReactMarkdown>
        </div>
      }
      {!!getReportContent('literature') &&
        <div>
          <h3>{t('reports.title.literature')}</h3>
        <ReactMarkdown>{getReportContent('literature')}</ReactMarkdown>
        </div>
      }
      {!!getReportContent('keywords') &&
        <div>
          <h3>{t('reports.title.keywords')}</h3>
        <ReactMarkdown>{getReportContent('keywords')}</ReactMarkdown>
        </div>
      }
    </div>
  )
}

export default ReportDetails
