import { Input } from 'antd'
import classes from './InstanceForm.module.css'
import PopoverConfirm from '../../common/PopoverConfirm/PopoverConfirm'
import { ReactComponent as DeleteIcon } from './../../../img/icons/delete.svg'
import { useTranslation } from 'react-i18next'

const TitleDescriptionFields: React.FC<TitleDescriptionFieldsPropTypes> = ({
  title,
  titleLabel,
  onTitleChange,
  description,
  onDescriptionChange,
  onDelete,
}) => {
  const { t } = useTranslation(['forms'])

  return (
    <div>
      <div className={classes.formBlock}>
        <div className={classes.label}>
          {t('fields.titleDescription.titleLabel', {item: titleLabel})}
        </div>
        <div className={classes.inputWrapper}>
          <Input
            placeholder={t('fields.titleDescription.titleLabel', {item: titleLabel}).charAt(0).toUpperCase() + t('fields.titleDescription.titleLabel', {item: titleLabel}).slice(1)}
            value={title}
            onChange={e => onTitleChange(e.target.value)}
          />
          {!!onDelete &&
            <PopoverConfirm
              title={t('fields.titleDescription.deleteConfirm')}
              onConfirm={() => onDelete()}
            >
              <DeleteIcon className={classes.deleteIcon}/>
            </PopoverConfirm>
          }
        </div>
      </div>

      <div className={classes.formBlock}>
        <div className={classes.label}>
          {t('fields.titleDescription.descriptionLabel')}
        </div>
        <div className={classes.inputWrapper}>
          <Input.TextArea
            placeholder={t('fields.titleDescription.descriptionPlaceholder')}
            rows={3}
            autoSize
            value={description}
            onChange={e => onDescriptionChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

interface TitleDescriptionFieldsPropTypes {
  title: string
  titleLabel: string
  onTitleChange: (val: string) => void
  description: string
  onDescriptionChange: (val: string) => void
  onDelete?: () => void
}

export default TitleDescriptionFields
