/* eslint-disable */
import {useState} from 'react'
import { Resizable } from 're-resizable'
import { ReactComponent as DreamInterpreterIcon } from './../../img/icons/dreamInterpreter.svg'
import classes from './DreamInterpreter.module.css'
import Chat from './Chat/Chat'
import { selectChatSettings, setChatSettings } from '../../store/currentUserReducer'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {selectCurrentChatStage} from "../../store/dreamInterpreterReducer";

const DreamInterpreter = () => {
  const dispatch = useAppDispatch()
  const [isChatOpen, setIsChatOpen] = useState(false)
  const chatSize = useAppSelector(selectChatSettings)
  const currentChatStage = useAppSelector(selectCurrentChatStage)

  return (
    <>
      {isChatOpen ? (
        <Resizable
          className={classes.chatWindow}
          defaultSize={{
            width: chatSize.width,
            height: chatSize.height,
            // height: 'fit-content'
          }}
          minHeight={currentChatStage === 'Prompt pick' ? 600 : 256}
          maxHeight={756}
          minWidth={150}
          maxWidth={900}
          onResizeStop={(_:any, __:any, ___:any, d) => {
            dispatch(setChatSettings({
              width: chatSize.width + d.width,
              height: chatSize.height + d.height,
            }))
          }}
        >
          <Chat onClose={() => setIsChatOpen(false)}/>
        </Resizable>
      ) : (
        <DreamInterpreterIcon
          onClick={() => {setIsChatOpen(true)}}
          className={classes.chatIcon}
        />
      )}
    </>
  )
}

export default DreamInterpreter
