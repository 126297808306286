import { Collapse } from 'antd'
import React, { useState } from 'react'
import { ReactComponent as MinusOutlined } from './../../img/icons/faq_panel_minus.svg'
import { ReactComponent as PlusOutlined } from './../../img/icons/faq_panel_plus.svg'
import { ReactComponent as ArrowListMarker } from './../../img/icons/arrow-list-marker.svg'
import newPatient from '../../img/faq/1.1.png'
import changingPatient from '../../img/faq/1.2.png'
import patientActivation from '../../img/faq/1.3.png'
import createNewSession from '../../img/faq/1.4.png'
import modifyData from '../../img/faq/1.5.png'
import viewSessions from '../../img/faq/1.6.png'
import creatingReports from '../../img/faq/1.7.png'
import viewingReports from '../../img/faq/1.8.png'
import changePersonalParameters from '../../img/faq/2.1.png'
import newInstance from '../../img/faq/3.1.png'
import createNewTopic from '../../img/faq/3.2.png'
import createNewSubtopic from '../../img/faq/3.3.png'
import classes from './Faq.module.scss'
import { useAppSelector } from '../../app/hooks'
import { selectIsAdmin } from '../../store/currentUserReducer'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

interface FaqItemType {
  title: string
  text?: string
  image: string
  maxWidth?: boolean
  points?: {mainText: string, text: string, subList?: string[]}[]
}

const Faq = () => {
  const { t } = useTranslation(['pages'])
  const [activeKey, setActiveKey] = useState<string | string[]>([])
  const isAdmin = useAppSelector(selectIsAdmin)

  const patientFaqItems: FaqItemType[] = [
    {
      title: t('faq.patients.add.title'),
      image: newPatient,
      points: [
        {
          mainText: t('faq.patients.add.step1.title'),
          text: t('faq.patients.add.step1.description')
        },
        {
          mainText: t('faq.patients.add.step2.title'),
          text: t('faq.patients.add.step2.description')
        },
        {
          mainText: t('faq.patients.add.step3.title'),
          text: t('faq.patients.add.step3.description')
        },
        {
          mainText: t('faq.patients.add.step4.title'),
          text: t('faq.patients.add.step4.description')
        },
        {
          mainText: t('faq.patients.add.step5.title'),
          text: t('faq.patients.add.step5.description')
        },
        {
          mainText: t('faq.patients.add.step6.title'),
          text: t('faq.patients.add.step6.description')
        },
      ]
    },
    {
      title: t('faq.patients.change.title'),
      image: changingPatient,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.change.step1.title'),
          text: t('faq.patients.change.step1.description')
        },
        {
          mainText: t('faq.patients.change.step2.title'),
          text: t('faq.patients.change.step2.description')
        },
        {
          mainText: t('faq.patients.change.step3.title'),
          text: t('faq.patients.change.step3.description')
        },
        {
          mainText: t('faq.patients.change.step4.title'),
          text: t('faq.patients.change.step4.description')
        },
        {
          mainText: t('faq.patients.change.step5.title'),
          text: t('faq.patients.change.step5.description')
        },
        {
          mainText: t('faq.patients.change.step6.title'),
          text: t('faq.patients.change.step6.description')
        },
      ]
    },
    {
      title: t('faq.patients.activate.title'),
      image: patientActivation,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.activate.step1.title'),
          text: t('faq.patients.activate.step1.description')
        },
        {
          mainText: t('faq.patients.activate.step2.title'),
          text: t('faq.patients.activate.step2.description')
        },
        {
          mainText: t('faq.patients.activate.3.title'),
          text: t('faq.patients.activate.step3.description')
        },
        {
          mainText: t('faq.patients.activate.step4.title'),
          text: t('faq.patients.activate.step4.description')
        },
      ]
    },
    {
      title: t('faq.patients.addSession.title'),
      image: createNewSession,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.addSession.step1.title'),
          text: t('faq.patients.addSession.step1.description')
        },
        {
          mainText: t('faq.patients.addSession.step2.title'),
          text: t('faq.patients.addSession.step2.description')
        },
        {
          mainText: t('faq.patients.addSession.step3.title'),
          text: t('faq.patients.addSession.step3.description')
        },
        {
          mainText: t('faq.patients.addSession.step4.title'),
          text: t('faq.patients.addSession.step4.description')
        },
        {
          mainText: t('faq.patients.addSession.step5.title'),
          text: t('faq.patients.addSession.step5.description')
        },
        {
          mainText: t('faq.patients.addSession.step6.title'),
          text: t('faq.patients.addSession.step6.description')
        },
        {
          mainText: t('faq.patients.addSession.step7.title'),
          text: t('faq.patients.addSession.step7.description')
        },
        {
          mainText: t('faq.patients.addSession.step8.title'),
          text: t('faq.patients.addSession.step8.description')
        },
      ]
    },
    {
      title: t('faq.patients.editSession.title'),
      image: modifyData,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.editSession.step1.title'),
          text: t('faq.patients.editSession.step1.description')
        },
        {
          mainText: t('faq.patients.editSession.step2.title'),
          text: t('faq.patients.editSession.step2.description')
        },
        {
          mainText: t('faq.patients.editSession.step3.title'),
          text: t('faq.patients.editSession.step3.description')
        },
        {
          mainText: t('faq.patients.editSession.step4.title'),
          text: t('faq.patients.editSession.step4.description')
        },
        {
          mainText: t('faq.patients.editSession.step5.title'),
          text: t('faq.patients.editSession.step5.description')
        },
        {
          mainText: t('faq.patients.editSession.step6.title'),
          text: t('faq.patients.editSession.step6.description')
        },
        {
          mainText: t('faq.patients.editSession.step7.title'),
          text: t('faq.patients.editSession.step7.description')
        },
      ]
    },
    {
      title: t('faq.patients.viewSession.title'),
      image: viewSessions,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.viewSession.step1.title'),
          text: t('faq.patients.viewSession.step1.description')
        },
        {
          mainText: t('faq.patients.viewSession.step2.title'),
          text: t('faq.patients.viewSession.step2.description')
        },
        {
          mainText: t('faq.patients.viewSession.step3.title'),
          text: t('faq.patients.viewSession.step3.description')
        },
        {
          mainText: t('faq.patients.viewSession.step4.title'),
          text: t('faq.patients.viewSession.step4.description')
        },
        {
          mainText: t('faq.patients.viewSession.step5.title'),
          text: t('faq.patients.viewSession.step5.description')
        },
        {
          mainText: t('faq.patients.viewSession.step6.title'),
          text: t('faq.patients.viewSession.step6.description')
        },
      ]
    },
    {
      title: t('faq.patients.addReport.title'),
      image: creatingReports,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.addReport.step1.title'),
          text: t('faq.patients.addReport.step1.description'),
        },
        {
          mainText: t('faq.patients.addReport.step2.title'),
          text: t('faq.patients.addReport.step2.description'),
        },
        {
          mainText: t('faq.patients.addReport.step3.title'),
          text: t('faq.patients.addReport.step3.description'),
        },
        {
          mainText: t('faq.patients.addReport.step4.title'),
          text: t('faq.patients.addReport.step4.description'),
          subList: [
            t('faq.patients.addReport.step4.subSteps.subStep1')
          ]
        },
        {
          mainText: t('faq.patients.addReport.step5.title'),
          text: t('faq.patients.addReport.step5.description'),
          subList: [
            t('faq.patients.addReport.step5.subSteps.subStep1'),
            t('faq.patients.addReport.step5.subSteps.subStep2'),
            t('faq.patients.addReport.step5.subSteps.subStep3'),
            t('faq.patients.addReport.step5.subSteps.subStep4'),
            t('faq.patients.addReport.step5.subSteps.subStep5')
          ]
        },
        {
          mainText: t('faq.patients.addReport.step6.title'),
          text: t('faq.patients.addReport.step6.description'),
        },
        {
          mainText: t('faq.patients.addReport.step7.title'),
          text: t('faq.patients.addReport.step7.description'),
          subList: [
            t('faq.patients.addReport.step7.subSteps.subStep1'),
            t('faq.patients.addReport.step7.subSteps.subStep2')
          ]
        },
        {
          mainText: t('faq.patients.addReport.step8.title'),
          text: t('faq.patients.addReport.step8.description'),
        },
        {
          mainText: t('faq.patients.addReport.step9.title'),
          text: t('faq.patients.addReport.step9.description'),
        },
        {
          mainText: t('faq.patients.addReport.step10.title'),
          text: t('faq.patients.addReport.step10.description'),
        },
      ]
    },
    {
      title: t('faq.patients.viewReport.title'),
      image: viewingReports,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.patients.viewReport.step1.title'),
          text: t('faq.patients.viewReport.step1.description'),
        },
        {
          mainText: t('faq.patients.viewReport.step2.title'),
          text: t('faq.patients.viewReport.step2.description'),
        },
        {
          mainText: t('faq.patients.viewReport.step3.title'),
          text: t('faq.patients.viewReport.step3.description'),
        },
        {
          mainText: t('faq.patients.viewReport.step4.title'),
          text: t('faq.patients.viewReport.step4.description'),
        },
        {
          mainText: t('faq.patients.viewReport.step5.title'),
          text: t('faq.patients.viewReport.step5.description'),
        },
        {
          mainText: t('faq.patients.viewReport.step6.title'),
          text: t('faq.patients.viewReport.step6.description'),
        },
      ]
    },
  ]

  const parametersFaqItems:FaqItemType[] = [
    {
      title: t('faq.parameters.add.title'),
      image: changePersonalParameters,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.parameters.add.step1.title'),
          text: t('faq.parameters.add.step1.description'),
        },
        {
          mainText: t('faq.parameters.add.step2.title'),
          text: t('faq.parameters.add.step2.description'),
        },
        {
          mainText: t('faq.parameters.add.step3.title'),
          text: t('faq.parameters.add.step3.description'),
        },
        {
          mainText: t('faq.parameters.add.step4.title'),
          text: t('faq.parameters.add.step4.description'),
        },
        {
          mainText: t('faq.parameters.add.step5.title'),
          text: t('faq.parameters.add.step5.description'),
        },
      ]
    },
  ]

  const instancesFaqItems:FaqItemType[] = [
    {
      title: t('faq.instances.add.title'),
      image: newInstance,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.instances.add.step1.title'),
          text: t('faq.instances.add.step1.description'),
        },
        {
          mainText: t('faq.instances.add.step2.title'),
          text: t('faq.instances.add.step2.description'),
        },
        {
          mainText: t('faq.instances.add.step3.title'),
          text: t('faq.instances.add.step3.description'),
        },
        {
          mainText: t('faq.instances.add.step4.title'),
          text: t('faq.instances.add.step4.description'),
        },
        {
          mainText: t('faq.instances.add.step5.title'),
          text: t('faq.instances.add.step5.description'),
        },
      ]
    },
    {
      title: t('faq.instances.addTopic.title'),
      image: createNewTopic,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.instances.addTopic.step1.title'),
          text: t('faq.instances.addTopic.step1.description'),
        },
        {
          mainText: t('faq.instances.addTopic.step2.title'),
          text: t('faq.instances.addTopic.step2.description'),
        },
        {
          mainText: t('faq.instances.addTopic.step3.title'),
          text: t('faq.instances.addTopic.step3.description'),
        },
        {
          mainText: t('faq.instances.addTopic.step4.title'),
          text: t('faq.instances.addTopic.step4.description'),
        },
        {
          mainText: t('faq.instances.addTopic.step5.title'),
          text: t('faq.instances.addTopic.step5.description'),
        },
        {
          mainText: t('faq.instances.addTopic.step6.title'),
          text: t('faq.instances.addTopic.step6.description'),
        },
        {
          mainText: t('faq.instances.addTopic.step7.title'),
          text: t('faq.instances.addTopic.step7.description'),
        },
      ]
    },
    {
      title: t('faq.instances.addSubtopic.title'),
      image: createNewSubtopic,
      maxWidth: true,
      points: [
        {
          mainText: t('faq.instances.addSubtopic.step1.title'),
          text: t('faq.instances.addSubtopic.step1.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step2.title'),
          text: t('faq.instances.addSubtopic.step2.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step3.title'),
          text: t('faq.instances.addSubtopic.step3.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step4.title'),
          text: t('faq.instances.addSubtopic.step4.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step5.title'),
          text: t('faq.instances.addSubtopic.step5.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step6.title'),
          text: t('faq.instances.addSubtopic.step6.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step7.title'),
          text: t('faq.instances.addSubtopic.step7.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step8.title'),
          text: t('faq.instances.addSubtopic.step8.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step9.title'),
          text: t('faq.instances.addSubtopic.step9.description'),
        },
        {
          mainText: t('faq.instances.addSubtopic.step10.title'),
          text: t('faq.instances.addSubtopic.step10.description'),
        },
      ]
    },
  ]

  return (
    <div>
      <h1 className='pageTitle'>
        {t('faq.title')}
      </h1>
      <h2 className='pageDescription'>
        {t('faq.description')}
      </h2>
      <InfoSection
        title='Patients:'
        tips={patientFaqItems}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      />
      <InfoSection
        title='Parameters:'
        tips={parametersFaqItems}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      />
      {isAdmin &&
        <InfoSection
          title='Instances:'
          tips={instancesFaqItems}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      }
    </div>
  )
}

const InfoSection: React.FC<InfoSectionPropTypes> = ({title, tips, activeKey, setActiveKey}) => {
  const genExtra = (key: string) => activeKey.includes(key)
    ? <MinusOutlined />
    : <PlusOutlined />

  return (
    <div className={classes.tip}>
      <h3>
        {title}
      </h3>
      <Collapse
        onChange={(key: string | string[]) => setActiveKey(key)}
        activeKey={activeKey}
        accordion
        style={{backgroundColor: 'white'}}
      >
        {tips.map((tip, index) => (
          <Panel
            header={tip.title}
            key={tip.title}
            extra={genExtra(index.toString())}
            showArrow={false}
            className={classes.tip__name}
          >
            {tip?.text &&
              <p className={classes.tip__text}>
                {tip.text}
              </p>
            }
            {tip?.points && 
              <ol>
                {tip.points.map(point => (
                  <div key={point.mainText}>
                    <li key={point.mainText} className={classes.bulletList}>
                      <ArrowListMarker />
                      <div>
                        <span>
                          {point.mainText}
                        </span>
                        {point.text}
                      </div>
                    </li>
                    {point?.subList &&
                      <ul>
                        {point?.subList.map(subListItem => (
                          <li key={subListItem}>
                            {subListItem}
                          </li>
                        ))}
                      </ul>
                    }
                  </div>
                ))}
              </ol>
            }
            <img
              className={classes.tip__image}
              src={tip.image}
              alt='new_patient'
              style={{
                maxWidth: tip.maxWidth ? '100%' : '646px',
                height: tip.maxWidth ? '450px' : '629px'
              }}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

interface InfoSectionPropTypes {
  title: string
  tips: FaqItemType[]
  activeKey: string | string[]
  setActiveKey: (key: string | string[]) => void
}

export default Faq
