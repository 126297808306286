/* eslint-disable */
import React, {useEffect, useState} from 'react';
import classes from "../SignIn/SignIn.module.css";
import {Button, Checkbox, Form, Input, message} from "antd";
import signInImage from "../../img/landingImg/List of Patients. Sessions1.png";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import SuccessModal from "../common/SuccessModal";
import {
    selectIsRequestLoading,
    selectIsSuccessModalOpened,
    setIsRequestLoading,
    setIsSuccessModalOpened,
    SignUpThunk
} from '../../store/currentUserReducer';
import { useNavigate } from 'react-router-dom';
import { SignUpDataType } from '../../types/currentUserTypes';
import logo from "../../img/logo.png"


const SignUp = () => {
    const {t} = useTranslation(['pages', 'forms'])
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(selectIsRequestLoading)
    const isModalOpened = useAppSelector(selectIsSuccessModalOpened)
    const navigate = useNavigate()
    const [checkedTerms, setCheckedTerms] = useState(false)

    useEffect(() => {
        dispatch(setIsSuccessModalOpened(false))
    }, [])

    const onFinish = (formData: SignUpDataType) => {
        if(checkedTerms){
            dispatch(setIsRequestLoading(true))
            dispatch(SignUpThunk(formData))
        }
        else{
            message.warning('You need to read and agree with Terms of Service and Privacy Policy before registering an account', 10)
        }

    }

    const onCheck = (value: boolean) => {
        setCheckedTerms(value)
    }

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.logo}>
                    <img src={logo} alt='logo' className={classes.logo}/>
                </div>
                <Form
                    name='signIn'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={classes.form}
                >
                    <h1 className={classes.signUpTitle}>
                        {t('auth.signUp.title')}
                    </h1>
                    <p className={classes.signUpText}> {t('auth.signUp.subTitle')}</p>
                    <div className={classes.label}>
                        {t('fields.firstName.label', {ns: 'forms'})}
                    </div>
                    <Form.Item
                        name='first_name'
                        rules={[
                            {required: true, message: t('fields.firstName.validationMessage', {ns: 'forms'})}
                        ]}
                    >
                        <Input placeholder={t('fields.firstName.placeholder', {ns: 'forms'})}/>
                    </Form.Item>

                    <div className={classes.label}>
                        {t('fields.lastName.label', {ns: 'forms'})}
                    </div>
                    <Form.Item
                        name='last_name'
                        rules={[
                            {required: true, message: t('fields.lastName.validationMessage', {ns: 'forms'})},
                        ]}
                    >
                        <Input placeholder={t('fields.lastName.placeholder', {ns: 'forms'})}/>
                    </Form.Item>

                    <div className={classes.label}>
                        {t('fields.emailUsername.label', {ns: 'forms'})}
                    </div>
                    <Form.Item
                        name='email'
                        rules={[
                            {required: true, message: t('fields.emailUsername.validationMessage', {ns: 'forms'})},
                            {type: 'email', message: t('fields.email.formatValidationMessage', {ns: 'forms'})},
                        ]}
                    >
                        <Input placeholder={t('fields.emailUsername.placeholder', {ns: 'forms'})}/>
                    </Form.Item>
                    <div className={classes.checkboxInner}>
                        <Checkbox onChange={(value) => onCheck(value.target.checked)}/>
                        <p>I agree with <a href="/terms" target={'_blank'}>Terms of Service</a> and <a href="/privacyPolicy" target={'_blank'}>Privacy Policy</a>.</p>
                    </div>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
                            {t('auth.signUp.signUpBtn')}
                        </Button>
                    </Form.Item>
                    <div className={classes.haveAcc}>
                        <p>{t('auth.signUp.haveAccText')}</p>
                        <span onClick={() => navigate('/sign-in')}>{t('auth.signUp.loginText')}</span>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '12px',
                        width: '100%',
                        justifyContent: 'center'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                            <path
                                d="M13 6.75C13.4142 6.75 13.75 6.41421 13.75 6C13.75 5.58579 13.4142 5.25 13 5.25V6.75ZM0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
                                fill="#667085"/>
                        </svg>
                        <p
                            style={{
                                margin: '0 0 0 10px',
                                color: '#667085',
                                fontSize: '16px',
                                cursor: "pointer"
                            }}
                            onClick={() => navigate('/')}
                        >Back</p>
                    </div>
                </Form>
                <div className={classes.signUpImage}>
                    <div>
                        <p>{t('auth.signUp.signUpDescription')}</p>
                    </div>
                    <img src={signInImage} alt=''/>
                </div>
            </div>
            {
                isModalOpened
                &&
                <SuccessModal title={'Thank you for registering!'} withText={true} height={280}/>
            }
        </>

    )
};

export default SignUp;