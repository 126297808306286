import axios from 'axios'
import dayjs from 'dayjs'
import { tokenDataHelper } from './localStorageHelper'

declare global {
  interface Window {
    env: {
      API_DOMAIN_ADDR: string,
      GOOGLE_API_KEY: string,
    }
  }
}
const target = window.env.API_DOMAIN_ADDR

axios.interceptors.request.use(async (request:any) => {
  const {token, token_expiry_date} = tokenDataHelper.getTokenData()
  if (token && !request.url?.includes('/login/refresh')) {
    const isTokenExpired = dayjs().isAfter(dayjs(token_expiry_date))
    if (isTokenExpired) {
      await refreshToken(token)
        .then(newToken => request.headers['Authorization'] = newToken)
    } else {
      request.headers['Authorization'] = token
    }
  }
  return request
})

export const refreshToken = async(token: string) => {
  const response = await axios.get('/login/refresh', {
    headers: {
      'Authorization': token
    }
  })

  tokenDataHelper.setTokenData({
    token: response.data.token,
    token_expiry_date: response.data.token_expiry_date
  })

  // if (!!response.data?.user_logo_url) {
  // userDataHelper.setUserAvatar(response.data?.user_logo_url)
  // const event = new CustomEvent('storageAvatarRefresh', {detail: response.data?.user_logo_url})
  // window.dispatchEvent(event)
  // }
  window.dispatchEvent(new Event('storage'))
  return response.data.token
}

axios.defaults.baseURL = target

export default axios
