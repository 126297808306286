/* eslint-disable */
import {Form, Input, Button} from 'antd'
import React, {useState} from 'react'
import classes from './SignIn.module.css'
import {SignInDataType} from '../../types/currentUserTypes'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {SignInThunk, selectIsLoggedIn} from '../../store/currentUserReducer'
import signInImage from './../../img/sign-in.webp'
import {Navigate, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import CryptoJS from 'crypto-js'
import { selectPublicKey } from '../../store/userReducer'

const SignIn = () => {
    const {t} = useTranslation(['pages', 'forms'])
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector(selectIsLoggedIn)
    const CRYPTOR_KEY = useAppSelector(selectPublicKey)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const onFinish = (formData: SignInDataType) => {
        try {
            setIsLoading(true)
            const encryptedPassword = encrypt(formData.password, CRYPTOR_KEY)
            dispatch(SignInThunk({
                ...formData,
                password: encryptedPassword,
            })).then(() => {
                setIsLoading(false)
            }).catch((error) => {
                console.error('Login failed:', error)
                setIsLoading(false)
            });
        } catch (error) {
            setIsLoading(false)
        }
    }

    if (isLoggedIn) {
        return <Navigate to={'/patients'}/>
    }

    // Encrypt function using CryptoJS
    function encrypt(text: string, base64Key: string): string {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    return (
        <div className={classes.wrapper}>
            <Form
                name='signIn'
                onFinish={onFinish}
                autoComplete='off'
                validateTrigger='onBlur'
                className={classes.form}
            >
                <h1>
                    {t('auth.signIn.title')}
                </h1>
                <div className={classes.label}>
                    {t('fields.email.label', {ns: 'forms'})}
                </div>
                <Form.Item
                    name='email'
                    rules={[
                        {required: true, message: t('fields.email.validationMessage', {ns: 'forms'})},
                        {type: 'email', message: t('fields.email.formatValidationMessage', {ns: 'forms'})},
                    ]}
                >
                    <Input placeholder={t('fields.email.placeholder', {ns: 'forms'})}/>
                </Form.Item>

                <div className={classes.label}>
                    {t('fields.password.label', {ns: 'forms'})}
                </div>
                <Form.Item
                    name='password'
                    rules={[
                        {required: true, message: t('fields.password.validationMessage', {ns: 'forms'})},
                        {min: 4, message: t('fields.password.formatValidationMessage', {ns: 'forms', count: 4})}
                    ]}
                >
                    <Input.Password placeholder={t('fields.password.placeholder', {ns: 'forms'})}/>
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
                        {t('auth.signIn.signInBtn')}
                    </Button>
                </Form.Item>
                <div className={classes.haveAcc}>
                    <p>{t('auth.signIn.haveNoAccText')}</p>
                    <span onClick={() => navigate('/sign-up')}>{t('auth.signIn.loginText')}</span>
                </div>
                <div className={classes.haveAcc}>
                    <p>{t('auth.forgotPassword.title')}</p>
                    <span onClick={() => navigate('/restore-password')}>{t('auth.forgotPassword.text')}</span>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '12px',
                    width: '100%',
                    justifyContent: 'center'
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                        <path
                            d="M13 6.75C13.4142 6.75 13.75 6.41421 13.75 6C13.75 5.58579 13.4142 5.25 13 5.25V6.75ZM0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
                            fill="#667085"/>
                    </svg>
                    <p
                        style={{
                            margin: '0 0 0 10px',
                            color: '#667085',
                            fontSize: '16px',
                            cursor: "pointer"
                        }}
                        onClick={() => navigate('/')}
                    >Back</p>
                </div>
            </Form>
            <div className={classes.signInImage}>
                <img src={signInImage} alt=''/>
            </div>
        </div>
    )
}

export default SignIn
