import { Select, Tag } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

const TagMultiSelect: React.FC<TagMultiSelectPropTypes> = ({
  options,
  placeholder,
  getPopupContainer,
  tagColor,
  sortTags,
  ...props
}) => {
  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          backgroundColor: options.find(option => option.value === value)?.color,
          color: tagColor
        }}
      >
        {label}
      </Tag>
    )
  }

  return (
    <Select
      mode='multiple'
      tagRender={(props) => tagRender(props)}
      style={{ width: '100%' }}
      options={options.map(option => ({label: option.label, value: option.value}))}
      placeholder={placeholder}
      getPopupContainer={getPopupContainer}
      {...props}
    />
  )
}

interface TagMultiSelectPropTypes {
  options: {label: string, value: string, color?: string}[]
  placeholder: string
  getPopupContainer?: () => any
  sortTags?: () => void
  tagColor?: string
}

export default TagMultiSelect
