/* eslint-disable */
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import classes from './GenerateReport.module.css'
import { GetPersonByIdThunk, GetPersonReportSettingsThunk, selectSelectedPersonData, setPersonDetailsActiveTabKey, setSelectedPersonReportSettings } from '../../../store/personReducer'
import { useEffect, useState } from 'react'
import SessionInformation from './SessionInformation/SessionInformation'
import { NewRequestDataType, ReportCDType, ReportTemplateType } from '../../../types/requestTypes'
import ReportSettings from './ReportSettings/ReportSettings'
import { GetLastSessionsDateRangeThunk, clearSessionListRequestParams, selectSessionList, selectSessionListRequestParams } from '../../../store/sessionReducer'
import { selectInstanceList, setInstanceList } from '../../../store/instanceReducer'
import { GetUserReportSettingsThunk, selectReportSettings, setReportSettings } from '../../../store/currentUserReducer'
import dayjs from 'dayjs'
import { Button, Spin } from 'antd'
import Results from './Results/Results'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import { GoToWGKKReportThunk, setRequestData as setGlobalStateRequestData } from './../../../store/requestReducer'
import { uniqBy } from 'lodash'
import TooltipHint from '../../common/TooltipHint/TooltipHint'
import WGKKReport from './WGKKReport/WGKKReport'

const GenerateReport = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const userReportSettings = useAppSelector(selectReportSettings)
  const sessionListRequestParams = useAppSelector(selectSessionListRequestParams)
  const instanceList = useAppSelector(selectInstanceList)
  const sessionList = useAppSelector(selectSessionList)

  const [reportPage, setReportPage] = useState<'main' | 'wgkk'>('main')
  const [requestData, setRequestData] = useState<NewRequestDataType | null>(null)
  const [sessionSelectionType, setSessionSelectionType] = useState<'period' | 'manual'>('period')
  const [settingTemplate, setSettingTemplate] = useState<ReportTemplateType>('P')
  const [isInstanceDataLoading, setIsInstanceDataLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState<ReportCDType[]>([])
  const [resultsActiveTabKey, setResultsActiveTabKey] = useState<ReportCDType>('diagnoses')
  const [selectedReportTypes, setSelectedReportTypes] = useState<ReportCDType[]>(['main'])
  const [activeReportType, setActiveReportType] = useState<ReportCDType>('main')

  const [isPersonDataLoading, setIsPersonDataLoading] = useState(false)

  const getPersonId: () => number = () => {
    //pathname example: /patients/4/generate-report 
    return +pathname.substring(
      pathname.indexOf('/patients/') + '/patients/'.length, 
      pathname.lastIndexOf('/generate-report')
    )
  }

  useEffect(() => {   
    const id = getPersonId()
    if (selectedPersonData?.id !== id || !userReportSettings) {
      setIsPersonDataLoading(true)
      Promise.all([
        dispatch(GetPersonByIdThunk(id)),
        dispatch(GetPersonReportSettingsThunk(id)),
        dispatch(GetLastSessionsDateRangeThunk({personId: id})),
        ...(userReportSettings === null ? [dispatch(GetUserReportSettingsThunk())] : [])
      ])
        .then(() => setIsPersonDataLoading(false))
    } else if (selectedPersonData?.id === id
      && !Object.keys(requestData || {})?.length
      && !!sessionListRequestParams.person_data_request?.date_from
    ) {
      setRequestData({
        date_from: dayjs(sessionListRequestParams.person_data_request?.date_from).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        date_till: dayjs(sessionListRequestParams.person_data_request?.date_to).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        person_id: id,
        comment: '',
        p_lang_cd: selectedPersonData?.lang_cd,
        report_style: selectedPersonData?.report_style,
        report_format: selectedPersonData?.report_format,
        session_list: [],
        instance_list: selectedPersonData?.instance_list.map(instance => instance.id) || [],
        is_confirmed: false,
        report_param_mode: 'P'
      })
    }
    // eslint-disable-next-line
  }, [dispatch, selectedPersonData, pathname, requestData])

  useEffect(() => {
    return () => {
      dispatch(clearSessionListRequestParams())
      dispatch(setInstanceList(null))
      dispatch(setSelectedPersonReportSettings(null))
      dispatch(setReportSettings(null))
    }
  }, [dispatch])

  const getSelectedSessionsInstances = (updatedSessionList?: number[]) => {
    if (sessionSelectionType === 'period') {
      return instanceList || []
    } else {
      const sessions = updatedSessionList || requestData?.session_list
      const instances = sessionList?.filter(s => sessions?.includes(s.id))?.map(s => s.person_data_rec_list?.map(rec => rec.instance)).flat()
      return uniqBy(instances, instance => instance?.id)
    }
  }

  const goToWGKKReport = () => {
    dispatch(GoToWGKKReportThunk(
      {
        ...requestData!,
        instance_list: [],
        session_list: [],
      }
    )).then((r) => {
      if (r.type.includes('rejected')) {
        setReportPage('main')
      }
    })
    setReportPage('wgkk')
  }

  if (isPersonDataLoading || requestData === null) {
    return <Spin style={{width: '100%'}}/>
  }

  if (reportPage === 'wgkk') {
    return <WGKKReport
      goBack={() => setReportPage('main')}
      requestData={requestData}
    />
  }

  return (
    <div className={classes.wrapper}>

      <GoBackLink
        onClick={() => {
          dispatch(setGlobalStateRequestData(null))
          dispatch(setPersonDetailsActiveTabKey('sessions'))
          navigate(`/patients/edit/${getPersonId()}`)
        }}
        text='Go to list of sessions'
        style={{marginBottom: '10px'}}
      />

      <h1 className='pageTitle'>
        Create a new Report on {selectedPersonData?.name}

        <TooltipHint
          title={'Antrag auf Kostenübernahme Psychotherapy'}
          overlayInnerStyle={{width: '200px'}}
        >
          <Button
            onClick={goToWGKKReport}
            disabled={!!isGenerating?.length || selectedPersonData?.session_count === 0}
          >
            Antrag
          </Button>
        </TooltipHint>
      </h1>

      <SessionInformation
        sessionSelectionType={sessionSelectionType}
        setSessionSelectionType={(val: 'period' | 'manual') => setSessionSelectionType(val)}
        isInstanceDataLoading={isInstanceDataLoading}
        setIsInstanceDataLoading={(val: boolean) => setIsInstanceDataLoading(val)}
        isGenerating={isGenerating}
        requestData={requestData}
        setRequestData={setRequestData}
        getPersonId={getPersonId}
        settingTemplate={settingTemplate}
        setSelectedReportTypes={setSelectedReportTypes}
        selectedReportTypes={selectedReportTypes}
        getSelectedSessionsInstances={getSelectedSessionsInstances}
      />

      <ReportSettings
        requestData={requestData}
        setRequestData={setRequestData}
        settingTemplate={settingTemplate}
        sessionSelectionType={sessionSelectionType}
        isGenerating={isGenerating}
        setIsGenerating={setIsGenerating}
        isInstanceDataLoading={isInstanceDataLoading}
        getPersonId={getPersonId}
        setResultsActiveTabKey={setResultsActiveTabKey}
        selectedReportTypes={selectedReportTypes}
        setSelectedReportTypes={setSelectedReportTypes}
        setSettingTemplate={setSettingTemplate}
        activeReportType={activeReportType}
        setActiveReportType={setActiveReportType}
        getSelectedSessionsInstances={getSelectedSessionsInstances}
      />

      <Results
        getPersonId={getPersonId}
        setResultsActiveTabKey={setResultsActiveTabKey}
        resultsActiveTabKey={resultsActiveTabKey}
        isGenerating={isGenerating}
      />
    </div>
  )
}

export default GenerateReport
