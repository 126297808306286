/* eslint-disable */
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {userAPI} from '../app/api'
import {
  ChangeUserBalance,
  NewUserType,
  UserDataForSuperUserType,
  UserDefaultSettingsType,
  UserDetailsActiveTabKeyType,
  UserListForSuperUserRequestParamsType,
  UserListManualParamsType,
  UserListRequestParamsType,
  UserRoleType,
  UserStatusType,
  UserTokenType,
  UserType
} from './../types/userTypes'
import {AppStatusType} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './../types/appTypes'
import {setIsSuccessModalOpened, SignOutThunk} from './currentUserReducer'
import {pickBy, sortBy} from 'lodash'
import { TransactionsRequestParamsType, TransactionType, UserBillingSettingsType } from '../types/billingTypes'
import { GridSortDirection } from '@mui/x-data-grid-pro'

const userListRequestParamsInitialValue = {
  // pagination_request: {page: 1, size: 10, sort: [{field: 'first_name', direction: 'ASC' as 'ASC'}]},
  user_request: {active_status: 'ALL' as 'ALL', name: ''}
}

export const userListForSuperUserRequestParamsInitialValue = {
  pagination_request: {page: 1, size: 10, sort: [{field: 'first_name', direction: 'ASC' as 'ASC'}]}
}

export const userTransactionsRequestParamsInitialValue = {
  pagination_request: {page: 1, size: 10, sort: [{field: 'change_date', direction: 'DESC' as 'DESC'}]},
  transaction_request: {change_type: 'ChangeSum' as 'ChangeSum'}
}

const personListManualParamsInitialValue = {
  page: 1,
  size: 10,
  sort: [{field: 'first_name', sort: 'asc' as GridSortDirection}],
  activeStatus: 'ACTIVE' as UserStatusType,
  filters: {items: []},
  search: {fields: [''], query: ''}
}

interface InitialStateType {
  userList: null | UserType[]
  userListTotalCount: number
  userListRequestParams: UserListRequestParamsType
  userListManualParams: UserListManualParamsType

  selectedUserData: null | UserType
  userDetailsActiveTabKey: UserDetailsActiveTabKeyType
  roleList: null | UserRoleType[]
  userDefaultSettings: null | UserDefaultSettingsType

  userListForSuperUser: null | UserDataForSuperUserType[]
  userListForSuperUserTotalCount: number
  userListForSuperUserFilterCount: number
  userListForSuperUserRequestParams: UserListForSuperUserRequestParamsType

  userBillingSettings: UserBillingSettingsType
  userTransactions: null | TransactionType[]
  userTransactionsTotalCount: number
  userTransactionsFilterCount: number
  userTransactionsRequestParams: TransactionsRequestParamsType
  publicKey: string
  currentAppWidth: number
}

const initialState: InitialStateType = {
  userList: null,
  userListTotalCount: 0,
  userListRequestParams: userListRequestParamsInitialValue,
  userListManualParams: personListManualParamsInitialValue,

  selectedUserData: null,
  userDetailsActiveTabKey: 'general',
  roleList: null,
  userDefaultSettings: null,

  userListForSuperUser: null,
  userListForSuperUserTotalCount: 0,
  userListForSuperUserFilterCount: 0,
  userListForSuperUserRequestParams: userListForSuperUserRequestParamsInitialValue,

  userBillingSettings: {
    tariff_plan_name: "",
    tariff_plan_to_date: "",
    total_person: 0,
    available_person: 0,
    current_person_count: 0,
    total_credits: 0,
    credits_used: 0,
    credits_balance: 0,
    tariff_plan_info: {
      name: "",
      to_date: "",
      max_patients: 0,
      current_patients: 0,
      total_credits: 0,
      used_credits: 0,
      balance_credits: 0
    },
    additional_credit_info: {
      total_credits: 0,
      used_credits: 0,
      balance_credits: 0
    },
    additional_patient_info: {
      total_person: 0,
      available_person: 0
    }
  },
  userTransactions: null,
  userTransactionsTotalCount: 0,
  userTransactionsFilterCount: 0,
  userTransactionsRequestParams: userTransactionsRequestParamsInitialValue,
  publicKey: 'IdEM87OE4AAQtWPMK7LHQc/ZOCl70ZsDhWIFvbPGG6E=',
  currentAppWidth: 1440
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserList: (state, action: PayloadAction<UserType[] | null>) => {
      state.userList = action.payload
    },
    setUserListTotalCount: (state, action: PayloadAction<number>) => {
      state.userListTotalCount = action.payload
    },
    setUserListRequestParams: (state, action: PayloadAction<UserListRequestParamsType>) => {
      state.userListRequestParams = action.payload
    },
    setUserListManualParams: (state, action: PayloadAction<UserListManualParamsType>) => {
      state.userListManualParams = action.payload
    },
    setSelectedUserData: (state, action: PayloadAction<UserType | null>) => {
      state.selectedUserData = action.payload
    },
    setUserDetailsActiveTabKey: (state, action: PayloadAction<UserDetailsActiveTabKeyType>) => {
      state.userDetailsActiveTabKey = action.payload
    },
    setRoleList: (state, action: PayloadAction<null | UserRoleType[]>) => {
      state.roleList = action.payload
    },
    setUserListForSuperUser: (state, action: PayloadAction<null | UserDataForSuperUserType[]>) => {
      state.userListForSuperUser = action.payload
    },
    setUserListForSuperUserTotalCount: (state, action: PayloadAction<number>) => {
      state.userListForSuperUserTotalCount = action.payload
    },
    setUserListForSuperUserFilterCount: (state, action: PayloadAction<number>) => {
      state.userListForSuperUserFilterCount = action.payload
    },
    setUserListForSuperUserRequestParams: (state, action: PayloadAction<UserListForSuperUserRequestParamsType>) => {
      state.userListForSuperUserRequestParams = action.payload
    },
    setUserTransactions: (state, action: PayloadAction<null | TransactionType[]>) => {
      state.userTransactions = action.payload
    },
    setUserTransactionsTotalCount: (state, action: PayloadAction<number>) => {
      state.userTransactionsTotalCount = action.payload
    },
    setUserTransactionsFilterCount: (state, action: PayloadAction<number>) => {
      state.userTransactionsFilterCount = action.payload
    },
    setUserTransactionsRequestParams: (state, action: PayloadAction<TransactionsRequestParamsType>) => {
      state.userTransactionsRequestParams = action.payload
    },
    setCurrentAppWidth: (state, action: PayloadAction<number>) => {
      state.currentAppWidth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserListThunk.fulfilled, (state, action) => {
        state.userList = action.payload.users
        state.userListTotalCount = action.payload.total_count
      })
      .addCase(GetUserByIdThunk.fulfilled, (state, action) => {
        state.selectedUserData = action.payload
      })
      .addCase(EditUserThunk.fulfilled, (state, action) => {
        if (state.userListRequestParams.user_request.active_status === 'ALL') {
          state.userList = state.userList?.map(user => user.user_id === action.payload.user_id ? action.payload : user) || []
        } else {
          state.userList = state.userList?.filter(user => user.user_id !== action.payload.user_id) || []
        }
      })
      .addCase(DeleteUserThunk.fulfilled, (state, action) => {
        state.userList = state.userList?.filter(user => user.user_id !== action.payload) || []
        state.userListTotalCount -= 1
      })
      .addCase(GetUserRoleListThunk.fulfilled, (state, action) => {
        state.roleList = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.userList = null
        state.userListTotalCount = 0
        state.selectedUserData = null
        state.userListRequestParams = userListRequestParamsInitialValue
        state.userListForSuperUser = null
        state.userListForSuperUser = null
        state.userListForSuperUserTotalCount = 0
        state.userListForSuperUserFilterCount = 0
        state.userListForSuperUserRequestParams = userListForSuperUserRequestParamsInitialValue
        state.userTransactions = null
        state.userTransactionsTotalCount = 0
        state.userTransactionsFilterCount = 0
        state.userTransactionsRequestParams = userTransactionsRequestParamsInitialValue
        state.userDefaultSettings = null
      })
      .addCase(GetUserBillingSettingsThunk.fulfilled, (state, action) => {
        if (!action.payload.isCurrentUser) {
          state.userBillingSettings = action.payload.billing
        }
      })
      .addCase(CreateUserThunk.fulfilled, (state, action) => {
        state.selectedUserData = action.payload
      })
      .addCase(EditUserActiveStatusThunk.fulfilled, (state, action) => {
        if (state.userListRequestParams.user_request.active_status === 'ALL') {
          state.userList = state.userList?.map(user => user.user_id === action.payload.userId ? {...user, is_active: action.payload.isActive} : user) || []
        } else {
          state.userList = state.userList?.filter(user => user.user_id !== action.payload.userId) || []
        }
      })
      .addCase(GetUserListForSuperUserThunk.fulfilled, (state, action) => {
        state.userListForSuperUser = action.payload.users
        state.userListForSuperUserTotalCount = action.payload.total_count
        state.userListForSuperUserFilterCount = action.payload.filter_count
      })
      .addCase(GetUserTransactionsThunk.fulfilled, (state, action) => {
        state.userTransactions = action.payload.transactions
        state.userTransactionsTotalCount = action.payload.total_count
        state.userTransactionsFilterCount = action.payload.filter_count
      })
      .addCase(GetUserDefaultSettingThunk.fulfilled, (state, action) => {
        state.userDefaultSettings = action.payload
      })
  }
})

export const {
  setUserList,
  setUserListTotalCount,
  setUserListRequestParams,
  setUserListManualParams,
  setSelectedUserData,
  setUserDetailsActiveTabKey,
  setRoleList,
  setUserListForSuperUser,
  setUserListForSuperUserTotalCount,
  setUserListForSuperUserFilterCount,
  setUserListForSuperUserRequestParams,
  setUserTransactions,
  setUserTransactionsTotalCount,
  setUserTransactionsFilterCount,
  setUserTransactionsRequestParams,
  setCurrentAppWidth
} = userSlice.actions

export const selectUserList = (state: RootState): UserType[] | null => state.user.userList
export const selectUserListTotalCount = (state: RootState): number => state.user.userListTotalCount
export const selectUserListRequestParams = (state: RootState): UserListRequestParamsType => state.user.userListRequestParams
export const selectUserListManualParams = (state: RootState): UserListManualParamsType => state.user.userListManualParams
export const selectSelectedUserData = (state: RootState): UserType | null => state.user.selectedUserData
export const selectUserDetailsActiveTabKey = (state: RootState): UserDetailsActiveTabKeyType => state.user.userDetailsActiveTabKey
export const selectRoleList = (state: RootState): null | UserRoleType[] => state.user.roleList
export const selectUserDefaultSettings = (state: RootState): null | UserDefaultSettingsType => state.user.userDefaultSettings
export const selectUserListForSuperUser = (state: RootState): null | UserDataForSuperUserType[] => state.user.userListForSuperUser
export const selectUserListForSuperUserTotalCount = (state: RootState): number => state.user.userListForSuperUserTotalCount
export const selectUserListForSuperUserFilterCount = (state: RootState): number => state.user.userListForSuperUserFilterCount
export const selectUserListForSuperUserRequestParams = (state: RootState): UserListForSuperUserRequestParamsType => state.user.userListForSuperUserRequestParams
export const selectUserBillingSettings = (state: RootState): UserBillingSettingsType => state.user.userBillingSettings
export const selectUserTransactions = (state: RootState): null | TransactionType[] => state.user.userTransactions
export const selectUserTransactionsTotalCount = (state: RootState): number => state.user.userTransactionsTotalCount
export const selectUserTransactionsFilterCount = (state: RootState): number => state.user.userTransactionsFilterCount
export const selectUserTransactionsRequestParams = (state: RootState): TransactionsRequestParamsType => state.user.userTransactionsRequestParams
export const selectPublicKey = (state: RootState): string => state.user.publicKey
export const selectCurrentAppWidth = (state: RootState): number => state.user.currentAppWidth

export const GetUserListThunk = createAsyncThunk<{ users: UserType[], total_count: number }, UserListRequestParamsType, AsyncThunkConfig>(
  'user/getUserList',
  async (requestParams, thunkAPI) => {
    try {
      const formData = new FormData()
      // formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
      formData.append('user_request', new Blob([JSON.stringify(requestParams.user_request, null, 2)], {type: 'application/json'}))
      const {status, data} = await userAPI.getUserList(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserByIdThunk = createAsyncThunk<UserType, number, AsyncThunkConfig>(
  'user/getUserById',
  async (userId, thunkAPI) => {
    try {
      const {status, data} = await userAPI.getUserById(userId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateUserThunk = createAsyncThunk<UserType, NewUserType, AsyncThunkConfig>(
  'user/createUser',
  async (userData, thunkAPI) => {
    try {
      const {status, data} = await userAPI.createUser(userData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditUserThunk = createAsyncThunk<UserType, { userId: number, userData: NewUserType, successMessage?: string }, AsyncThunkConfig>(
  'user/editUser',
  async ({userId, userData, successMessage}, thunkAPI) => {
    try {
      const {status, data} = await userAPI.editUser(userId, userData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {
          appStatus: successMessage?.length ? AppStatusType.succeeded : AppStatusType.idle,
          appMessage: successMessage
        })
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteUserThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'user/deleteUser',
  async (userId, thunkAPI) => {
    try {
      const {status, data} = await userAPI.deleteUser(userId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(userId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserRoleListThunk = createAsyncThunk<UserRoleType[], void, AsyncThunkConfig>(
  'user/getUserRoleList',
  async (_, thunkAPI) => {
    try {
      const {status, data} = await userAPI.getUserRoleList()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.roles, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const getUserGeneralData = (personData: UserType): NewUserType => {
  return {
    ...pickBy(
      personData, (_, key) => (
        key !== 'user_id' &&
        key !== 'confirmation_token' &&
        key !== 'is_active' &&
        key !== 'report_language'
      )
    ),
    instance_list: sortBy(personData.instance_list, i => i.order_num)?.map(instance => String(instance.id)) || []
  } as NewUserType
}

export const GetUserTokensThunk = createAsyncThunk<UserTokenType, number, AsyncThunkConfig>(
  'user/getUserTokens',
  async (userId, thunkAPI) => {
    try {
      const {status, data} = await userAPI.getUserTokens(userId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserBillingSettingsThunk = createAsyncThunk<{billing: UserBillingSettingsType, isCurrentUser: boolean}, {userId: number, isCurrentUser: boolean}, AsyncThunkConfig>(
  'user/getUserBillingSettings',
  async ({userId, isCurrentUser}, thunkAPI) => {
    try {
      const {status, data} = await userAPI.getUserBillingSettings(userId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({billing: data, isCurrentUser}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)


export const ChangeUserBalanceThunk = createAsyncThunk<any, ChangeUserBalance, AsyncThunkConfig>(
  'user/changeUserBalance',
  async (reqData, thunkAPI) => {
    try {
      const {status, data} = await userAPI.changeAccountBalance(reqData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditUserActiveStatusThunk = createAsyncThunk<{userId: number, isActive: boolean}, {userId: number, isActive: boolean}, AsyncThunkConfig>(
  'user/editUserActiveStatus',
  async ({userId, isActive}, thunkAPI) => {
    try {
      const {status, data} = await userAPI.editUserActiveStatus(userId, isActive)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({userId, isActive}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserListForSuperUserThunk = createAsyncThunk<{users: UserDataForSuperUserType[], filter_count: number, total_count: number}, UserListForSuperUserRequestParamsType, AsyncThunkConfig>(
  'user/getUserListForSuperUser',
  async (requestParams, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
      !!Object.keys(requestParams.select_request || {})?.length && formData.append('select_request', new Blob([JSON.stringify(requestParams.select_request, null, 2)], {type: 'application/json'}))
      const {status, data} = await userAPI.getUserListForSuperUser(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserTransactionsThunk = createAsyncThunk<{filter_count: number, total_count: number, transactions: TransactionType[]}, TransactionsRequestParamsType, AsyncThunkConfig>(
  'user/getUserTransactions',
  async (requestParams, thunkAPI) => {
    const formData = new FormData()
    formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
    !!Object.keys(requestParams.transaction_request || {})?.length && formData.append('transaction_request', new Blob([JSON.stringify(requestParams.transaction_request, null, 2)], {type: 'application/json'}))
    try {
      const {status, data} = await userAPI.getUserTransactions(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserDefaultSettingThunk = createAsyncThunk<UserDefaultSettingsType, void, AsyncThunkConfig>(
  'user/getUserDefaultSettings',
  async (_, thunkAPI) => {
    try {
      const {status, data} = await userAPI.getUserDefaultSettings()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)


export const ConfirmUserRegistration = createAsyncThunk<any, {user_id: number, is_confirmed: boolean}, AsyncThunkConfig>(
    'user/confirmUserRegistration',
    async (reqData, thunkAPI) => {
      try {
        const {status, data} = await userAPI.confirmUserRegistration(reqData.user_id, reqData.is_confirmed)
        if (status === 200 && data) {
          return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.rejectWithValue(data)
        }
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error?.response?.data?.message)
      }
    }
)

export const SetUserPassword = createAsyncThunk<any, {password: string, token: string}, AsyncThunkConfig>(
    'user/setUserPassword',
    async (reqData, thunkAPI) => {
      try {
        const {status, data} = await userAPI.setUserPassword(reqData.password, reqData.token)
        if (status === 200) {
          thunkAPI.dispatch(setIsSuccessModalOpened(true))
          return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.rejectWithValue(data)
        }
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error?.response?.data?.message)
      }
      finally {
        setTimeout(() => {
          thunkAPI.dispatch(setIsSuccessModalOpened(false))
          window.location.replace('/sign-in')
        }, 7000)
      }
    }
)

export const ResetUserPassword = createAsyncThunk<void, string, AsyncThunkConfig>(
    'user/resetUserPassword',
    async (email, thunkAPI) => {
      try {
        const {status, data} = await userAPI.restorePassword(email)
        if (status === 200) {
          thunkAPI.dispatch(setIsSuccessModalOpened(true))
          return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.rejectWithValue(data)
        }
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error?.response?.data?.message)
      }
      finally {
        setTimeout(() => {
          thunkAPI.dispatch(setIsSuccessModalOpened(false))
          window.location.replace('/sign-in')
        }, 7000)
      }
    }
)

export default userSlice.reducer
  