import { createSlice, PayloadAction, isRejected, isPending, isFulfilled, createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkConfig, fulfilledMetaType, RootState, UIPermissionType } from './../types/appTypes'
import { currentUserAPI } from '../app/api'
import { SignOutThunk } from './currentUserReducer'

export enum AppStatusType {'idle', 'loading', 'succeeded', 'failed', 'info', 'fatal'}

interface InitialStateType {
  appStatus: AppStatusType
  appMessage: string | null
  uiPermissions: UIPermissionType[] | null
}

const initialState: InitialStateType = {
  appStatus: AppStatusType.idle,
  appMessage: null,
  uiPermissions: null,
}

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatusType>) => {
      state.appStatus = action.payload
    },
    setAppMessage: (state, action: PayloadAction<string | null>) => {
      state.appMessage = action.payload
    },
    setUIPermissions: (state, action: PayloadAction<UIPermissionType[] | null>) => {
      state.uiPermissions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUIPermissionsThunk.fulfilled, (state, action) => {
        state.uiPermissions = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.uiPermissions = null
      })
      .addMatcher(isPending, (state) => {
        state.appStatus = AppStatusType.loading
      })
      .addMatcher(isRejected, (state, action) => {
        if (action.payload === 'User is not active') {
          state.appStatus = AppStatusType.fatal
        } else {
          state.appStatus = action.payload === 'canceled' ? AppStatusType.idle : AppStatusType.failed
          state.appMessage = action.payload === 'canceled' || !(action.payload as string)?.length ? null : action.payload as string || 'Something went wrong'
        }
      })
      .addMatcher(isFulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType
        meta.appStatus === AppStatusType.idle && (state.appStatus = AppStatusType.idle)
        if (meta.appStatus === AppStatusType.info) {
          state.appStatus = AppStatusType.info
          state.appMessage = meta.appMessage || null
        } else if (meta.appStatus === AppStatusType.succeeded) {
          state.appStatus = AppStatusType.succeeded
          state.appMessage = meta.appMessage || null
        }
      })
  },
})

export const {
  setAppStatus,
  setAppMessage,
  setUIPermissions,
} = appStatusSlice.actions

export const selectAppStatus = (state: RootState) => state.appStatus.appStatus
export const selectAppMessage = (state: RootState) => state.appStatus.appMessage
export const selectUIPermissions = (state: RootState) => state.appStatus.uiPermissions
export const getUIPermission = (permissionName: string) => (state: RootState) => state.appStatus.uiPermissions?.find(p => p.objectName.toLowerCase() === permissionName.toLowerCase())?.isAvailable

export const GetUIPermissionsThunk = createAsyncThunk<UIPermissionType[], void, AsyncThunkConfig>(
  'appStatus/getUserReportSettings',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await currentUserAPI.getUIPermissions()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default appStatusSlice.reducer
