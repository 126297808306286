import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, ManualPaginationType, RootState } from './../types/appTypes'
import { InstanceFormType, InstanceListRequestParamsType, InstanceSubtopicType, InstanceTopicType, InstanceType, NewInstanceSubtopicType, NewInstanceTopicType, NewInstanceType, SubtopicCommentHintType } from '../types/instanceType'
import { instanceAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { GetLastSessionsDateRangeThunk } from './sessionReducer'
import { DateRangeType } from '../types/sessionTypes'
import { SignOutThunk } from './currentUserReducer'
import { sortBy } from 'lodash'
import { GridSortDirection } from '@mui/x-data-grid-pro'

const instanceListRequestParamsInitialValue = {
  // pagination_request: {page: 1, size: 10, sort: [{field: 'order_num', direction: 'ASC' as 'ASC'}]},
  instance_request: {search: ''} 
}

const instanceListManualParamsInitialValue = {
  page: 1,
  size: 10,
  sort: [{field: 'order_num', sort: 'asc' as GridSortDirection}],
  filters: {items: []},
  search: {fields: ['name'], query: ''}
}

interface InitialStateType {
  instanceList: InstanceType[] | null
  instanceListTotalCount: number
  instanceListRequestParams: InstanceListRequestParamsType
  instanceListManualParams: ManualPaginationType
  instanceTopicList: InstanceTopicType[] | null
  instanceSubtopicList: InstanceSubtopicType[] | null
  subtopicCommentHints: SubtopicCommentHintType[] | null

  currentInstance: null | InstanceType
  isAddInstanceModalOpened: boolean
  isBackLinkModalOpened: boolean
}

const initialState: InitialStateType = {
  instanceList: null,
  instanceListTotalCount: 0,
  instanceListRequestParams: instanceListRequestParamsInitialValue,
  instanceListManualParams: instanceListManualParamsInitialValue,
  instanceTopicList: null,
  instanceSubtopicList: null,
  subtopicCommentHints: null,

  currentInstance: null,
  isAddInstanceModalOpened: false,
  isBackLinkModalOpened: false
}

export const instanceSlice = createSlice({
  name: 'instance',
  initialState,
  reducers: {
    setInstanceList: (state, action: PayloadAction<InstanceType[] | null>) => {state.instanceList = action.payload},
    setInstanceListTotalCount: (state, action: PayloadAction<number>) => {state.instanceListTotalCount = action.payload},
    setInstanceListRequestParams: (state, action: PayloadAction<InstanceListRequestParamsType>) => {state.instanceListRequestParams = action.payload},
    setInstanceListManualParams: (state, action: PayloadAction<ManualPaginationType>) => {state.instanceListManualParams = action.payload},
    setInstanceTopicList: (state, action: PayloadAction<InstanceTopicType[] | null>) => {state.instanceTopicList = action.payload},
    setInstanceSubtopicList: (state, action: PayloadAction<InstanceSubtopicType[] | null>) => {state.instanceSubtopicList = action.payload},
    setSubtopicCommentHints: (state, action: PayloadAction<SubtopicCommentHintType[] | null>) => {state.subtopicCommentHints = action.payload},

    setCurrentInstance: (state, action: PayloadAction<InstanceType | null>) => {state.currentInstance = action.payload},
    setAddInstanceModalOpened: (state, action: PayloadAction<boolean>) => {state.isAddInstanceModalOpened = action.payload},
    setBackLinkModalOpened: (state, action: PayloadAction<boolean>) => {state.isBackLinkModalOpened = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetInstanceListThunk.fulfilled, (state, action) => {
        state.instanceList = action.payload.instances
        state.instanceListTotalCount = action.payload.total_count
      })
      .addCase(GetInstanceTopicListThunk.fulfilled, (state, action) => {
        state.instanceTopicList = action.payload
      })
      .addCase(GetInstanceSubtopicListThunk.fulfilled, (state, action) => {
        state.instanceSubtopicList = action.payload
      })
      .addCase(GetSessionInstancesByPeriodThunk.fulfilled, (state, action) => {
        state.instanceList = action.payload.instances
      })
      .addCase(DeleteInstanceThunk.fulfilled, (state, action) => {
        state.instanceList = state.instanceList?.filter(instance => instance.id !== action.payload) || []
        state.instanceListTotalCount -= 1
      })
      .addCase(GetInstanceByIdThunk.fulfilled, (state, action) => {
        state.currentInstance = action.payload
      })
      .addCase(CreateInstanceThunk.fulfilled, (state, action) => {
        state.instanceList = [...state.instanceList || [], action.payload]
        state.instanceListTotalCount += 1
      })
      .addCase(ReorderInstancesThunk.fulfilled, (state, action) => {
        state.instanceList = sortBy(action.payload.instances, instance => instance.order_num).slice(0, 10)
        state.instanceListRequestParams = instanceListRequestParamsInitialValue
      })
      .addCase(GetSubtopicCommentHintsThunk.fulfilled, (state, action) => {
        state.subtopicCommentHints = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.instanceList = null
        state.instanceTopicList = null
        state.instanceSubtopicList = null
        state.instanceListRequestParams = instanceListRequestParamsInitialValue
        state.currentInstance = null
        state.subtopicCommentHints = null
      })
  }
})

export const {
  setInstanceList,
  setInstanceListTotalCount,
  setInstanceListRequestParams,
  setInstanceListManualParams,
  setInstanceTopicList,
  setInstanceSubtopicList,
  setSubtopicCommentHints,
  setCurrentInstance,
  setAddInstanceModalOpened,
  setBackLinkModalOpened
} = instanceSlice.actions

export const selectInstanceList = (state: RootState): InstanceType[] | null => state.instance.instanceList
export const selectInstanceListTotalCount = (state: RootState): number => state.instance.instanceListTotalCount
export const selectInstanceListRequestParams = (state: RootState): InstanceListRequestParamsType => state.instance.instanceListRequestParams
export const selectInstanceListManualParams = (state: RootState): ManualPaginationType => state.instance.instanceListManualParams
export const selectInstanceTopicList = (state: RootState): InstanceTopicType[] | null => state.instance.instanceTopicList
export const selectInstanceSubtopicList = (state: RootState): InstanceSubtopicType[] | null => state.instance.instanceSubtopicList
export const selectSubtopicCommentHints = (state: RootState): SubtopicCommentHintType[] | null => state.instance.subtopicCommentHints
export const selectCurrentInstance = (state: RootState): InstanceType | null => state.instance.currentInstance
export const selectAddInstanceModalOpened = (state: RootState): boolean => state.instance.isAddInstanceModalOpened
export const selectBackLinkModalOpened = (state: RootState): boolean => state.instance.isBackLinkModalOpened

export const GetInstanceListThunk = createAsyncThunk<{instances: InstanceType[], total_count: number}, InstanceListRequestParamsType | void, AsyncThunkConfig>(
  'instance/getInstanceList',
  async (instanceListRequestParams, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('instance_request', new Blob([JSON.stringify(!!instanceListRequestParams ? instanceListRequestParams?.instance_request : {search: ''}, null, 2)], {type: 'application/json'}))
      // formData.append('pagination_request', new Blob([JSON.stringify(!!instanceListRequestParams ? instanceListRequestParams?.pagination_request : {sort: [{field: 'order_num', direction: 'ASC' as 'ASC'}]}, null, 2)], {type: 'application/json'}))
      const { status, data } = await instanceAPI.getInstanceList(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({instances: data.instances, total_count: data.total_count}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error?.message)
    }
  }
)

export const GetInstanceTopicListThunk = createAsyncThunk<InstanceTopicType[], number, AsyncThunkConfig>(
  'instance/getInstanceTopicList',
  async (instanceId, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.getInstanceTopicList(instanceId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.instance_topics, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetInstanceSubtopicListThunk = createAsyncThunk<InstanceSubtopicType[], number, AsyncThunkConfig>(
  'instance/getInstanceSubtopicList',
  async (topicId, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.getInstanceSubtopicList(topicId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.subtopics, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetSessionInstancesByPeriodThunk = createAsyncThunk<{instances: InstanceType[], session_count: number}, {date_from?: string, date_till?: string, person_id: number}, AsyncThunkConfig>(
  'instance/getSessionInstancesByPeriod',
  async (requestData, thunkAPI) => {
    try {
      if (!requestData?.date_from || !requestData?.date_till) {
        const defaultSessionsCount = 10
        const {payload: dateRange} = await thunkAPI.dispatch(GetLastSessionsDateRangeThunk({personId: requestData.person_id, sessionCount: defaultSessionsCount}))
        requestData.date_from = (dateRange as DateRangeType).date_from
        requestData.date_till = (dateRange as DateRangeType).date_till
      }
      const formData = new FormData()
      formData.append('instance_request', new Blob([JSON.stringify(requestData, null, 2)], {type: 'application/json'}))
      const { status, data } = await instanceAPI.getInstanceList(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteInstanceThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'instance/deleteInstance',
  async (instanceId, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.deleteInstance(instanceId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(instanceId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteInstanceTopicsThunk = createAsyncThunk<number[], number[], AsyncThunkConfig>(
  'instance/deleteInstanceTopics',
  async (instanceTopicIds, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.deleteInstanceTopics(instanceTopicIds)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(instanceTopicIds, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteInstanceSubtopicsThunk = createAsyncThunk<number[], number[], AsyncThunkConfig>(
  'instance/deleteInstanceSubtopics',
  async (instanceSubtopicIds, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.deleteInstanceSubtopics(instanceSubtopicIds)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(instanceSubtopicIds, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetInstanceByIdThunk = createAsyncThunk<InstanceType, number, AsyncThunkConfig>(
  'instance/getInstanceById',
  async (instanceId, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.getInstanceById(instanceId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateInstanceThunk = createAsyncThunk<InstanceType, InstanceFormType, AsyncThunkConfig>(
  'instance/createInstance',
  async (formValues, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.createInstance(formValues.instance as NewInstanceType)
      if (status === 200 && data) {
        if (formValues.addedTopics?.length) {
          await Promise.all(formValues.addedTopics.map(topic => {
            const subtopics = formValues.addedSubtopics?.filter(s => s.temp_topic_id === topic.temp_id)
            return thunkAPI.dispatch(CreateInstanceTopicThunk({
              topic: {
                instance_id: data.id,
                order_num: topic.order_num,
                name: topic.name,
                description: topic.description
              },
              subtopics
            }))
          }))
        }
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateInstanceTopicThunk = createAsyncThunk<InstanceTopicType, {topic: NewInstanceTopicType, subtopics: NewInstanceSubtopicType[]}, AsyncThunkConfig>(
  'instance/createInstanceTopic',
  async ({topic, subtopics}, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.createInstanceTopic(topic)
      if (status === 200 && data) {
        if (subtopics?.length) {
          await Promise.all(subtopics.map(subtopic => thunkAPI.dispatch(CreateInstanceSubtopicThunk({
            topic_id: data.id,
            order_num: subtopic.order_num,
            name: subtopic.name,
            description: subtopic.description,
            short_description: ''
          }))))
        }
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateInstanceSubtopicThunk = createAsyncThunk<InstanceSubtopicType, NewInstanceSubtopicType, AsyncThunkConfig>(
  'instance/createInstanceSubtopic',
  async (subtopicData, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.createInstanceSubtopic(subtopicData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditInstanceThunk = createAsyncThunk<InstanceType, {instanceId: number, formValues: InstanceFormType}, AsyncThunkConfig>(
  'instance/editInstance',
  async ({instanceId, formValues}, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.editInstance(instanceId!, formValues.instance as NewInstanceType)
      if (status === 200 && data) {
        if (formValues?.addedTopics?.length) {
          await Promise.all(formValues.addedTopics.map(topic => {
            const subtopics = formValues?.addedSubtopics?.filter(s => s.temp_topic_id === topic.temp_id)
            return thunkAPI.dispatch(CreateInstanceTopicThunk({
              topic: {
                instance_id: instanceId,
                order_num: topic.order_num,
                name: topic.name,
                description: topic.description
              },
              subtopics
            }))
          }))
        }
        if (formValues?.editedTopics?.length) {
          await Promise.all(formValues.editedTopics.map(topic => {
            const addSubtopics = formValues?.addedSubtopics?.filter(s => s.topic_id === topic.id)
            const editSubtopics = formValues?.editedSubtopics?.filter(s => s.topic_id === topic.id)
            return thunkAPI.dispatch(EditInstanceTopicThunk({
              topicId: topic.id!,
              topic: {
                instance_id: instanceId,
                order_num: topic.order_num,
                name: topic.name,
                description: topic.description
              },
              addSubtopics,
              editSubtopics
            }))
          }))
        }
        if (formValues?.deletedTopics?.length) {
          thunkAPI.dispatch(DeleteInstanceTopicsThunk(formValues?.deletedTopics))
        }
        if (formValues?.deletedSubtopics?.length) {
          thunkAPI.dispatch(DeleteInstanceSubtopicsThunk(formValues?.deletedSubtopics))
        }
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditInstanceTopicThunk = createAsyncThunk<InstanceTopicType, {topicId: number, topic: NewInstanceTopicType, addSubtopics?: NewInstanceSubtopicType[], editSubtopics?: NewInstanceSubtopicType[]}, AsyncThunkConfig>(
  'instance/editInstanceTopic',
  async ({topicId, topic, addSubtopics, editSubtopics}, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.editInstanceTopic(topicId, topic)
      if (status === 200 && data) {
        if (addSubtopics?.length) {
          await Promise.all(addSubtopics.map(subtopic => thunkAPI.dispatch(CreateInstanceSubtopicThunk({
            topic_id: data.id,
            order_num: subtopic.order_num,
            name: subtopic.name,
            description: subtopic.description,
            short_description: ''
          }))))
        }
        if (editSubtopics?.length)  {
          await Promise.all(editSubtopics.map(subtopic => thunkAPI.dispatch(EditInstanceSubtopicThunk({
            subtopicId: subtopic.id!,
            subtopicData: {
              id: subtopic.id!,
              topic_id: data.id,
              order_num: subtopic.order_num,
              name: subtopic.name,
              description: subtopic.description,
              short_description: ''
            }
          }))))
        }
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditInstanceSubtopicThunk = createAsyncThunk<InstanceSubtopicType, {subtopicId: number, subtopicData: NewInstanceSubtopicType}, AsyncThunkConfig>(
  'instance/editInstanceSubtopic',
  async ({subtopicId, subtopicData}, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.editInstanceSubtopic(subtopicId, subtopicData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const ReorderInstancesThunk = createAsyncThunk<{instances: InstanceType[], total_count: number}, {instance_id: number, order_num: number}[], AsyncThunkConfig>(
  'instance/reorderInstances',
  async (reorderedList, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.reorderInstances({instance_list: reorderedList})
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Instance order has been changed!'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetSubtopicCommentHintsThunk = createAsyncThunk<SubtopicCommentHintType[], {subtopicId: number, userId: number}, AsyncThunkConfig>(
  'instance/getSubtopicCommentHints',
  async ({subtopicId, userId}, thunkAPI) => {
    try {
      const { status, data } = await instanceAPI.getSubtopicCommentHints(subtopicId, userId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data['comment-hints'], {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default instanceSlice.reducer
 