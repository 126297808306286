import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, ManualPaginationType, RootState } from './../types/appTypes'
import { AppStatusType } from './appStatusReducer'
import { sessionAPI } from '../app/api'
import { DateRangeType, NewSessionDataType, NewSessionMainDataType, SessionExcelRequestDataType, SessionFormDataType, SessionListRequestParamsType, SessionType } from '../types/sessionTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { GetSessionInstancesByPeriodThunk } from './instanceReducer'

dayjs.extend(utc)

export const sessionListRequestParamsInitialValue = {
  // pagination_request: {page: 1, size: 10, sort: [{field: 'rec_date', direction: 'DESC' as 'DESC'}]},
  person_data_request: {}
}

const sessionListRequestManualParamsInitialValue: ManualPaginationType = {
  page: 1,
  size: 10,
  sort: [{field: 'rec_date', sort: 'desc'}],
  filters: {items: []},
  search: {fields: [''], query: ''}
}

interface InitialStateType {
  sessionList: null | SessionType[]
  sessionListRequestParams: SessionListRequestParamsType
  sessionListManualParams: ManualPaginationType
  sessionListTotalCount: number
  sessionListFilterCount: number
  sessionData: null | SessionType
}

const initialState: InitialStateType = {
  sessionList: null,
  sessionListRequestParams: sessionListRequestParamsInitialValue,
  sessionListManualParams: sessionListRequestManualParamsInitialValue,
  sessionListTotalCount: 0,
  sessionListFilterCount: 0,
  sessionData: null,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionList: (state, action: PayloadAction<SessionType[] | null>) => {state.sessionList = action.payload},
    setSessionListRequestParams: (state, action: PayloadAction<SessionListRequestParamsType>) => {state.sessionListRequestParams = action.payload},
    setSessionListManualParams: (state, action: PayloadAction<ManualPaginationType>) => {state.sessionListManualParams = action.payload},
    clearSessionListRequestParams: (state) => {state.sessionListRequestParams = sessionListRequestParamsInitialValue},
    setSessionListTotalCount: (state, action: PayloadAction<number>) => {state.sessionListTotalCount = action.payload},
    setSessionListFilterCount: (state, action: PayloadAction<number>) => {state.sessionListFilterCount = action.payload},
    setSessionData: (state, action: PayloadAction<SessionType | null>) => {state.sessionData = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSessionsByPersonIdThunk.fulfilled, (state, action) => {
        if (action.payload.infiniteScroll) {
          if ((state.sessionList?.length && action.payload.session?.[0]?.person?.id !== state.sessionList[0]?.person?.id)
            || (state.sessionList?.some(s => s.id === action.payload.session[0]?.id))) {
            state.sessionList = action.payload.session
          } else {
            state.sessionList = [...(state.sessionList || []), ...action.payload.session]
          }
        } else {
          state.sessionList = action.payload.session
        }
        state.sessionListTotalCount = action.payload.total_count
        state.sessionListFilterCount = action.payload.filter_count
      })
      .addCase(GetSessionsBySessionIdThunk.fulfilled, (state, action) => {
        state.sessionData = action.payload
      })
      .addCase(CreateSessionThunk.fulfilled, (state, action) => {
        state.sessionData = action.payload
        state.sessionListFilterCount += 1
        state.sessionListTotalCount += 1
        state.sessionList = [...state.sessionList || [], action.payload]
        state.sessionListRequestParams = {
          ...state.sessionListRequestParams,
          person_data_request: {
            ...state.sessionListRequestParams.person_data_request,
            date_to: dayjs.utc(action.payload.rec_date).endOf('day')
          }
        }
      })
      .addCase(DeleteSessionThunk.fulfilled, (state, action) => {
        state.sessionList = state.sessionList?.filter(s => s.id !== action.payload) || []
        state.sessionListFilterCount -= 1
        state.sessionListTotalCount -= 1
      })
      .addCase(EditSessionThunk.fulfilled, (state, action) => {
        state.sessionData = action.payload
      })
      .addCase(GetSessionInstancesByPeriodThunk.fulfilled, (state, action) => {
        state.sessionListTotalCount = action.payload.session_count
      })
      .addCase(GetLastSessionsDateRangeThunk.fulfilled, (state, action) => {
        state.sessionListRequestParams = {
          ...state.sessionListRequestParams,
          person_data_request: {
            ...state.sessionListRequestParams.person_data_request,
            date_from: action.payload.date_from ? dayjs.utc(action.payload.date_from) : dayjs.utc().startOf('month'),
            date_to: action.payload.date_till ? dayjs.utc(action.payload.date_till) : dayjs.utc().endOf('day'),
          }
        }
      })
  }
})

export const {
  setSessionList,
  setSessionListRequestParams,
  setSessionListManualParams,
  clearSessionListRequestParams,
  setSessionListTotalCount,
  setSessionListFilterCount,
  setSessionData,
} = sessionSlice.actions

export const selectSessionList = (state: RootState): SessionType[] | null => state.session.sessionList
export const selectSessionListRequestParams = (state: RootState): SessionListRequestParamsType => state.session.sessionListRequestParams
export const selectSessionListManualParams = (state: RootState): ManualPaginationType => state.session.sessionListManualParams
export const selectSessionListTotalCount = (state: RootState): number => state.session.sessionListTotalCount
export const selectSessionListFilterCount = (state: RootState): number => state.session.sessionListFilterCount
export const selectSessionData = (state: RootState): SessionType | null => state.session.sessionData

export const GetSessionsByPersonIdThunk = createAsyncThunk<{session: SessionType[], total_count: number, filter_count: number, infiniteScroll: boolean}, {requestParams: SessionListRequestParamsType, infiniteScroll: boolean}, AsyncThunkConfig>(
  'session/getSessionsByPersonId',
  async ({requestParams, infiniteScroll}, thunkAPI) => {
    try {
      const selectedUserHasChanged = thunkAPI.getState().person?.selectedPersonData === null
      const hasNoDates = !requestParams.person_data_request?.date_from && !requestParams.person_data_request?.date_to
      if (selectedUserHasChanged || hasNoDates) {
        const defaultSessionsCount = 10
        const {payload: dateRange} = await thunkAPI.dispatch(GetLastSessionsDateRangeThunk({personId: requestParams.person_data_request.person_id!, sessionCount: defaultSessionsCount}))
        if ((dateRange as DateRangeType)?.date_from && (dateRange as DateRangeType)?.date_till) {
          requestParams.person_data_request.date_from = dayjs.utc((dateRange as DateRangeType).date_from)
          requestParams.person_data_request.date_to = dayjs.utc((dateRange as DateRangeType).date_till)
        } else {
          requestParams.person_data_request.date_from = dayjs.utc().startOf('month')
          requestParams.person_data_request.date_to = dayjs.utc().endOf('day')
        }
      }
      const formData = new FormData()
      if (requestParams.pagination_request?.page) {
        formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
      }
      formData.append('person_data_request', new Blob([JSON.stringify(requestParams.person_data_request, null, 2)], {type: 'application/json'}))
      const { status, data } = await sessionAPI.getSessionsByPersonId(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({
          session: data.person_data_list,
          total_count: data.total_count,
          filter_count: data.filter_count,
          infiniteScroll: infiniteScroll,
        }, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetSessionsBySessionIdThunk = createAsyncThunk<SessionType, number, AsyncThunkConfig>(
  'session/getSessionsBySessionId',
  async (sessionId, thunkAPI) => {
    try {
      const { status, data } = await sessionAPI.getSessionsBySessionId(sessionId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateSessionThunk = createAsyncThunk<SessionType, SessionFormDataType, AsyncThunkConfig>(
  'session/createSession',
  async ({mainData, sessionData}, thunkAPI) => {
    try {
      const { data: sessionDataResp } = await sessionAPI.createSession(mainData)
      const { status, data } = await sessionAPI.addSessionData({person_datas: sessionData.map(s => ({...s, person_data_id: sessionDataResp?.id}))})
      if (status === 200 && data) {
        const session: SessionType = {...sessionDataResp, person_data_rec_list: data.person_data_rec_list}
        return thunkAPI.fulfillWithValue(session, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteSessionThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'session/deleteSession',
  async (sessionId, thunkAPI) => {
    try {
      const { status, data } = await sessionAPI.deleteSession(sessionId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(sessionId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditSessionThunk = createAsyncThunk<SessionType, {sessionId: number, mainData: NewSessionMainDataType, sessionData: NewSessionDataType[]}, AsyncThunkConfig>(
  'session/editSession',
  async ({sessionId, mainData, sessionData}, thunkAPI) => {
    try {
      const responses = await Promise.all([
        sessionAPI.editSession(sessionId, mainData),
        sessionAPI.editSessionData(sessionId, {person_datas: sessionData.map(s => ({...s, person_data_id: sessionId}))})
      ])
      if (responses[0]?.status === 200 && responses[1]?.status === 200) {
        const session: SessionType = {...responses?.[0]?.data, person_data_rec_list: responses?.[1]?.data?.person_data_rec_list}
        return thunkAPI.fulfillWithValue(session, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(responses[0]?.data || responses[1]?.data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetSessionsExcelThunk = createAsyncThunk<any, SessionExcelRequestDataType, AsyncThunkConfig>(
  'session/getSessionsBySessionId',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await sessionAPI.exportSessionExcel(requestData)
      if (status === 200 && data) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        const filename = `session_report_${dayjs().format('YYYY-MM-DD_HH:mm')}.xlsx`
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetLastSessionsDateRangeThunk = createAsyncThunk<DateRangeType, {personId: number, sessionCount?: number}, AsyncThunkConfig>(
  'session/getLastSessionsDateRange',
  async ({personId, sessionCount}, thunkAPI) => {
    try {
      const { status, data } = await sessionAPI.getLastSessionsDateRange(personId, sessionCount)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default sessionSlice.reducer
 