import React, { useState } from 'react'
import infoModals from "../InfoModal/InfoModal.module.scss"
import { Button, InputNumber } from "antd"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { ChangeUserBalanceThunk, GetUserBillingSettingsThunk, GetUserTransactionsThunk, selectSelectedUserData, selectUserTransactionsRequestParams } from "../../../store/userReducer"
import dayjs from "dayjs"
import s from './AddMoreModal.module.scss'
import { useTranslation } from 'react-i18next'

interface InfoModalPropsType {
  title: string
  label: string
  maxValue?: number
  closeModal: () => void
}


const AddMoreModal = ({ title, label, maxValue, closeModal }: InfoModalPropsType) => {
  const { t } = useTranslation(['pages', 'app'])
  const [inputValue, setInputValue] = useState<null | number>(1)
  const dispatch = useAppDispatch()
  const selectedUserData = useAppSelector(selectSelectedUserData)
  const transactionsRequestParams = useAppSelector(selectUserTransactionsRequestParams)

  const onChangeUserBalance = () => {
    if (label === t('billing.credit.addModal.label')) {
      dispatch(ChangeUserBalanceThunk({
        user_id: selectedUserData?.user_id!!,
        change_date: dayjs(),
        change_credit: inputValue!,
        change_pat: 0,
        change_reason: `added_${inputValue}_credits`
      }))
        .then(() => {
          Promise.all([
            dispatch(GetUserBillingSettingsThunk({userId: selectedUserData!.user_id, isCurrentUser: false})),
            dispatch(GetUserTransactionsThunk({
              ...transactionsRequestParams,
              transaction_request: {
                ...(transactionsRequestParams.transaction_request || {}),
                user_id: transactionsRequestParams?.transaction_request?.user_id ? transactionsRequestParams.transaction_request?.user_id : selectedUserData?.user_id!
              }
            }))
          ])
        })
        .then(() => {
          closeModal()
        })
    }
    else {
      dispatch(ChangeUserBalanceThunk({
        user_id: selectedUserData?.user_id!!,
        change_date: dayjs(),
        change_credit: 0,
        change_pat: inputValue!,
        change_reason: `added_${inputValue}_patients`
      }))
        .then(() => {
          Promise.all([
            dispatch(GetUserBillingSettingsThunk({userId: selectedUserData!.user_id, isCurrentUser: false})),
            dispatch(GetUserTransactionsThunk({
              ...transactionsRequestParams,
              transaction_request: {
                ...(transactionsRequestParams.transaction_request || {}),
                user_id: transactionsRequestParams?.transaction_request?.user_id ? transactionsRequestParams.transaction_request?.user_id : selectedUserData?.user_id!
              }
            }))
          ])
        })
        .then(() => {
          closeModal()
        })
    }
  }

  return (
    <div className={infoModals.inner} >
      <div className={infoModals.inner__content} style={{
        height: '310px',
        width: '380px'
      }}>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFF" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8" />
          <path
            d="M17 26H39M19 20H37C38.1046 20 39 20.8954 39 22V34C39 35.1046 38.1046 36 37 36H19C17.8954 36 17 35.1046 17 34V22C17 20.8954 17.8954 20 19 20Z"
            stroke="#007DFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <p className={s.title}>{title}</p>
        <div className={s.formItem}>
          <label>{label}</label>
          <InputNumber
            // min={1}
            max={maxValue}
            defaultValue={1}
            onChange={(value) => setInputValue(Number(value))}
            style={{
              width: '100%'
            }}
          />
        </div>
        <div style={{
          display: 'flex',
          gap: '12px'
        }}>
          <Button
            onClick={closeModal}
            style={{
              width: '160px'
            }}
          >
            {t('actions.cancel', {ns: 'app'})}
          </Button>
          <Button
            type='primary'
            className={s.inner__btn}
            onClick={onChangeUserBalance}
            style={{
              width: '160px'
            }}
          >
            {t('actions.add', {ns: 'app'})}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddMoreModal
