import { Link } from 'react-router-dom'
import { ReactComponent as LeftArrow } from './../../../img/icons/left-arrow.svg'
import classes from './GoBackLink.module.css'


const GoBackLink: React.FC<GoBackLinkPropTypes> = ({ link, text, onClick, style, showConfirmModal }) => {
  if (!!link?.length && !showConfirmModal) {
    return (
      <Link to={link} className={classes.link} style={style}>
        <LeftArrow /> {text}
      </Link>
    )
  }

  return (
    <div className={classes.link} onClick={showConfirmModal ? showConfirmModal : onClick} style={style}>
      <LeftArrow /> {text}
    </div>
  )
}

interface GoBackLinkPropTypes {
  link?: string
  text: string
  onClick?: () => void
  style?: object
  showConfirmModal?: () => void
}

export default GoBackLink
